// Composables
import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/store/auth';

import Home from '@/views/Home.vue';

// AUTH
import Auth from '@/views/Auth/Auth.vue';
import Login from '@/views/Auth/Login.vue';
import ForgotPassword from '@/views/Auth/ForgotPassword.vue';
import ResetPassword from '@/views/Auth/ResetPassword.vue';

// PANEL
import Panel from '@/views/Panel/Panel.vue';

// WORK CENTERS
import WorkCenters from '@/views/WorkCenters/WorkCenters.vue';
import WorkCenter from '@/views/WorkCenters/WorkCenter.vue';
import WorkCenterDetails from '@/views/WorkCenters/WorkCenterDetails.vue';
import WorkCenterMachineCost from '@/views/WorkCenters/WorkCenterMachineCost.vue';
import WorkCenterLabor from '@/views/WorkCenters/WorkCenterLabor.vue';
import WorkCenterParameters from '@/views/WorkCenters/WorkCenterParameters.vue';
import WorkCenterTerminal from '@/views/WorkCenters/WorkCenterTerminal.vue';
import WorkCenterPlanning from '@/views/WorkCenters/WorkCenterPlanning.vue';
import CreateWorkCenter from '@/views/WorkCenters/CreateWorkCenter.vue';

// ARTICLES
import Articles from '@/views/Articles/Articles.vue';
import Article from '@/views/Articles/Article.vue';
import CreateArticle from '@/views/Articles/CreateArticle.vue';
import ArticleDetails from '@/views/Articles/ArticleDetails.vue';
import ArticleParentArticles from '@/views/Articles/ArticleParentArticles.vue';
import ArticleTechnicalSheet from '@/views/Articles/ArticleTechnicalSheet.vue';
import LoadArticles from '@/views/Articles/LoadArticles.vue';

// TECHNICAL SHEETS
import TechnicalSheets from '@/views/TechnicalSheets/TechnicalSheets.vue';
import TechnicalSheetDetail from '@/views/TechnicalSheets/TechnicalSheetDetail.vue';
import LoadInventorTechnicalSheet from '@/views/TechnicalSheets/LoadInventorTechnicalSheet.vue';
import CreateTechnicalSheet from '@/views/TechnicalSheets/CreateTechnicalSheet.vue';
import LoadOldTechnicalSheet from '@/views/TechnicalSheets/LoadOldTechnicalSheet.vue';

// CALC NEEDS
import CalcNeeds from '@/views/CalcNeeds/CalcNeeds.vue';
import CreateCalcNeeds from '@/views/CalcNeeds/CreateCalcNeeds.vue';
import CalcNeed from '@/views/CalcNeeds/CalcNeed.vue';

// PROJECTS
import Projects from '@/views/Projects/Projects.vue';
import Project from '@/views/Projects/Project.vue';
import ProjectMaterialCosts from '@/views/Projects/ProjectMaterialCosts.vue';
import ProjectWorkerCosts from '@/views/Projects/ProjectWorkerCosts.vue';
import ProjectProductionOrders from '@/views/Projects/ProjectProductionOrders.vue';

// PRODUCTION ORDERS
import ProductionOrders from '@/views/ProductionOrders/ProductionOrders.vue';
import ProductionOrder from '@/views/ProductionOrders/ProductionOrder.vue';
import CreateProductionOrder from '@/views/ProductionOrders/CreateProductionOrder.vue';
import ProductionOrderDetails from '@/views/ProductionOrders/ProductionOrderDetails.vue';
import ProductionOrderDocuments from '@/views/ProductionOrders/ProductionOrderDocuments.vue';
import Operations from '@/views/ProductionOrders/Operations.vue';
import Operation from '@/views/ProductionOrders/Operation.vue';
import OperationDetails from '@/views/ProductionOrders/OperationDetails.vue';
import OperationComponents from '@/views/ProductionOrders/OperationComponents.vue';
import Cuttings from '@/views/ProductionOrders/Cuttings.vue';
import ControlPanel from '@/views/ProductionOrders/ControlPanel.vue';

// STOCK DISPATCHES
import StockDispatches from '@/views/StockDispatches/StockDispatches.vue';
import StockDispatch from '@/views/StockDispatches/StockDispatch.vue';

// INTERNAL DOCUMENTS
import InternalDocuments from '@/views/InternalDocuments/InternalDocuments.vue';
import InternalOrderServices from '@/views/InternalDocuments/InternalOrderService/InternalOrderServices.vue';
import CreateInternalOrderService from '@/views/InternalDocuments/InternalOrderService/CreateInternalOrderService.vue';
import InternalOrderService from '@/views/InternalDocuments/InternalOrderService/InternalOrderService.vue';
import InternalRequests from '@/views/InternalDocuments/InternalRequest/InternalRequests.vue';
import CreateInternalRequest from '@/views/InternalDocuments/InternalRequest/CreateInternalRequest.vue';
import InternalRequest from '@/views/InternalDocuments/InternalRequest/InternalRequest.vue';


// DAILY WORK
import CreateDailyWork from '@/views/DailyWork/CreateDailyWork.vue';
import DailyWorks from '@/views/DailyWork/DailyWorks.vue';
import DailyWork from '@/views/DailyWork/DailyWork.vue';
import DailyWorkDetails from '@/views/DailyWork/DailyWorkDetails.vue';
import DailyWorkDocuments from '@/views/DailyWork/DailyWorkDocuments.vue';

// RESERVATIONS MANAGER
import ReservationsManager from '@/views/ReservationsManager/ReservationsManager.vue';
import ReservationsManagerReservations from '@/views/ReservationsManager/Reservations.vue';
import ReservationsManagerNeeds from '@/views/ReservationsManager/Needs.vue';

// SUPPLIERS ORDERS
import SuppliersOrders from '@/views/SuppliersOrders/SuppliersOrders.vue';
import SuppliersOrder from '@/views/SuppliersOrders/SuppliersOrder.vue';

const routes = [
    {
        path: '/auth',
        name: 'Auth',
        component: Auth,
        redirect: { name: 'Login' },
        beforeEnter: (to, from, next) => {
            const authStore = useAuthStore();
            if (authStore.isAuthenticated) {
                next({ name: 'Home' });
            } else {
                next();
            }
        },
        children: [
            {
                path: 'login',
                name: 'Login',
                component: Login,
            },
            {
                path: 'forgot-password',
                name: 'ForgotPassword',
                component: ForgotPassword,
            },
            {
                path: 'reset-password',
                name: 'ResetPassword',
                component: ResetPassword,
            },
        ],
        meta: {
            authRequired: false,
        },
    },
    {
        path: '/',
        component: Home,
        name: 'Home',
        redirect: { name: 'Panel' },
        meta: {
            authRequired: true,
        },
        children: [
            {
                path: 'panel',
                name: 'Panel',
                component: Panel,
                meta: {
                    canGoBack: false,
                }
            },
            {
                path: 'work-centers',
                //name: 'WorkCenters',
                //component: WorkCenters,
                children: [
                    {
                        path: '',
                        name: 'WorkCenters',
                        component: WorkCenters,
                        meta: {
                            canGoBack: false,
                        }
                    },
                    {
                        path: 'create',
                        name: 'CreateWorkCenter',
                        component: CreateWorkCenter,
                        meta: {
                            canGoBack: true,
                        }
                    },
                    {
                        path: ':id',
                        name: 'WorkCenter',
                        component: WorkCenter,
                        redirect: { name: 'WorkCenterDetails' },
                        children: [
                            {
                                path: 'details',
                                name: 'WorkCenterDetails',
                                component: WorkCenterDetails,
                                meta: {
                                    canGoBack: true,
                                }
                            },
                            {
                                path: 'machine-cost',
                                name: 'WorkCenterMachineCost',
                                component: WorkCenterMachineCost,
                                meta: {
                                    canGoBack: true,
                                }
                            },
                            {
                                path: 'labor',
                                name: 'WorkCenterLabor',
                                component: WorkCenterLabor,
                                meta: {
                                    canGoBack: true,
                                }
                            },
                            {
                                path: 'parameters',
                                name: 'WorkCenterParameters',
                                component: WorkCenterParameters,
                                meta: {
                                    canGoBack: true,
                                }
                            },
                            {
                                path: 'terminal',
                                name: 'WorkCenterTerminal',
                                component: WorkCenterTerminal,
                                meta: {
                                    canGoBack: true,
                                }
                            },
                            {
                                path: 'planning',
                                name: 'WorkCenterPlanning',
                                component: WorkCenterPlanning,
                                meta: {
                                    canGoBack: true,
                                }
                            },
                        ],
                    },
                ],
            },
            {
                path: 'articles',
                name: 'Articles',
                component: Articles,
                meta: {
                    canGoBack: false,
                }

            },
            {
                path: 'articles/create',
                name: 'CreateArticle',
                component: CreateArticle,
                meta: {
                    canGoBack: true,
                }
            },
            {
                path: 'articles/load',
                name: 'LoadArticles',
                component: LoadArticles,
                meta: {
                    canGoBack: false,
                }

            },
            {
                path: 'articles/:id',
                name: 'Article',
                component: Article,
                redirect: { name: 'ArticleDetails' },
                children: [
                    {
                        path: 'details',
                        name: 'ArticleDetails',
                        component: ArticleDetails,
                        meta: {
                            canGoBack: true,
                        }
                    },
                    {
                        path: 'technical-sheet',
                        name: 'ArticleTechnicalSheet',
                        component: ArticleTechnicalSheet,
                        meta: {
                            canGoBack: true,
                        }
                    },
                    {
                        path: 'parent-articles',
                        name: 'ArticleParentArticles',
                        component: ArticleParentArticles,
                        meta: {
                            canGoBack: true,
                        }
                    }
                ],
            },
            {
                path: 'technical-sheets',
                name: 'TechnicalSheets',
                component: TechnicalSheets,
                meta: {
                    canGoBack: false,
                }

            },
            {
                path: 'technical-sheets/create',
                name: 'CreateTechnicalSheet',
                component: CreateTechnicalSheet,
                meta: {
                    canGoBack: true,
                }

            },
            {
                path: 'technical-sheets/:id',
                name: 'TechnicalSheetDetail',
                component: TechnicalSheetDetail,
                meta: {
                    canGoBack: true,
                }

            },
            {
                path: 'technical-sheets/load/inventor',
                name: 'LoadInventorTechnicalSheet',
                component: LoadInventorTechnicalSheet,
                meta: {
                    canGoBack: false,
                }

            },
            {
                path: 'calc-needs',
                name: 'CalcNeeds',
                component: CalcNeeds,
                meta: {
                    canGoBack: false,
                }

            },
            {
                path: 'calc-needs/create',
                name: 'CreateCalcNeeds',
                component: CreateCalcNeeds,
                meta: {
                    canGoBack: true,
                }
            },
            {
                path: 'calc-needs/:id',
                name: 'CalcNeed',
                component: CalcNeed,
                meta: {
                    canGoBack: true,
                }
            },
            {
                path: 'projects',
                name: 'Projects',
                component: Projects,
                meta: {
                    canGoBack: false,
                }

            },
            {
                path: 'projects/:id',
                name: 'Project',
                component: Project,
                redirect: { name: 'ProjectMaterialCosts' },
                children: [
                    {
                        path: 'material-costs',
                        name: 'ProjectMaterialCosts',
                        component: ProjectMaterialCosts,
                        meta: {
                            canGoBack: true,
                        }

                    },
                    {
                        path: 'worker-costs',
                        name: 'ProjectWorkerCosts',
                        component: ProjectWorkerCosts,
                        meta: {
                            canGoBack: true,
                        }

                    },
                    {
                        path: 'production-orders',
                        name: 'ProjectProductionOrders',
                        component: ProjectProductionOrders,
                        meta: {
                            canGoBack: true,
                        }

                    },
                ],

            },
            {
                path: 'production-orders',
                name: 'ProductionOrders',
                component: ProductionOrders,
                meta: {
                    canGoBack: false,
                }

            },
            {
                path: 'production-orders/operations',
                name: 'Operations',
                component: Operations,
                meta: {
                    canGoBack: false,
                }

            },
            {
                path: 'production-orders/cuttings',
                name: 'Cuttings',
                component: Cuttings,
                meta: {
                    canGoBack: false,
                }

            },
            {
                path: 'production-orders/control-panel',
                name: 'ControlPanel',
                component: ControlPanel,
                meta: {
                    canGoBack: false,
                }

            },
            {
                path: 'production-orders/operations/:operationId',
                name: 'Operation',
                component: Operation,
                redirect: { name: 'OperationDetails' },
                children: [
                    {
                        path: 'details',
                        name: 'OperationDetails',
                        component: OperationDetails,
                        meta: {
                            canGoBack: true,
                        }

                    },
                    {
                        path: 'components',
                        name: 'OperationComponents',
                        component: OperationComponents,
                        meta: {
                            canGoBack: true,
                        }

                    }
                ],
            },
            {
                path: 'production-orders/create',
                name: 'CreateProductionOrder',
                component: CreateProductionOrder,
                meta: {
                    canGoBack: true,
                }

            },
            {
                path: 'production-orders/:id',
                name: 'ProductionOrder',
                component: ProductionOrder,
                redirect: { name: 'ProductionOrderDetails' },
                children: [
                    {
                        path: 'details',
                        name: 'ProductionOrderDetails',
                        component: ProductionOrderDetails,
                        meta: {
                            canGoBack: true,
                        }

                    },
                    {
                        path: 'documents',
                        name: 'ProductionOrderDocuments',
                        component: ProductionOrderDocuments,
                        meta: {
                            canGoBack: true,
                        }
                    }
                ],

            },
            {
                path: 'stock-dispatches',
                name: 'StockDispatches',
                component: StockDispatches,
                meta: {
                    canGoBack: false,
                }

            },
            {
                path: 'internal-documents/OSI',
                name: 'InternalOrderServices',
                component: InternalOrderServices,
                meta: {
                    canGoBack: false,
                }

            },
            {
                path: 'internal-documents/OSI/create',
                name: 'CreateInternalOrderService',
                component: CreateInternalOrderService,
                meta: {
                    canGoBack: true,
                }

            },
            {
                path: 'internal-documents/OSI/:id',
                name: 'InternalOrderService',
                component: InternalOrderService,
                meta: {
                    canGoBack: true,
                }

            },
            {
                path: 'internal-documents/RI',
                name: 'InternalRequests',
                component: InternalRequests,
                meta: {
                    canGoBack: false,
                }
            },
            {
                path: 'internal-documents/RI/create',
                name: 'CreateInternalRequest',
                component: CreateInternalRequest,
                meta: {
                    canGoBack: true,
                }
            },
            {
                path: 'internal-documents/RI/:id',
                name: 'InternalRequest',
                component: InternalRequest,
                meta: {
                    canGoBack: true,
                }
            },
            {
                path: 'daily-works',
                name: 'DailyWorks',
                component: DailyWorks,
                meta: {
                    canGoBack: false,
                }
            },
            {
                path: 'daily-works/create',
                name: 'CreateDailyWork',
                component: CreateDailyWork,
                meta: {
                    canGoBack: true,
                }
            },
            {
                path: 'daily-works/:id',
                name: 'DailyWork',
                component: DailyWork,
                redirect: { name: 'DailyWorkDetails' },
                children: [
                    {
                        path: 'details',
                        name: 'DailyWorkDetails',
                        component: DailyWorkDetails,
                        meta: {
                            canGoBack: true,
                        }

                    },
                    {
                        path: 'documents',
                        name: 'DailyWorkDocuments',
                        component: DailyWorkDocuments,
                        meta: {
                            canGoBack: true,
                        }
                    }
                ],

            },
            {
                path: 'reservations-manager',
                name: 'RerservationsManager',
                component: ReservationsManager,
                redirect: { name: 'Reservations' },
                children: [
                    {
                        path: 'reservations',
                        name: 'Reservations',
                        component: ReservationsManagerReservations,
                        meta: {
                            canGoBack: false,
                        }
                    },
                    {
                        path: 'needs',
                        name: 'Needs',
                        component: ReservationsManagerNeeds,
                        meta: {
                            canGoBack: false,
                        }
                    },
                ],
            },
            {
                path: 'suppliers-orders',
                name: 'SuppliersOrders',
                component: SuppliersOrders,
                meta: {
                    canGoBack: false,
                }
            },
            {
                path: 'suppliers-orders/:id',
                name: 'SuppliersOrder',
                component: SuppliersOrder,
                meta: {
                    canGoBack: true,
                }
            }
        ],
    },
] as Readonly<RouteRecordRaw[]>;

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const authRequired = to.matched.some((route) => route.meta.authRequired);

    if (authRequired) {
        const authStore = useAuthStore();
        if (!authStore.isAuthenticated) {
            next({ name: 'Login' });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
