<template>
    <v-row class="tw-mt-3">
        <v-data-table
            :headers="headers"
            :items="props.documents"
            v-model:page="page"
            v-model:items-per-page="pageSize"
            item-value="IdOrigem">
            <template v-slot:item.TipoOrigem="{ item }">
                <span>{{ sourceType(item.TipoOrigem) }}</span>
            </template>
            <template v-slot:item.NumDoc="{ item }">
                <span>{{ item.NumDoc == null ? item.DescricaoOrigem : item.NumDoc }}</span>
            </template>
            <template v-slot:item.Data="{ item }">
                <span>{{ formatDate(item.Data) }}</span>
            </template>
        </v-data-table>
    </v-row>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import { useRoute } from 'vue-router';
    import { useLoader } from '@/composables/useLoader';
    import { useAlert } from '@/composables/useAlert';

    const { showLoader, hideLoader } = useLoader();
    const $route = useRoute();
    const $alert = useAlert();

    const page = ref<number>(1);
    const pageSize = ref<number>(10);

    const props = defineProps(['documents']);

    const headers = ref([
        { title: 'Tipo Origem', key: 'TipoOrigem' },
        { title: 'Documento', key: 'CodigoOrigem' },
        { title: 'N.º Doc.', key: 'NumDoc' },
        { title: 'Série', key: 'Serie' },
        { title: 'N.º Linha', key: 'NumLinha' },
        { title: 'Cliente', key: 'Cliente' },
        { title: 'Nome', key: 'Nome' },
        { title: 'Artigo', key: 'Artigo' },
        { title: 'Data', key: 'Data' },
        { title: 'Quantidades', key: 'QuantidadeOrigem', align: 'end' },
        { title: 'Grupo', key: 'Grupo' },
    ]);

    // Return Source Type
    const sourceType = (sourceType: number) => {
        if (sourceType == 1) return 'Ordens de Fabrico';
        if (sourceType == 2) return 'Planos de Produção';
        if (sourceType == 3) return 'Encomendas';
    };

    // Format date to 'dd/mm/yyyy'
    const formatDate = (date: string) => {
        return date == null ? '' : new Date(date).toLocaleDateString('pt-PT');
    };
</script>

<style scoped></style>
