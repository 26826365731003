<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botão Filtrar -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="9"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-ml-14 tw-text-2xl tw-font-bold lg:tw-ml-14">Projetos</h1>
            </v-col>
            <!-- <v-col
                cols="3"
                class="tw-flex tw-items-center tw-justify-end">
                <v-btn

                    color="primary"
                    @click="$router.push({ name: 'CreateProductionOrder' })"
                    >{{ $t('shared.add') }}</v-btn
                >
            </v-col> -->
        </v-row>
        <v-row
            id="events-search"
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start tw-sticky tw-top-[var(--v-layout-top)] tw-z-[5] tw-mb-4 tw-bg-white">
            <v-col
                cols="12"
                class="tw-flex tw-flex-wrap tw-gap-2">
                <v-text-field
                    v-model="search"
                    class="tw-grow max-sm:tw-w-full"
                    clearable
                    @update:model-value="onSearch"
                    placeholder="Pesquisar"></v-text-field>
                <v-btn
                    size="large"
                    density="comfortable"
                    class="text-gray tw-border tw-border-gray"
                    icon="mdi-delete"
                    @click="$router.back()"></v-btn>
                <!-- <v-btn
                    size="large"
                    density="comfortable"
                    class="text-gray  tw-mr-2 tw-border tw-border-gray"
                    icon="mdi-format-list-bulleted"></v-btn> -->
            </v-col>
        </v-row>
        <v-row>
            <v-data-table-server
                v-if="done"
                :headers="headers"
                :items="projects"
                :items-length="total"
                v-model:page="page"
                v-model:items-per-page="pageSize"
                @click:row="onRowClick"
                item-value="ID">
                <template v-slot:item.DataEntregaObra="{ item }">
                    <span>{{ formatDate(item.DataEntregaObra) }}</span>
                </template>
            </v-data-table-server>
        </v-row>
    </v-container>
    <ConfirmDialog ref="confirm" />
</template>

<script setup lang="ts">
    import { WorkCenter } from '@/types';
    import { getProjects } from '@/api/projects';
    import { getArticles } from '@/api/articles';
    import { Ref, ref, watch } from 'vue';
    import { useRouter, useRoute } from 'vue-router';
    import { useLoader } from '@/composables/useLoader';
    import { useChunk } from '@/composables/useChunk';
    import _, { filter, initial, set } from 'lodash';
    import ConfirmDialog from '@/components/ConfirmDialog.vue';
    import { useAlert } from '@/composables/useAlert';

    const { showLoader, hideLoader } = useLoader();
    const $alert = useAlert();
    const $router = useRouter();
    const $route = useRoute();

    const initialQuery = ref(true);
    const done = ref(false);

    const confirm = ref<InstanceType<typeof ConfirmDialog> | null>(null);

    const searchTimeoutId = ref<NodeJS.Timeout>();
    const search = ref('');
    const page = ref(parseInt($route.query.page as string) || 1);
    const total = ref<number>(0);
    const pageSize = ref(parseInt($route.query.pageSize as string) || 10);

    const projects = ref<any[]>([]);

    const headers = ref([
        { title: 'Projeto', key: 'Codigo' },
        { title: 'Descrição', key: 'Descricao' },
        { title: 'Referencia', key: 'Referencia' },
        { title: 'Data Entrega', key: 'DataEntregaObra' },
    ]);

    // here we are initializing the query params, in case there are no query params
    async function initQueryParams() {
        if (Object.keys($route.query).length === 0) $router.push({ query: { page: page.value, pageSize: pageSize.value } });
    }

    initQueryParams();

    async function onRowClick(row: any, o: any) {
        $router.push({ name: 'Project', params: { id: o.item.ID } });
    }

    function setRouterQuery() {
        const query = {
            ..._.cloneDeep($route.query),
            pageSize: pageSize.value,
            page: page.value,
            search: undefined as string | undefined,
        };
        if (pageSize.value) query.pageSize = pageSize.value;
        if (page.value) query.page = page.value;
        if (search.value) query.search = search.value;

        $router.push({ query });
    }

    async function init() {
        showLoader();
        let _page, _pageSize;
        // if all are selected
        if (pageSize.value == -1) {
            (_page = null), (_pageSize = null);
        } else {
            _page = page.value;
            _pageSize = pageSize.value;
        }
        try {
            await getProjects(_page, _pageSize, search.value)
                .then(async (response) => {
                    projects.value = response.data.data;
                    total.value = response.data.total;

                    setRouterQuery();
                })
                .finally(async () => {
                    initialQuery.value = false;
                    done.value = true;
                    hideLoader();
                });
        } catch (error) {
            $alert.showAlert({
                type: 'error',
                text: 'Erro ao carregar Projetos',
            });
            console.error(error);
        }
    }

    // On search
    function onSearch(str: string) {
        clearTimeout(searchTimeoutId.value);
        searchTimeoutId.value = setTimeout(() => {
            search.value = str ?? '';
            setRouterQuery();
        }, 250);
    }


     // watch when query changes
     watch(
        () => $route.query,
        () => {
            pageSize.value = parseInt($route.query.pageSize as string) || 10;
            page.value = parseInt($route.query.page as string) || 1;
            search.value = ($route.query.search as string) ?? '';

            if (initialQuery.value == false) init();
        },
        {
            immediate: true,
        },
    );

    watch([page, pageSize], () => {
        setRouterQuery();
    });

    // Call Init Function
    init();

    // Format date to 'dd/mm/yyyy'
    const formatDate = (date: string) => {
        return date == null ? '' : new Date(date).toLocaleDateString('pt-PT');
    };
</script>

<style scoped></style>
