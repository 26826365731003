import { AxiosPromise } from 'axios';
import axios from '@plugins/axios';

export const API_REF = 'primavera/suppliers-orders';

export async function getSuppliersOrders(): AxiosPromise<any[]> {
    return axios.get(API_REF);
}

export async function getSuppliersOrder(id: string): AxiosPromise<any> {
    return axios.get(`${API_REF}/${id}`);
}

