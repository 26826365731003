<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botão Filtrar -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="9"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-ml-14 tw-text-2xl tw-font-bold lg:tw-ml-14">Ordens de Fabrico</h1>
            </v-col>
            <!-- <v-col
                cols="3"
                class="tw-flex tw-items-center tw-justify-end">
                <v-btn

                    color="primary"
                    @click="$router.push({ name: 'CreateProductionOrder' })"
                    >{{ $t('shared.add') }}</v-btn
                >
            </v-col> -->
        </v-row>
        <v-row
            id="events-search"
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start tw-sticky tw-top-[var(--v-layout-top)] tw-z-[5] tw-mb-4 tw-bg-white">
            <v-col
                cols="12"
                class="tw-flex tw-flex-wrap tw-gap-2">
                <v-text-field
                    v-model="search"
                    class="tw-grow max-sm:tw-w-full"
                    clearable
                    @update:model-value="onSearch"
                    placeholder="Pesquisar"></v-text-field>
                <v-btn
                    size="large"
                    density="comfortable"
                    class="text-gray tw-border tw-border-gray"
                    icon="mdi-delete"
                    @click="onDelete"></v-btn>
                <!-- <v-btn
                    size="large"
                    density="comfortable"
                    class="text-gray  tw-mr-2 tw-border tw-border-gray"
                    icon="mdi-format-list-bulleted"></v-btn> -->
            </v-col>
        </v-row>
        <v-row>
            <v-data-table-server
                v-if="done"
                :headers="headers"
                :items="productionOrders"
                :items-length="total"
                v-model:page="page"
                v-model:items-per-page="pageSize"
                show-select
                v-model="selectedItems"
                @click:row="onRowClick"
                item-value="IDOrdemFabrico">
                <!-- filters -->
                <template v-slot:header.CDU_CodigoProjeto="{ column }">
                    <div class="tw-flex tw-cursor-auto tw-justify-between">
                        <span>{{ column.title }}</span>
                        <v-menu
                            width="350"
                            v-model="isProjectFilterOpened"
                            location="bottom"
                            :close-on-content-click="false">
                            <template v-slot:activator="{ props }">
                                <v-icon
                                    v-bind="props"
                                    class="tw-cursor-pointer"
                                    color="gray"
                                    >mdi-filter-variant</v-icon
                                >
                            </template>
                            <v-list height="400">
                                <v-list-item>
                                    <div class="tw-ml-2 tw-flex tw-py-2">
                                        <v-text-field
                                            v-model="searchProject"
                                            @update:model-value="onSearchProject"
                                            clearable
                                            label="Pesquisar"
                                            variant="outlined"></v-text-field>
                                    </div>
                                </v-list-item>
                                <v-list-item
                                    v-for="item in searchedProjects"
                                    :key="item">
                                    <div class="tw-ml-2 tw-flex tw-items-center tw-py-2">
                                        <v-list-item-action start>
                                            <v-checkbox-btn
                                                color="primary"
                                                v-model="selectedProjects"
                                                :value="item"></v-checkbox-btn>
                                        </v-list-item-action>
                                        <div>
                                            <v-list-item-title>{{ item }}</v-list-item-title>
                                        </div>
                                    </div>
                                </v-list-item>
                                <v-list-item>
                                    <div class="tw-ml-2 tw-flex tw-items-center">
                                        <v-list-item-action start>
                                            <v-checkbox-btn
                                                @click="selectAllProjects()"
                                                color="primary"
                                                v-model="allProjectsSelected"></v-checkbox-btn>
                                        </v-list-item-action>
                                        <div>
                                            <v-list-item-title>Selecionar Tudo</v-list-item-title>
                                        </div>
                                    </div>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </template>
                <template v-slot:header.Estado="{ column }">
                    <div class="tw-flex tw-cursor-auto tw-justify-between">
                        <span>{{ column.title }}</span>
                        <v-menu
                            width="350"
                            v-model="isStateFilterOpened"
                            location="bottom"
                            :close-on-content-click="false">
                            <template v-slot:activator="{ props }">
                                <v-icon
                                    v-bind="props"
                                    class="tw-cursor-pointer"
                                    color="gray"
                                    >mdi-filter-variant</v-icon
                                >
                            </template>
                            <v-list height="400">
                                <v-list-item>
                                    <div class="tw-ml-2 tw-flex tw-py-2">
                                        <v-text-field
                                            v-model="searchState"
                                            @update:model-value="onSearchState"
                                            clearable
                                            label="Pesquisar"
                                            variant="outlined"></v-text-field>
                                    </div>
                                </v-list-item>
                                <v-list-item
                                    v-for="item in searchedStates"
                                    :key="item">
                                    <div class="tw-ml-2 tw-flex tw-items-center tw-py-2">
                                        <v-list-item-action start>
                                            <v-checkbox-btn
                                                color="primary"
                                                v-model="selectedStates"
                                                :value="item"></v-checkbox-btn>
                                        </v-list-item-action>
                                        <div>
                                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                                        </div>
                                    </div>
                                </v-list-item>
                                <v-list-item>
                                    <div class="tw-ml-2 tw-flex tw-items-center">
                                        <v-list-item-action start>
                                            <v-checkbox-btn
                                                @click="selectAllStates()"
                                                color="primary"
                                                v-model="allStatesSelected"></v-checkbox-btn>
                                        </v-list-item-action>
                                        <div>
                                            <v-list-item-title>Selecionar Tudo</v-list-item-title>
                                        </div>
                                    </div>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </template>
                <template v-slot:item.Descricao="{ item }">
                    <span>{{ description(item.Artigo) }}</span>
                </template>
                <template v-slot:item.DataOrdemFabrico="{ item }">
                    <span>{{ formatDate(item.DataOrdemFabrico) }}</span>
                </template>
                <template v-slot:item.Confirmada="{ item }">
                    <v-icon :color="item.Confirmada ? 'success' : 'error'">
                        {{ item.Confirmada ? 'mdi-check' : 'mdi-close' }}
                    </v-icon>
                </template>
                <template v-slot:item.Estado="{ item }">
                    <v-chip
                        size="default"
                        :color="item.Estado == 2 ? 'secondary' : item.Estado == 3 ? 'warning' : item.Estado == 4 ? 'error' : item.Estado == 5 ? 'success' : 'grey'"
                        dark>
                        {{ item.Estado == 2 ? 'Por Iniciar' : item.Estado == 3 ? 'Em Curso' : item.Estado == 4 ? 'Suspensa' : item.Estado == 5 ? 'Terminada' : 'Desconhecido' }}
                    </v-chip>
                </template>
            </v-data-table-server>
        </v-row>
    </v-container>
    <ConfirmDialog ref="confirm" />
</template>

<script setup lang="ts">
    import { WorkCenter } from '@/types';
    import { getProductionOrders, deleteProductionOrders } from '@/api/productionOrders';
    import { getProjects } from '@/api/projects';
    import { getArticles } from '@/api/articles';
    import { Ref, ref, watch } from 'vue';
    import { useRouter, useRoute } from 'vue-router';
    import { useLoader } from '@/composables/useLoader';
    import { useChunk } from '@/composables/useChunk';
    import _, { filter, initial, set } from 'lodash';
    import ConfirmDialog from '@/components/ConfirmDialog.vue';
    import { useAlert } from '@/composables/useAlert';

    const { showLoader, hideLoader } = useLoader();
    const $alert = useAlert();
    const $router = useRouter();
    const $route = useRoute();

    const initialQuery = ref(true);
    const done = ref(false);

    const confirm = ref<InstanceType<typeof ConfirmDialog> | null>(null);

    const searchTimeoutId = ref<NodeJS.Timeout>();
    const search = ref('');
    const page = ref(parseInt($route.query.page as string) || 1);
    const total = ref<number>(0);
    const pageSize = ref(parseInt($route.query.pageSize as string) || 10);
    const selectedItems = ref<any[]>([]);

    const productionOrders = ref<any[]>([]);
    const articles = ref<any[]>([]);
    const projects = ref<any[]>([]);
    const states = ref([
        { text: 'Por Iniciar', value: 2 },
        { text: 'Em Curso', value: 3 },
        { text: 'Suspensa', value: 4 },
        { text: 'Terminada', value: 5 },
        { text: 'Desconhecido', value: 0 },
    ]) as any;

    const headers = ref([
        { title: 'Ordem de Fabrico', key: 'OrdemFabrico' },
        { title: 'Artigo', key: 'Artigo' },
        { title: 'Projeto', key: 'CDU_CodigoProjeto' },
        { title: 'Descrição', key: 'Descricao' },
        { title: 'Data', key: 'DataOrdemFabrico' },
        { title: 'Confirmada', key: 'Confirmada' },
        { title: 'Valorizada', key: '' },
        { title: 'Estado', key: 'Estado' },
    ]);

    const isProjectFilterOpened = ref(false);
    const isStateFilterOpened = ref(false);

    // Declare Filters Variables
    //
    // Projects
    const selectedProjects = ref<string[]>([]);
    const allProjectsSelected = ref(false);
    const searchedProjects = ref<any[]>([]);
    const searchProject = ref('');
    // States
    const selectedStates = ref([]) as any;
    const allStatesSelected = ref(false);
    const searchedStates = ref(states.value) as any;
    const searchState = ref('');

    const filterByIds = ref<any>(null);

    // here we are initializing the query params, in case there are no query params
    async function initQueryParams() {
        if (Object.keys($route.query).length === 0) $router.push({ query: { page: page.value, pageSize: pageSize.value } });
    }

    initQueryParams();

    async function onRowClick(row: any, o: any) {
        $router.push({ name: 'ProductionOrder', params: { id: o.item.IDOrdemFabrico } });
    }

    // On Search Functions
    function onSearch(str: string) {
        clearTimeout(searchTimeoutId.value);
        searchTimeoutId.value = setTimeout(() => {
            search.value = str ?? '';
            setRouterQuery();
        }, 250);
    }

    function onSearchProject(str: string) {
        if (allProjectsSelected.value && searchedProjects.value.length !== projects.value.length) allProjectsSelected.value = false;
        else if (!allProjectsSelected.value && searchedProjects.value.length == projects.value.length) allProjectsSelected.value = true;

        searchProject.value = str ?? '';

        searchedProjects.value = [...new Set(projects.value.filter((project) => project.toLowerCase().includes(searchProject.value.toLowerCase())))];
    }

    function onSearchState(str: string) {
        if (allStatesSelected.value && searchedStates.value.length !== states.value.length) allStatesSelected.value = false;
        else if (!allStatesSelected.value && searchedStates.value.length == states.value.length) allStatesSelected.value = true;

        searchState.value = str ?? '';

        searchedStates.value = states.value.filter((state: any) => {
            if (state.text == 'Desconhecido') return false;
            else return state.text.toLowerCase().includes(searchState.value.toLowerCase());
        });
    }

    // Select All Projects Function
    async function selectAllProjects() {
        if (selectedProjects.value.length == searchedProjects.value.length) (selectedProjects.value = []), (allProjectsSelected.value = false);
        else (selectedProjects.value = searchedProjects.value), (allProjectsSelected.value = true);
    }

    // Select All States Function
    async function selectAllStates() {
        if (selectedStates.value.length == searchedStates.value.length) (selectedStates.value = []), (allStatesSelected.value = false);
        else (selectedStates.value = searchedStates.value), (allStatesSelected.value = true);
    }

    function setRouterQuery() {
        const query = {
            ..._.cloneDeep($route.query),
            pageSize: pageSize.value,
            page: page.value,
            search: undefined as string | undefined,
            projects: undefined as string | undefined,
            states: undefined as string | undefined,
        };
        if (pageSize.value) query.pageSize = pageSize.value;
        if (page.value) query.page = page.value;
        if (search.value) query.search = search.value;
        if (selectedProjects.value.length > 0) query.projects = selectedProjects.value.join(',');
        if (selectedStates.value.length > 0) query.states = selectedStates.value.map((state: any) => state.value).join(',');

        $router.push({ query });
    }

    async function init() {
        showLoader();
        let _page, _pageSize;
        // if all are selected
        if (pageSize.value == -1) {
            (_page = null), (_pageSize = null);
        } else {
            _page = page.value;
            _pageSize = pageSize.value;
        }
        try {
            await getProductionOrders(_page, _pageSize, search.value, filterByIds.value)
                .then(async (response) => {
                    productionOrders.value = response.data.data;
                    total.value = response.data.total;

                    const articlesIds = productionOrders.value.map((po) => po.Artigo);

                    //use chunk to avoid 413 error on headers
                    const chunkedArticlesIds = useChunk(articlesIds, 100);

                    for (let i = 0; i < chunkedArticlesIds.length; i++) {
                        const filterByIds = {
                            Artigo: chunkedArticlesIds[i],
                        };
                        const articlesResponse = await getArticles(null, null, null, filterByIds);
                        articles.value = [...articles.value, ...articlesResponse.data.data];
                    }
                    setRouterQuery();
                })
                .finally(async () => {
                    initialQuery.value = false;
                    done.value = true;
                    hideLoader();
                });
        } catch (error) {
            $alert.showAlert({
                type: 'error',
                text: 'Erro ao carregar Ordens de Fabrico',
            });
            console.error(error);
        }
    }

    async function _getProjects() {
        try {
            await getProjects(null, null, null)
                .then((response) => {
                    projects.value = response?.data?.data?.map((po) => po.Codigo).filter((project) => project !== null);
                    searchedProjects.value = [...new Set(projects.value)];
                })
                .catch((error) => {
                    console.error(error);
                });
        } catch (error) {
            console.error(error);
        }
    }

    const onDelete = async () => {
        try {
            if (selectedItems.value.length == 0) {
                $alert.showAlert({
                    type: 'warning',
                    text: 'Selecione pelo menos uma Ordem de Fabrico para eliminar.',
                });
                return;
            }
            const result = await confirm.value.open('Confirmação', `As Ordens de Fabrico selecionadas estão confirmadas. Tem a certeza que deseja eliminá-las?`, 'Sim', 'Não');
            if (!result) return;

            showLoader();
            // Disconfirm the production order
            // So then we can delete it
            await deleteProductionOrders(selectedItems.value as string[]).then(() => {
                init();
                $alert.showAlert({
                    type: 'success',
                    text: 'Ordens de Fabrico eliminadas com sucesso.',
                });
            });
        } catch (error) {
            $alert.showAlert({
                type: 'error',
                text:
                    (error?.response?.data?.error?.message !== undefined || error?.response?.data?.error?.message !== null) && error?.response?.data?.error?.status !== 500
                        ? error.response.data.error.message
                        : 'Erro ao eliminar as Ordens de Fabrico. Por favor, tente novamente.',
            });
            console.error(error);
        } finally {
            hideLoader();
        }
    };

    // watch when query changes
    watch(
        () => $route.query,
        () => {
            pageSize.value = parseInt($route.query.pageSize as string) || 10;
            page.value = parseInt($route.query.page as string) || 1;
            search.value = ($route.query.search as string) ?? '';
            selectedProjects.value = ($route.query.projects as string)?.split(',') ?? [];
            selectedStates.value =
                ($route.query.states as string)?.split(',').map((item) => ({
                    text: item === '2' ? 'Por Iniciar' : item === '3' ? 'Em Curso' : item === '4' ? 'Suspensa' : item === '5' ? 'Terminada' : 'Desconhecido',
                    value: parseInt(item),
                })) ?? [];

            filterByIds.value = {
                CDU_CodigoProjeto: selectedProjects.value.length > 0 ? selectedProjects.value : null,
                Estado: selectedStates.value.length > 0 ? selectedStates.value.map((state) => state.value) : null,
            };

            if (initialQuery.value == false) init();
        },
        {
            immediate: true,
        },
    );

    watch([page, pageSize], () => {
        setRouterQuery();
    });

    watch(selectedProjects, async () => {
        if (selectedProjects.value.length !== searchedProjects.value.length) allProjectsSelected.value = false;
        else allProjectsSelected.value = true;

        filterByIds.value = selectedProjects.value.length > 0 ? { CDU_CodigoProjeto: selectedProjects.value } : null;

        setRouterQuery();
    });

    watch(selectedStates, async () => {
        if (selectedStates.value.length !== searchedStates.value.length) allStatesSelected.value = false;
        else allStatesSelected.value = true;

        filterByIds.value = selectedStates.value.length > 0 ? { ...filterByIds, Estado: selectedStates.value.map((state) => state.value) } : null;
        setRouterQuery();
    });

    // Call Init and GetProjects functions
    init();
    _getProjects();

    // Format date to 'dd/mm/yyyy'
    const formatDate = (date: string) => {
        return date == null ? '' : new Date(date).toLocaleDateString('pt-PT');
    };

    // Return "Descricao" of an article
    const description = (articleId: string) => {
        const article = articles.value.find((a) => a.Artigo == articleId);
        return article?.Descricao ?? '';
    };
</script>

<style scoped></style>
