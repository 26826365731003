<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botão Filtrar -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="9"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-ml-14 tw-text-2xl tw-font-bold lg:tw-ml-14">Operações</h1>
            </v-col>
            <!-- <v-col
                cols="3"
                class="tw-flex tw-items-center tw-justify-end">
                <v-btn
                    color="primary"
                    @click="$router.push({ name: 'CreateProductionOrder' })"
                    >{{ $t('shared.add') }}</v-btn
                >
            </v-col> -->
        </v-row>
        <v-row
            id="events-search"
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start tw-sticky tw-top-[var(--v-layout-top)] tw-z-[5] tw-mb-4 tw-bg-white">
            <v-col
                cols="12"
                class="tw-flex tw-flex-wrap tw-gap-2">
                <v-text-field
                    class="tw-grow max-sm:tw-w-full"
                    v-model="search"
                    clearable
                    @update:model-value="onSearch"
                    placeholder="Pesquisar"></v-text-field>
                <!-- <v-btn

                    size="large"
                    density="comfortable"
                    class="text-gray tw-border tw-border-gray"
                    icon="mdi-tune"></v-btn> -->
                <!-- <v-btn


                    size="large"
                    density="comfortable"
                    class="text-gray  tw-mr-2 tw-border tw-border-gray"
                    icon="mdi-format-list-bulleted"></v-btn> -->
            </v-col>
        </v-row>
        <v-row>
            <v-data-table-server
                v-if="done"
                :headers="headers"
                :items="operations"
                :items-length="total"
                v-model:page="page"
                v-model:items-per-page="pageSize"
                @click:row="onRowClick"
                item-value="IDOrdemFabricoOperacao">
                <!-- filters -->
                <template v-slot:header.CentroTrabalho="{ column }">
                    <div class="tw-flex tw-cursor-auto tw-justify-between">
                        <span>{{ column.title }}</span>
                        <v-menu
                            width="350"
                            v-model="isMenuOpen"
                            location="bottom"
                            :close-on-content-click="false">
                            <template v-slot:activator="{ props }">
                                <v-icon
                                    v-bind="props"
                                    class="tw-cursor-pointer"
                                    color="gray"
                                    >mdi-filter-variant</v-icon
                                >
                            </template>
                            <v-list height="400">
                                <v-list-item>
                                    <div class="tw-ml-2 tw-flex tw-py-2">
                                        <v-text-field
                                            v-model="searchWorkCenter"
                                            @update:model-value="onSearchWorkCenter"
                                            clearable
                                            label="Pesquisar"
                                            variant="outlined"></v-text-field>
                                    </div>
                                </v-list-item>
                                <v-list-item
                                    v-for="item in searchedWorkCenters"
                                    :key="item.IDCentroTrabalho">
                                    <div class="tw-ml-2 tw-flex tw-py-2">
                                        <v-list-item-action start>
                                            <v-checkbox-btn
                                                color="primary"
                                                v-model="selectedWorkCenters"
                                                :value="item.IDCentroTrabalho"></v-checkbox-btn>
                                        </v-list-item-action>
                                        <div>
                                            <v-list-item-title>{{ item.CentroTrabalho }}</v-list-item-title>
                                            <v-list-item-subtitle>{{ item.Descricao }}</v-list-item-subtitle>
                                        </div>
                                    </div>
                                </v-list-item>
                                <v-list-item>
                                    <div class="tw-ml-2 tw-flex tw-items-center">
                                        <v-list-item-action start>
                                            <v-checkbox-btn
                                                @click="selectAllWorkCenters()"
                                                color="primary"
                                                v-model="allWorkCentersSelected"></v-checkbox-btn>
                                        </v-list-item-action>
                                        <div>
                                            <v-list-item-title>Selecionar Tudo</v-list-item-title>
                                        </div>
                                    </div>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </template>
                <!------------->
                <template v-slot:item.CentroTrabalho="{ item }">
                    <span>{{ workCenter(item.IDCentroTrabalho).name }}</span>
                </template>
                <template v-slot:item.DescricaoCentroTrabalho="{ item }">
                    <span>{{ workCenter(item.IDCentroTrabalho).description }}</span>
                </template>
                <template v-slot:item.IDOrdemFabrico="{ item }">
                    <span>{{ productionOrder(item.IDOrdemFabrico) }}</span>
                </template>
                <template v-slot:item.Estado="{ item }">
                    <v-chip
                        size="default"
                        :color="item.Estado == 7 ? 'secondary' : item.Estado == 8 ? 'warning' : item.Estado == 9 ? 'success' : 'grey'"
                        dark>
                        {{ item.Estado == 7 ? 'Por Iniciar' : item.Estado == 8 ? 'Em Curso' : item.Estado == 9 ? 'Terminada' : 'Desconhecido' }}
                    </v-chip>
                </template>
            </v-data-table-server>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import { ref, watch } from 'vue';
    import { useRouter, useRoute } from 'vue-router';
    import { useLoader } from '@/composables/useLoader';
    import { useAlert } from '@/composables/useAlert';
    import { getProductionOrdersOperations, getProductionOrders } from '@/api/productionOrders';
    import { getWorkCenters } from '@/api/workCenters';
    import _, { filter, get } from 'lodash';

    const { showLoader, hideLoader } = useLoader();
    const $alert = useAlert();

    const $router = useRouter();
    const $route = useRoute();

    const initialQuery = ref(true);
    const done = ref(false);

    const searchTimeoutId = ref<NodeJS.Timeout>();
    const search = ref('');
    const page = ref(parseInt($route.query.page as string) || 1);
    const total = ref<number>(0);
    const pageSize = ref(parseInt($route.query.pageSize as string) || 10);

    const operations = ref<any[]>([]);
    const productionOrders = ref<any[]>([]);
    const workCenters = ref<any[]>([]);

    const headers = ref([
        { title: 'ID', key: 'IDOrdemFabricoOperacao' },
        { title: 'Operação de Produção', key: 'OperacaoProducao' },
        { title: 'Operação', key: 'Operacao' },
        { title: 'Descrição', key: 'Descricao' },
        { title: 'Centro de Trabalho', key: 'CentroTrabalho' },
        { title: 'Descrição Centro de Trabalho', key: 'DescricaoCentroTrabalho' },
        { title: 'Ordem de Fabrico', key: 'IDOrdemFabrico' },
        { title: 'Estado', key: 'Estado' },
    ]);

    const isMenuOpen = ref(false);

    // Declare Filters Variables
    //
    // Work Centers
    const selectedWorkCenters = ref<number[]>([]);
    const allWorkCentersSelected = ref(false);
    // Searched Work Centers Is An Array Of Work Centers
    // That Will Be Used To Handle The Search In Filters Menu, So It's Value Will Depend On The Search Input
    // Work Centers Array Is The Variable That Contains All Work Centers, And It's Value Will Not Change
    const searchedWorkCenters = ref<any[]>([]);
    const searchWorkCenter = ref('');

    const filterByIds = ref<any>(null);

    // here we are initializing the query params, in case there are no query params
    async function initQueryParams() {
        if (Object.keys($route.query).length === 0) $router.push({ query: { page: page.value, pageSize: pageSize.value } });
    }

    initQueryParams();

    async function onRowClick(row: any, o: any) {
        $router.push({ name: 'Operation', params: { operationId: o.item.IDOrdemFabricoOperacao } });
    }

    // On Search Functions
    function onSearch(str: string) {
        clearTimeout(searchTimeoutId.value);
        searchTimeoutId.value = setTimeout(() => {
            search.value = str ?? '';
            setRouterQuery();
        }, 250);
    }

    function onSearchWorkCenter(str: string) {
        if (allWorkCentersSelected.value && searchedWorkCenters.value.length !== workCenters.value.length) allWorkCentersSelected.value = false;
        else if (!allWorkCentersSelected.value && searchedWorkCenters.value.length == workCenters.value.length) allWorkCentersSelected.value = true;

        searchWorkCenter.value = str ?? '';
        getWorkCenters(null, null, searchWorkCenter.value, null)
            .then((response) => {
                searchedWorkCenters.value = response.data.data;
            })
            .catch((error) => {
                console.error(error);
            });
    }

    // Select All Work Centers Function
    async function selectAllWorkCenters() {
        if (selectedWorkCenters.value.length == searchedWorkCenters.value.length) (selectedWorkCenters.value = []), (allWorkCentersSelected.value = false);
        else (selectedWorkCenters.value = searchedWorkCenters.value.map((wc) => wc.IDCentroTrabalho)), (allWorkCentersSelected.value = true);
    }

    function setRouterQuery() {
        const query = {
            ..._.cloneDeep($route.query),
            pageSize: pageSize.value,
            page: page.value,
            search: undefined as string | undefined,
            workCenters: undefined as string | undefined,
        };
        if (pageSize.value) query.pageSize = pageSize.value;
        if (page.value) query.page = page.value;
        if (search.value) query.search = search.value;
        if (selectedWorkCenters.value.length > 0) query.workCenters = selectedWorkCenters.value.join(',');

        $router.push({ query });
    }

    async function init() {
        showLoader();
        let _page, _pageSize, _search;
        // if all are selected
        if (pageSize.value == -1) {
            (_page = null), (_pageSize = null);
        } else {
            _page = page.value;
            _pageSize = pageSize.value;
        }
        try {
            const [operationsResponse, productionOrdersResponse, workCentersResponse] = await Promise.all([
                getProductionOrdersOperations(_page, _pageSize, search.value, filterByIds.value),
                getProductionOrders(null, null, null),
                getWorkCenters(null, null, null, null),
            ]);
            operations.value = operationsResponse.data.data;
            productionOrders.value = productionOrdersResponse.data.data;
            workCenters.value = workCentersResponse.data.data;
            searchedWorkCenters.value = workCenters.value;

            total.value = operationsResponse.data.total;

            setRouterQuery();
        } catch (error) {
            console.error(error);
            $alert.showAlert({
                type: 'error',
                text: 'Ocorreu um erro ao carregar os dados da ordem de fabrico.',
            });
        } finally {
            initialQuery.value = false;
            done.value = true;
            hideLoader();
        }
    }

    // watch when query changes
    watch(
        () => $route.query,
        () => {
            pageSize.value = parseInt($route.query.pageSize as string) || 10;
            page.value = parseInt($route.query.page as string) || 1;
            search.value = ($route.query.search as string) ?? '';
            selectedWorkCenters.value = ($route.query.workCenters as string)?.split(',').map((item) => parseInt(item)) ?? [];

            if (selectedWorkCenters.value.length > 0) filterByIds.value = { IDCentroTrabalho: selectedWorkCenters.value };

            if (initialQuery.value == false) init();
        },
        {
            immediate: true,
        },
    );

    watch([page, pageSize], () => {
        setRouterQuery();
    });

    watch(selectedWorkCenters, async () => {
        if (selectedWorkCenters.value.length !== searchedWorkCenters.value.length) allWorkCentersSelected.value = false;
        else allWorkCentersSelected.value = true;

        filterByIds.value = selectedWorkCenters.value.length > 0 ? { IDCentroTrabalho: selectedWorkCenters.value } : null;
        setRouterQuery();
        // await getProductionOrdersOperations(page.value, pageSize.value, null, filterByIds.value)
        //     .then((response) => {
        //         operations.value = response.data.data;
        //         total.value = response.data.total;
        //     })
        //     .catch((error) => {
        //         console.error(error);
        //     });
    });

    init();

    // Return "Nome da Ordem de Fabrico" instead of "ID da Ordem de Fabrico"
    const productionOrder = (id: number) => {
        const productionOrder = productionOrders.value.find((po) => po.IDOrdemFabrico == id);
        return productionOrder ? productionOrder.OrdemFabrico : '';
    };

    // Return "Centro Trabalho" information of an operation
    const workCenter = (workCenterId: any) => {
        const workCenter = workCenters.value.find((wc) => wc.IDCentroTrabalho == workCenterId);
        return workCenter ? { name: workCenter?.CentroTrabalho, description: workCenter?.Descricao } : { name: '', description: '' };
    };
</script>

<style scoped></style>
