<template>
    <v-row class="tw-mt-3">
        <v-data-table
            v-model:page="page"
            v-model:items-per-page="pageSize"
            :headers="headers"
            show-expand
            v-model:expanded="expanded"
            :items="props.productionOrders"
            @click:row="onRowClick"
            item-value="OrdemFabrico">
            <template v-slot:item.totalConsumedTime="{ item }">
                <span>{{ totalTime(item.operations).consumed }}</span>
            </template>
            <template v-slot:item.totalExpectedTime="{ item }">
                <span>{{ totalTime(item.operations).expected }}</span>
            </template>
            <template v-slot:item.totalRealCost="{ item }">
                <span>{{ totalCost(item.operations).real }}</span>
            </template>
            <template v-slot:item.totalExpectedCost="{ item }">
                <span>{{ totalCost(item.operations).expected }}</span>
            </template>
            <!-- expanded rows -->
            <template v-slot:expanded-row="{ columns, item }">
                <tr
                    v-if="item?.operations?.length > 0"
                    class="tw-bg-gray-100">
                    <td :colspan="columns.length">
                        <div class="tw-py-2">
                            <!-- Headers -->
                            <v-row class="tw-items-center tw-font-bold tw-text-secondary">
                                <v-col>
                                    <p class="tw-text-sm">Sequência</p>
                                </v-col>
                                <v-col>
                                    <p class="tw-text-sm">Operacão</p>
                                </v-col>
                                <v-col>
                                    <p class="tw-text-sm">Descrição</p>
                                </v-col>
                                <v-col class="tw-flex tw-justify-end">
                                    <p class="tw-text-sm tw-text-end">Tempo Previsto (HH:mm)</p>
                                </v-col>
                                <v-col class="tw-flex tw-justify-end">
                                    <p class="tw-text-sm tw-text-end">Tempo Consumido (HH:mm)</p>
                                </v-col>
                                <!-- <v-col>
                                    <p class="tw-text-sm">Unidade</p>
                                </v-col> -->
                                <v-col class="tw-flex tw-justify-end">
                                    <p class="tw-text-sm tw-text-end">Custo Máquina (€)</p>
                                </v-col>
                            </v-row>
                            <!-- <v-divider class="tw-py-1"></v-divider> -->
                            <!-- Rows -->
                            <v-row
                                class="tw-cursor-pointer tw-items-center"
                                v-for="(operation, index) in item?.operations"
                                :key="operation.IDOrdemFabricoOperacao"
                                @click="onExpandedRowClick(operation)">
                                <v-col>
                                    <p class="tw-text-sm tw-text-secondary">{{ operation.Operacao }}</p>
                                </v-col>
                                <v-col>
                                    <p class="tw-text-sm tw-text-secondary">{{ operation.OperacaoProducao }}</p>
                                </v-col>
                                <v-col>
                                    <p class="tw-text-sm tw-text-secondary">{{ operation.Descricao }}</p>
                                </v-col>
                                <v-col>
                                    <p class="tw-text-end tw-text-sm tw-text-secondary">{{ formatTime(operation.TempoPrevisto) }}</p>
                                </v-col>
                                <v-col>
                                    <p class="tw-text-end tw-text-sm tw-text-secondary">{{ formatTime(operation.TempoConsumido) }}</p>
                                </v-col>
                                <!-- <v-col>
                                    <p class="tw-text-sm tw-text-secondary">{{ operation.UnidadeTempo }}</p>
                                </v-col> -->
                                <v-col>
                                    <p class="tw-text-end tw-text-sm tw-text-secondary">{{ operation.CustoMaquina }}</p>
                                </v-col>
                            </v-row>
                        </div>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </v-row>
</template>

<script setup lang="ts">
    import { ref, watch } from 'vue';
    import { useRouter, useRoute } from 'vue-router';
    import _ from 'lodash';

    const $router = useRouter();
    const $route = useRoute();

    const page = ref(parseInt($route.query.page as string) || 1);
    const pageSize = ref(parseInt($route.query.pageSize as string) || 10);
    const expanded = ref<any[]>([]);

    const props = defineProps(['project', 'productionOrders', 'workCenters', 'articles']);

    const headers = ref([
        { title: 'Ordem de Fabrico', key: 'OrdemFabrico' },
        { title: 'Tempo Previsto (HH:mm)', key: 'totalExpectedTime', align: 'end' },
        { title: 'Tempo Consumido (HH:mm)', key: 'totalConsumedTime', align: 'end' },
        { title: 'Custo Previsto (€)', key: 'totalExpectedCost', align: 'end' },
        { title: 'Custo Real (€)', key: 'totalRealCost', align: 'end' },
        { sortable: false, key: 'actions' },
    ]);

    // here we are initializing the query params, in case there are no query params
    async function initQueryParams() {
        if (Object.keys($route.query).length === 0) $router.push({ query: { page: page.value, pageSize: pageSize.value } });
    }

    initQueryParams();

    async function onRowClick(row: any, o: any) {
        if (expanded.value.includes(o.item.OrdemFabrico)) {
            expanded.value = expanded.value.filter((item) => item !== o.item.OrdemFabrico);
        } else {
            expanded.value.push(o.item.OrdemFabrico);
        }
    }

    async function onExpandedRowClick(item: any) {
        $router.push({ name: 'Operation', params: { operationId: item.IDOrdemFabricoOperacao } });
    }

    function setRouterQuery() {
        const query = {
            ..._.cloneDeep($route.query),
            pageSize: pageSize.value,
            page: page.value,
        };
        if (pageSize.value) query.pageSize = pageSize.value;
        if (page.value) query.page = page.value;

        $router.push({ query });
    }

    // Watchers
    watch(
        () => $route.query,
        () => {
            pageSize.value = parseInt($route.query.pageSize as string) || 10;
            page.value = parseInt($route.query.page as string) || 1;
        },
        {
            immediate: true,
        },
    );

    watch([page, pageSize], () => {
        setRouterQuery();
    });

    // Return total time (hours) of all operations of a production order
    const totalTime = (operations: any[]) => {
        const total = operations.reduce(
            (acc, operation) => {
                acc.expected += operation.TempoPrevisto;
                acc.consumed += operation.TempoConsumido;
                return acc;
            },
            { expected: 0, consumed: 0 },
        );
        return {
            expected: formatTime(total.expected),
            consumed: formatTime(total.consumed),
        };
    };

    // Return total cost of all operations of a production order
    const totalCost = (operations: any[]) => {
        const total = operations.reduce(
            (acc, operation) => {
                acc.expected += operation.CustoMaquina * operation.TempoPrevisto;
                acc.real += operation.CustoMaquina * operation.TempoConsumido;
                return acc;
            },
            { expected: 0, real: 0 },
        );
        return {
            expected: total.expected.toFixed(2),
            real: total.real.toFixed(2),
        };
    };

    // Format time (e.g. 180 minutes to 3:00)
    const formatTime = (time: number) => {
        // Round the time value to 2 decimal places
        const roundedTime = parseFloat(time.toFixed(2));
        const hours = Math.floor(roundedTime / 60);
        const minutes = Math.floor(roundedTime % 60);

        return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
    };
</script>

<style></style>
