<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botões -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="12"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-ml-14 tw-text-2xl tw-font-bold lg:tw-ml-14">{{ suppliersOrder.Documento }}</h1>
            </v-col>
        </v-row>
        <v-form ref="form">
            <v-row class="tw-mt-3">
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('suppliersOrders.fields.entity') }}
                    </v-label>
                    <div>
                        <v-text-field
                            disabled
                            :placeholder="$t('suppliersOrders.fields.entity')"
                            v-model="suppliersOrder.Entidade"></v-text-field>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg"> {{ $t('suppliersOrders.fields.name') }} </v-label>
                    <div>
                        <v-text-field
                            disabled
                            :placeholder="$t('suppliersOrders.fields.name')"
                            v-model="suppliersOrder.Nome"></v-text-field>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg"> {{ $t('suppliersOrders.fields.address') }} </v-label>
                    <div>
                        <v-text-field
                            disabled
                            :placeholder="$t('suppliersOrders.fields.address')"
                            v-model="suppliersOrder.Morada"></v-text-field>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg"> {{ $t('suppliersOrders.fields.taxpayer') }} </v-label>
                    <div>
                        <v-text-field
                            disabled
                            :placeholder="$t('suppliersOrders.fields.taxpayer')"
                            v-model="suppliersOrder.NumContribuinte"></v-text-field>
                    </div>
                </v-col>
                <!-- Datas -->
                <v-col
                    cols="12"
                    class="tw-pb-0">
                    <v-label class="tw-text-dark tw-whitespace-normal tw-font-semibold tw-opacity-100 md:tw-text-lg"> {{ $t('suppliersOrders.fields.dates') }}: </v-label>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('suppliersOrders.fields.docDate') }}
                    </v-label>
                    <div>
                        <v-text-field
                            disabled
                            type="datetime-local"
                            v-model="suppliersOrder.DataDoc"></v-text-field>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('suppliersOrders.fields.introDate') }}
                    </v-label>
                    <div>
                        <v-text-field
                            disabled
                            type="datetime-local"
                            v-model="suppliersOrder.DataIntroducao"></v-text-field>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('suppliersOrders.fields.dueDate') }}
                    </v-label>
                    <div>
                        <v-text-field
                            disabled
                            type="datetime-local"
                            v-model="suppliersOrder.DataVencimento"></v-text-field>
                    </div>
                </v-col>
                <!-- Custos -->
                <v-col
                    cols="12"
                    class="tw-pb-0">
                    <v-label class="tw-text-dark tw-whitespace-normal tw-font-semibold tw-opacity-100 md:tw-text-lg"> {{ $t('suppliersOrders.fields.costs') }}: </v-label>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('suppliersOrders.fields.mercServ') }}
                    </v-label>
                    <div>
                        <v-text-field
                            disabled
                            type="number"
                            v-model="suppliersOrder.TotalMerc"></v-text-field>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('suppliersOrders.fields.discounts') }}
                    </v-label>
                    <div>
                        <v-text-field
                            disabled
                            type="number"
                            v-model="suppliersOrder.TotalDesc"></v-text-field>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('suppliersOrders.fields.iva') }}
                    </v-label>
                    <div>
                        <v-text-field
                            disabled
                            type="number"
                            v-model="suppliersOrder.TotalIva"></v-text-field>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('suppliersOrders.fields.others') }}
                    </v-label>
                    <div>
                        <v-text-field
                            disabled
                            type="number"
                            v-model="suppliersOrder.TotalOutros"></v-text-field>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('suppliersOrders.fields.docTotal') }}
                    </v-label>
                    <div>
                        <v-text-field
                            disabled
                            type="number"
                            v-model="totalDoc"></v-text-field>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('suppliersOrders.fields.expenses') }}
                    </v-label>
                    <div>
                        <v-text-field
                            disabled
                            type="number"
                            v-model="suppliersOrder.TotalDespesasAdicionais"></v-text-field>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('suppliersOrders.fields.total') }}
                    </v-label>
                    <div>
                        <v-text-field
                            disabled
                            type="number"
                            v-model="total"></v-text-field>
                    </div>
                </v-col>
                <v-col cols="12">
                    <v-divider class="tw-py-1"></v-divider>
                    <v-data-table
                        :headers="headers"
                        :items="suppliersOrder.LinhasCompras"
                        item-value="Id">
                        <template v-slot:item.DataEntrada="{ item }">
                            <span>{{ formatDate(item.DataEntrada) }}</span>
                        </template>
                        <template v-slot:item.PrecUnit="{ item }">
                            <span>{{ Math.abs(item.PrecUnit) }}</span>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script setup lang="ts">
    import { ref, watch, computed, onMounted } from 'vue';
    import useRules from '@/composables/rules';
    import { useLoader } from '@/composables/useLoader';
    import { useAlert } from '@/composables/useAlert';
    import { getSuppliersOrder } from '@/api/suppliersOrders';
    import { useRouter, useRoute } from 'vue-router';
    import moment from 'moment';

    const { showLoader, hideLoader } = useLoader();
    const $alert = useAlert();
    const $router = useRouter();
    const $route = useRoute();
    const id = useRoute().params.id;
    const done = ref(false);

    const suppliersOrder = ref({});

    const headers = ref([
        { title: 'Artigo', key: 'Artigo' },
        { title: 'Arm.', key: 'Armazem' },
        { title: 'Localização', key: 'Localizacao' },
        { title: 'Lote', key: 'Lote' },
        { title: 'Descrição', key: 'Descricao' },
        { title: 'Dt. Ent./Exec.', key: 'DataEntrada' },
        { title: 'CIVA', key: 'CodIva' },
        { title: 'IVA', key: 'TaxaIva', align: 'end' },
        { title: 'Pr. Unit', key: 'PrecUnit', align: 'end' },
        { title: 'Desc.', key: 'Desconto1', align: 'end' },
        { title: 'UN.', key: 'Unidade' },
        { title: 'Qtd.', key: 'Quantidade', align: 'end' },
        { title: 'Total Líq.', key: 'PrecoLiquido', align: 'end' },
        { title: 'Contrato', key: 'ContratoID' },
    ]);

    const init = async () => {
        try {
            showLoader();
            const response = await getSuppliersOrder(id as string);

            suppliersOrder.value = response.data;

            //format dates so it can be displayed in the input fields
            suppliersOrder.value.DataDoc = moment(suppliersOrder.value.DataDoc).format('YYYY-MM-DDTHH:mm');
            suppliersOrder.value.DataIntroducao = moment(suppliersOrder.value.DataIntroducao).format('YYYY-MM-DDTHH:mm');
            suppliersOrder.value.DataVencimento = moment(suppliersOrder.value.DataVencimento).format('YYYY-MM-DDTHH:mm');

            done.value = true;
        } catch (error) {
            console.error(error);
            $alert.showAlert({
                type: 'error',
                text: 'Erro ao carregar os dados. Por favor, tente novamente.',
            });
        } finally {
            hideLoader();
        }
    };

    init();

    const totalDoc = computed(() => {
        return suppliersOrder.value.TotalMerc - suppliersOrder.value.TotalDesc + suppliersOrder.value.TotalIva + suppliersOrder.value.TotalOutros;
    });

    const total = computed(() => {
        return totalDoc.value + suppliersOrder.value.TotalDespesasAdicionais;
    });

    // Format date to 'dd/mm/yyyy'
    const formatDate = (date: string) => {
        return date == null ? '' : new Date(date).toLocaleDateString('pt-PT');
    };
</script>

<style scoped></style>
