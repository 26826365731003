<template>
    <v-form
        ref="form"
        @submit.prevent="onSubmit">
        <v-row class="tw-mt-3">
            <v-col cols="12">
                <h1 class="tw-text-xl tw-font-bold">Dados Gerais</h1>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('articles.fields.article') }}
                </v-label>
                <div>
                    <v-text-field
                        :placeholder="$t('articles.fields.article')"
                        :rules="[rules.required]"
                        readonly
                        disabled
                        v-model="article.article"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('articles.fields.description') }}
                </v-label>
                <div>
                    <v-text-field
                        :placeholder="$t('articles.fields.description')"
                        :rules="[rules.required]"
                        v-model="article.description"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('articles.fields.family') }}
                </v-label>
                <div>
                    <v-select
                        :rules="[rules.required]"
                        placeholder="Familia"
                        :items="families"
                        item-title="Descricao"
                        v-model:model-value="article.family"
                        item-value="Familia">
                    </v-select>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('articles.fields.price') }}
                </v-label>
                <div>
                    <v-text-field
                        type="number"
                        :placeholder="$t('articles.fields.price')"
                        :rules="[rules.required, rules.numeric, rules.nonNegative]"
                        v-model="article.price"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('articles.fields.standardCostPrice') }}
                </v-label>
                <div>
                    <v-text-field
                        type="number"
                        :placeholder="$t('articles.fields.standardCostPrice')"
                        :rules="[rules.required, rules.numeric, rules.nonNegative]"
                        v-model="article.standardCostPrice"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('articles.fields.meanCostPrice') }}
                </v-label>
                <div>
                    <v-text-field
                        disabled
                        type="number"
                        :placeholder="$t('articles.fields.meanCostPrice')"
                        :rules="[rules.required, rules.numeric, rules.nonNegative]"
                        v-model="article.meanCostPrice"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('articles.fields.lastCostPrice') }}
                </v-label>
                <div>
                    <v-text-field
                        disabled
                        type="number"
                        :placeholder="$t('articles.fields.lastCostPrice')"
                        :rules="[rules.required, rules.numeric, rules.nonNegative]"
                        v-model="article.lastCostPrice"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('articles.fields.volume') }}
                </v-label>
                <div>
                    <v-text-field
                        type="number"
                        :placeholder="$t('articles.fields.volume')"
                        :rules="[rules.required, rules.numeric, rules.nonNegative]"
                        v-model="article.volume"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('articles.fields.weight') }}
                </v-label>
                <div>
                    <v-text-field
                        type="number"
                        :placeholder="$t('articles.fields.weight')"
                        :rules="[rules.required, rules.numeric, rules.nonNegative]"
                        v-model="article.weight"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('articles.fields.articleType') }}
                </v-label>
                <div>
                    <v-select
                        :rules="[rules.required]"
                        placeholder="Tipo de Artigo"
                        :items="articleTypes"
                        item-title="Descricao"
                        v-model:model-value="article.typeArticle"
                        item-value="TipoArtigo">
                    </v-select>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('articles.fields.usedInProduction') + '?' }}
                </v-label>
                <div>
                    <v-btn-toggle
                        mandatory
                        :rules="[rules.required]"
                        color="primary"
                        v-model="article.usedInProduction"
                        border
                        class="tw-mt-auto">
                        <v-btn :value="true">
                            {{ $t('shared.yes') }}
                        </v-btn>
                        <v-btn :value="false">
                            {{ $t('shared.no') }}
                        </v-btn>
                    </v-btn-toggle>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('articles.fields.originGPR') }}
                </v-label>
                <div>
                    <v-select
                        :placeholder="$t('articles.fields.originGPR')"
                        :items="originGPR"
                        item-title="name"
                        v-model:model-value="article.GPROriginType"
                        item-value="id">
                    </v-select>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('articles.fields.productionType') }}
                </v-label>
                <div>
                    <v-select
                        :placeholder="$t('articles.fields.productionType')"
                        :items="productionType"
                        :disabled="!article.usedInProduction"
                        item-title="name"
                        :rules="article.usedInProduction ? [rules.required] : []"
                        v-model:model-value="article.productionType"
                        item-value="id">
                    </v-select>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('articles.fields.automaticComponentsProduction') + '?' }}
                </v-label>
                <div>
                    <v-btn-toggle
                        mandatory
                        :rules="[rules.required]"
                        color="primary"
                        v-model="article.automaticComponentsProduction"
                        border
                        class="tw-mt-auto">
                        <v-btn :value="true">
                            {{ $t('shared.yes') }}
                        </v-btn>
                        <v-btn :value="false">
                            {{ $t('shared.no') }}
                        </v-btn>
                    </v-btn-toggle>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('articles.fields.IvaIncluded') + '?' }}
                </v-label>
                <div>
                    <v-btn-toggle
                        mandatory
                        :rules="[rules.required]"
                        color="primary"
                        v-model="article.IvaIncluded"
                        border
                        class="tw-mt-auto">
                        <v-btn :value="true">
                            {{ $t('shared.yes') }}
                        </v-btn>
                        <v-btn :value="false">
                            {{ $t('shared.no') }}
                        </v-btn>
                    </v-btn-toggle>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('articles.fields.IVA') }}
                </v-label>
                <div>
                    <v-text-field
                        type="number"
                        :placeholder="$t('articles.fields.IVA')"
                        :rules="[rules.required]"
                        v-model="article.IVA"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('articles.fields.unit') }}
                </v-label>
                <div>
                    <v-select
                        :placeholder="$t('articles.fields.unit')"
                        :items="units"
                        item-title="Unidade"
                        :rules="[rules.required]"
                        v-model:model-value="article.unit"
                        item-value="Unidade">
                    </v-select>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('articles.fields.sellUnit') }}
                </v-label>
                <div>
                    <v-select
                        :placeholder="$t('articles.fields.sellUnit')"
                        :items="units"
                        item-title="Unidade"
                        :rules="[rules.required]"
                        v-model:model-value="article.sellUnit"
                        item-value="Unidade">
                    </v-select>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('articles.fields.buyUnit') }}
                </v-label>
                <div>
                    <v-select
                        :placeholder="$t('articles.fields.buyUnit')"
                        :items="units"
                        item-title="Unidade"
                        :rules="[rules.required]"
                        v-model:model-value="article.buyUnit"
                        item-value="Unidade">
                    </v-select>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('articles.fields.entranceUnit') }}
                </v-label>
                <div>
                    <v-select
                        :placeholder="$t('articles.fields.entranceUnit')"
                        :items="units"
                        item-title="Unidade"
                        :rules="[rules.required]"
                        v-model:model-value="article.entranceUnit"
                        item-value="Unidade">
                    </v-select>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('articles.fields.exitUnit') }}
                </v-label>
                <div>
                    <v-select
                        :placeholder="$t('articles.fields.exitUnit')"
                        :items="units"
                        item-title="Unidade"
                        :rules="[rules.required]"
                        v-model:model-value="article.exitUnit"
                        item-value="Unidade">
                    </v-select>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('articles.fields.convertionFactor') }}
                </v-label>
                <div>
                    <v-text-field
                        type="number"
                        :placeholder="$t('articles.fields.convertionFactor')"
                        v-model="article.convertionFactor"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('articles.fields.allowsReturn') + '?' }}
                </v-label>
                <div>
                    <v-btn-toggle
                        mandatory
                        :rules="[rules.required]"
                        color="primary"
                        v-model="article.allowsReturns"
                        border
                        class="tw-mt-auto">
                        <v-btn :value="true">
                            {{ $t('shared.yes') }}
                        </v-btn>
                        <v-btn :value="false">
                            {{ $t('shared.no') }}
                        </v-btn>
                    </v-btn-toggle>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('articles.fields.batchTreatment') + '?' }}
                </v-label>
                <div>
                    <v-btn-toggle
                        mandatory
                        :rules="[rules.required]"
                        color="primary"
                        v-model="article.batchTreatment"
                        border
                        class="tw-mt-auto">
                        <v-btn :value="true">
                            {{ $t('shared.yes') }}
                        </v-btn>
                        <v-btn :value="false">
                            {{ $t('shared.no') }}
                        </v-btn>
                    </v-btn-toggle>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('articles.fields.moveStocks') + '?' }}
                </v-label>
                <div>
                    <v-btn-toggle
                        mandatory
                        :rules="[rules.required]"
                        color="primary"
                        v-model="article.moveStocks"
                        border
                        class="tw-mt-auto">
                        <v-btn value="S">
                            {{ $t('shared.yes') }}
                        </v-btn>
                        <v-btn value="N">
                            {{ $t('shared.no') }}
                        </v-btn>
                    </v-btn-toggle>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('articles.fields.calcNeeds') + '?' }}
                </v-label>
                <div>
                    <v-btn-toggle
                        mandatory
                        :rules="[rules.required]"
                        color="primary"
                        v-model="article.calcNeeds"
                        border
                        class="tw-mt-auto">
                        <v-btn :value="true">
                            {{ $t('shared.yes') }}
                        </v-btn>
                        <v-btn :value="false">
                            {{ $t('shared.no') }}
                        </v-btn>
                    </v-btn-toggle>
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col
                cols="12"
                sm="6"
                xl="6"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('articles.fields.observations') }}
                </v-label>
                <div>
                    <v-textarea
                        :placeholder="$t('articles.fields.observations')"
                        v-model="article.observations"></v-textarea>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="tw-flex tw-items-end tw-justify-end">
                <v-btn
                    color="primary"
                    type="submit"
                    >{{ $t('shared.save') }}</v-btn
                >
            </v-col>
        </v-row>
    </v-form>
</template>

<script setup lang="ts">
    import useRules from '@/composables/rules';
    import { ref } from 'vue';
    import { updateArticle } from '@/api/articles';
    import { useLoader } from '@/composables/useLoader';
    import { useAlert } from '@/composables/useAlert';
    import usePrimavera from '@/composables/primaveraParser';
    import { VForm } from 'vuetify/lib/components/index.mjs';

    const $alert = useAlert();
    const { showLoader, hideLoader } = useLoader();
    const { parseSocToPrimaveraArticle, parsePrimaveraToSocArticle } = usePrimavera();
    const form = ref<VForm>();

    const props = defineProps(['article', 'families', 'articleTypes', 'units', 'articleOperations', 'parentArticles']);
    const originGPR = ref([
        {
            id: 1,
            name: 'Fabrico',
        },
        {
            id: 2,
            name: 'Compra',
        },
        {
            id: 3,
            name: 'Fabrico/Compra',
        },
    ]);

    const productionType = ref([
        {
            id: 1,
            name: 'Assemblagem',
        },
        {
            id: 2,
            name: 'Desassemblagem',
        },
    ]);

    let article = ref(parsePrimaveraToSocArticle(props.article));
    console.log('Logging Article', article.value);
    const rules = useRules();

    const onSubmit = async () => {
        if (!form.value) return;
        const { valid } = await form.value.validate();
        if (!valid) return;

        try {
            showLoader();

            const parsedArticle = parseSocToPrimaveraArticle(article.value);

            await updateArticle(props.article.Artigo, parsedArticle);
            $alert.showAlert({
                type: 'success',
                text: 'Artigo atualizado com sucesso.',
            });
        } catch (error) {
            console.error(error);
            $alert.showAlert({
                type: 'error',
                text: error?.response?.data?.error?.message || 'Erro ao atualizar o Artigo. Por favor, tente novamente.',
            });
        } finally {
            hideLoader();
        }
    };
</script>

<style scoped></style>
