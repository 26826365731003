<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botão Filtrar -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="9"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-ml-14 tw-text-2xl tw-font-bold lg:tw-ml-14">{{ $t('internalDocuments.RI.title') }}</h1>
            </v-col>
            <v-col
                cols="3"
                class="tw-flex tw-items-center tw-justify-end">
                <v-btn
                    color="primary"
                    @click="$router.push({ name: 'CreateInternalRequest' })"
                    >{{ $t('shared.add') }}</v-btn
                >
            </v-col>
        </v-row>

        <v-row
            id="events-search"
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start tw-sticky tw-top-[var(--v-layout-top)] tw-z-[5] tw-mb-4 tw-bg-white">
            <v-col
                cols="12"
                class="tw-flex tw-flex-wrap tw-gap-2">
                <v-text-field
                    class="tw-grow max-sm:tw-w-full"
                    v-model="search"
                    clearable
                    @update:model-value="onSearch"
                    placeholder="Pesquisar"></v-text-field>
                <v-btn
                    size="large"
                    density="comfortable"
                    class="text-gray tw-border tw-border-gray"
                    icon="mdi-delete"
                    @click="onDelete"></v-btn>
                <!-- <v-btn
                    size="large"
                    density="comfortable"
                    class="text-gray  tw-mr-2 tw-border tw-border-gray"
                    icon="mdi-format-list-bulleted"></v-btn> -->
            </v-col>
        </v-row>
        <v-row>
            <v-data-table-server
                v-if="done"
                :hover="true"
                :headers="headers"
                :items="documents"
                :items-length="total"
                v-model:page="page"
                v-model:items-per-page="pageSize"
                show-select
                v-model="selectedItems"
                @click:row="goToInternalRequest"
                item-value="Id">
                <template v-slot:item.Data="{ item }">
                    <span>{{ formatDate(item.Data) }}</span>
                </template>
            </v-data-table-server>
        </v-row>
    </v-container>
    <ConfirmDialog ref="confirm" />
</template>

<script setup lang="ts">
    import { ref, computed, watch } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { useLoader } from '@/composables/useLoader';
    import { getInternalDocumentsByDocType, deleteInternalDocuments } from '@/api/internalDocuments';
    import { useAlert } from '@/composables/useAlert';
    import _, { filter, initial } from 'lodash';
    import ConfirmDialog from '@/components/ConfirmDialog.vue';

    const $route = useRoute();
    const $router = useRouter();
    const $alert = useAlert();
    const { showLoader, hideLoader } = useLoader();

    const initialQuery = ref(true);
    const done = ref(false);

    const confirm = ref<InstanceType<typeof ConfirmDialog> | null>(null);

    const searchTimeoutId = ref<NodeJS.Timeout>();
    const search = ref('');
    const page = ref(parseInt($route.query.page as string) || 1);
    const total = ref<number>(0);
    const pageSize = ref(parseInt($route.query.pageSize as string) || 10);
    const selectedItems = ref<any[]>([]);

    const documents = ref([]) as any;

    const headers = ref([
        { title: 'N.º Doc', key: 'NumDoc' },
        { title: 'Série', key: 'Serie' },
        { title: 'Documento', key: 'Documento' },
        { title: 'Data', key: 'Data' },
        { title: 'Estado', key: 'Estado' },
    ]);

    const filterByIds = ref<any>(null);

    // here we are initializing the query params, in case there are no query params
    async function initQueryParams() {
        if (Object.keys($route.query).length === 0) $router.push({ query: { page: page.value, pageSize: pageSize.value } });
    }

    initQueryParams();

    // On Search Functions
    function onSearch(str: string) {
        clearTimeout(searchTimeoutId.value);
        searchTimeoutId.value = setTimeout(() => {
            search.value = str ?? '';
            setRouterQuery();
        }, 250);
    }

    function setRouterQuery() {
        const query = {
            ..._.cloneDeep($route.query),
            pageSize: pageSize.value,
            page: page.value,
            search: undefined as string | undefined,
        };
        if (pageSize.value) query.pageSize = pageSize.value;
        if (page.value) query.page = page.value;
        if (search.value) query.search = search.value;

        $router.push({ query });
    }

    const init = async () => {
        showLoader();
        let _page, _pageSize;
        // if all are selected
        if (pageSize.value == -1) {
            (_page = null), (_pageSize = null);
        } else {
            _page = page.value;
            _pageSize = pageSize.value;
        }
        try {
            (filterByIds.value = {
                TipoDoc: ['RI'],
            }),
                await getInternalDocumentsByDocType(_page, _pageSize, search.value, filterByIds.value, 'RI')
                    .then((response) => {
                        documents.value = response.data.data;
                        total.value = response.data.total;
                        setRouterQuery();
                    })
                    .finally(async () => {
                        initialQuery.value = false;
                        done.value = true;

                        hideLoader();
                    });
        } catch (error) {
            $alert.showAlert({
                type: 'error',
                text: 'Erro ao carregar Documentos Internos',
            });
            console.error(error);
        }
    };

    const onDelete = async () => {
        try {
            if (selectedItems.value.length == 0) {
                $alert.showAlert({
                    type: 'warning',
                    text: 'Selecione pelo menos uma Requisição Interna para eliminar.',
                });
                return;
            }
            const result = await confirm.value.open('Confirmação', `Tem a certeza que deseja eliminar as Requisições Internas selecionadas?`, 'Sim', 'Não');
            if (!result) return;

            showLoader();

            await deleteInternalDocuments(selectedItems.value as string[]).then(() => {
                init();
                $alert.showAlert({
                    type: 'success',
                    text: 'Requisições Internas eliminadas com sucesso.',
                });
            });
        } catch (error) {
            $alert.showAlert({
                type: 'error',
                text:
                    (error?.response?.data?.error?.message !== undefined || error?.response?.data?.error?.message !== null) && error?.response?.data?.error?.status !== 500
                        ? error.response.data.error.message
                        : 'Erro ao eliminar as Requisições Internas. Por favor, tente novamente.',
            });
            console.error(error);
        } finally {
            hideLoader();
        }
    };

    const goToInternalRequest = (event: any, row: any) => {
        $router.push({ name: 'InternalRequest', params: { id: row.item.Id } });
    };

    // watch when query changes
    watch(
        () => $route.query,
        () => {
            pageSize.value = parseInt($route.query.pageSize as string) || 10;
            page.value = parseInt($route.query.page as string) || 1;
            search.value = ($route.query.search as string) ?? '';

            if (initialQuery.value == false) init();
        },
        {
            immediate: true,
        },
    );

    watch([page, pageSize], () => {
        setRouterQuery();
    });

    init();

    // Format date to 'dd/mm/yyyy'
    const formatDate = (date: string) => {
        return date == null ? '' : new Date(date).toLocaleDateString('pt-PT');
    };
</script>
<style scoped></style>
