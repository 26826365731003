<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="9"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-ml-14 tw-text-2xl tw-font-bold lg:tw-ml-14">{{ $t('techincalSheets.listTechnicalSheets') }}</h1>
            </v-col>
            <v-col
                cols="3"
                class="tw-flex tw-items-center tw-justify-end">
                <v-btn
                    color="primary"
                    @click="$router.push({ name: 'CreateTechnicalSheet' })"
                    >{{ $t('shared.add') }}</v-btn
                >
            </v-col>
        </v-row>
        <v-row
            id="events-search"
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start tw-sticky tw-top-[var(--v-layout-top)] tw-z-[5] tw-mb-4 tw-bg-white">
            <v-col
                cols="12"
                class="tw-flex tw-flex-wrap tw-gap-2">
                <v-text-field
                    class="tw-grow max-sm:tw-w-full"
                    v-model="search"
                    clearable
                    @update:model-value="onSearch"
                    placeholder="Pesquisar"></v-text-field>
                <v-btn
                    size="large"
                    density="comfortable"
                    class="text-gray tw-border tw-border-gray"
                    icon="mdi-delete"
                    @click="onDelete"></v-btn>
                <!-- <v-btn
                    size="large"
                    density="comfortable"
                    class="text-gray  tw-mr-2 tw-border tw-border-gray"
                    icon="mdi-format-list-bulleted"></v-btn> -->
            </v-col>
        </v-row>
        <v-row>
            <v-data-table-server
                v-if="done"
                :items="technicalSheets"
                :headers="headers"
                :items-length="total"
                @click:row="onRowClick"
                v-model:page="page"
                v-model:items-per-page="pageSize"
                show-select
                v-model="selectedItems"
                item-value="id">
                <template v-slot:item.sent="{ item }">
                    <v-chip
                        size="default"
                        :color="item.sent ? 'success' : 'error'"
                        dark>
                        {{ item.sent ? 'Sim' : 'Não' }}
                    </v-chip>
                </template>
                <template v-slot:item.createdAt="{ item }">
                    <span>{{ formatDate(item.createdAt) }}</span>
                </template>
            </v-data-table-server>
        </v-row>
    </v-container>
    <ConfirmDialog ref="confirm" />
</template>

<script setup lang="ts">
    import { parse } from 'csv-parse/browser/esm/sync';
    import * as XLSX from 'xlsx';
    import { ref, computed, watch } from 'vue';
    import { useLoader } from '@/composables/useLoader';
    import { useRoute, useRouter } from 'vue-router';
    import { getTechnicalSheets, deleteTechnicalSheets } from '@/api/technicalSheets';
    import _, { filter, initial } from 'lodash';
    import ConfirmDialog from '@/components/ConfirmDialog.vue';
    import { useAlert } from '@/composables/useAlert';

    const { showLoader, hideLoader } = useLoader();
    const $router = useRouter();
    const $route = useRoute();
    const $alert = useAlert();

    const initialQuery = ref(true);
    const done = ref(false);

    const confirm = ref<InstanceType<typeof ConfirmDialog> | null>(null);

    const searchTimeoutId = ref<NodeJS.Timeout>();
    const search = ref<string>('');
    const page = ref(parseInt($route.query.page as string) || 1);
    const pageSize = ref(parseInt($route.query.pageSize as string) || 10);
    const total = ref<number>(0);
    const selectedItems = ref<any[]>([]);

    const headers = ref<any[]>([
        {
            title: 'ID',
            key: 'id',
        },
        {
            title: 'Artigo',
            key: 'article',
        },
        {
            title: 'Enviado para Primavera?',
            key: 'sent',
        },
        {
            title: 'Data de Criação',
            key: 'createdAt',
        },
    ]);

    const technicalSheets = ref<any[]>([]);

    // here we are initializing the query params, in case there are no query params
    async function initQueryParams() {
        if (Object.keys($route.query).length === 0) $router.push({ query: { page: page.value, pageSize: pageSize.value } });
    }

    initQueryParams();

    function setRouterQuery() {
        const query = {
            ..._.cloneDeep($route.query),
            pageSize: pageSize.value,
            page: page.value,
            search: undefined as string | undefined,
        };
        if (pageSize.value) query.pageSize = pageSize.value;
        if (page.value) query.page = page.value;
        if (search.value) query.search = search.value;

        $router.push({ query });
    }

    const init = async () => {
        try {
            showLoader();
            let params = {};

            if (pageSize.value > 0) {
                params = {
                    filters: {
                        article: {
                            $containsi: search.value,
                        },
                    },
                    pagination: {
                        page: page.value,
                        pageSize: pageSize.value,
                    },
                };
            }

            const response = await getTechnicalSheets(params);

            technicalSheets.value = response.data.data;
            total.value = response.data.meta.pagination.total;
            setRouterQuery();
        } catch (error) {
            console.error(error);
            $alert.showAlert({
                type: 'error',
                text: 'Erro ao carregar as Fichas Técnicas. Por favor, tente novamente.',
            });
        } finally {
            initialQuery.value = false;
            done.value = true;
            hideLoader();
        }
    };

    const onRowClick = (row: any, e: any) => {
        $router.push({ name: 'TechnicalSheetDetail', params: { id: e.item.id } });
    };

    // On Search Functions
    function onSearch(str: string) {
        clearTimeout(searchTimeoutId.value);
        searchTimeoutId.value = setTimeout(() => {
            search.value = str ?? '';
            setRouterQuery();
        }, 250);
    }

    const onDelete = async () => {
        try {
            if (selectedItems.value.length == 0) {
                $alert.showAlert({
                    type: 'warning',
                    text: 'Selecione pelo menos uma Ficha Técnica para eliminar.',
                });
                return;
            }
            const result = await confirm.value.open('Confirmação', `Tem a certeza que deseja eliminar as Fichas Técnicas selecionadas?`, 'Sim', 'Não');
            if (!result) return;

            showLoader();
            await deleteTechnicalSheets(selectedItems.value as string[]).then(() => {
                init();
                $alert.showAlert({
                    type: 'success',
                    text: 'Fichas Técnicas eliminadas com sucesso.',
                });
            });
        } catch (error) {
            $alert.showAlert({
                type: 'error',
                text:
                    (error?.response?.data?.error?.message !== undefined || error?.response?.data?.error?.message !== null) && error?.response?.data?.error?.status !== 500
                        ? error.response.data.error.message
                        : 'Erro ao eliminar as Fichas Técnicas. Por favor, tente novamente.',
            });
            console.error(error);
        } finally {
            hideLoader();
        }
    };

    // watch when query changes
    watch(
        () => $route.query,
        () => {
            pageSize.value = parseInt($route.query.pageSize as string) || 10;
            page.value = parseInt($route.query.page as string) || 1;
            search.value = ($route.query.search as string) ?? '';

            if (initialQuery.value == false) init();
        },
        {
            immediate: true,
        },
    );

    watch([page, pageSize], () => {
        setRouterQuery();
    });

    init();

    // Format date to 'dd/mm/yyyy'
    const formatDate = (date: string) => {
        return date == null ? '' : new Date(date).toLocaleDateString('pt-PT');
    };
</script>

<style scoped></style>
