import { AxiosPromise } from 'axios';
import axios from '@plugins/axios';

export const API_REF = 'primavera/projects';

export async function getProjects(page: number | null = null, limit: number | null = null, search: string | null
): AxiosPromise<[]> {
    return axios.get(`${API_REF}`, {
        params: {
            page,
            limit,
            search,
        },
    });
}

export async function getProject(id: string): AxiosPromise<any> {
    return axios.get(`${API_REF}/${id}`);
}

export async function getProjectByCode(docType: string): AxiosPromise<any> {
    return axios.get(`${API_REF}/${docType}`);
}
