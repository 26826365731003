<template>
    <v-row class="tw-mt-3">
        <v-data-table
            :headers="headers"
            :items="props.productionOrders"
            show-expand
            v-model:expanded="expanded"
            v-model:page="page"
            v-model:items-per-page="pageSize"
            @click:row="onRowClick"
            item-value="OrdemFabrico">
            <template v-slot:item.CustoMateriaisPrevisto="{ item }">
                <span>{{ item.CustoMateriaisPrevisto.toFixed(2) }}</span>
            </template>
            <template v-slot:item.CustoMateriaisReal="{ item }">
                <span>{{ item.CustoMateriaisReal.toFixed(2) }}</span>
            </template>
            <!-- expanded rows -->
            <template v-slot:expanded-row="{ columns, item }">
                <tr
                    v-if="item?.operations?.flatMap((operation) => operation.components).length > 0"
                    class="tw-bg-gray-100">
                    <td :colspan="columns.length">
                        <div class="tw-py-2">
                            <!-- Headers -->
                            <v-row class="tw-items-center tw-font-bold tw-text-secondary">
                                <v-col cols="3">
                                    <p class="tw-text-sm">Componente</p>
                                </v-col>
                                <v-col cols="2" class="tw-flex tw-justify-end">
                                    <p class="tw-text-end tw-text-sm">Preço (€)</p>
                                </v-col>
                                <v-col cols="2" class="tw-flex tw-justify-end">
                                    <p class="tw-text-end tw-text-sm">Quantidade Consumida</p>
                                </v-col>
                                <v-col cols="2">
                                    <p class="tw-mr-14 tw-text-sm">Unidade</p>
                                </v-col>
                                <v-col cols="3" class="tw-flex tw-justify-end">
                                    <p class="tw-mr-14 tw-text-end tw-text-sm">Custo (€)</p>
                                </v-col>
                            </v-row>
                            <!-- <v-divider class="tw-py-1"></v-divider> -->
                            <!-- Rows -->
                            <v-row
                                class="tw-cursor-pointer tw-items-center"
                                v-for="(component, index) in item?.operations?.flatMap((operation) => operation.components)"
                                :key="component.Componente">
                                <v-col cols="3">
                                    <p class="tw-text-sm tw-text-secondary">{{ component.Componente }}</p>
                                </v-col>
                                <v-col cols="2">
                                    <p class="tw-text-end tw-text-sm tw-text-secondary">{{ component.Preco.toFixed(2) }}</p>
                                </v-col>
                                <v-col cols="2">
                                    <p class="tw-text-end tw-text-sm tw-text-secondary">{{ component.QtConsumida.toFixed(3) }}</p>
                                </v-col>
                                <v-col cols="2">
                                    <p class="tw-mr-14 tw-text-sm tw-text-secondary">{{ component.Unidade }}</p>
                                </v-col>
                                <v-col cols="3">
                                    <p class="tw-mr-14 tw-text-end tw-text-sm tw-text-secondary">{{ (component.Preco * component.QtConsumida).toFixed(2) }}</p>
                                </v-col>
                            </v-row>
                        </div>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </v-row>
</template>

<script setup lang="ts">
    import { ref, watch } from 'vue';
    import { useRouter, useRoute } from 'vue-router';
    import _ from 'lodash';

    const $route = useRoute();
    const $router = useRouter();

    const page = ref(parseInt($route.query.page as string) || 1);
    const pageSize = ref(parseInt($route.query.pageSize as string) || 10);
    const expanded = ref<string[]>([]);

    const props = defineProps(['productionOrders']);

    // here we are creating a flat array of components from each production order
    // so we can display the components for each production order
    // const components = props.productionOrders.map((order) => order.operations.flatMap((operation) => operation.components));
    // console.log('components', components);

    const headers = ref([
        { title: 'Artigo', key: 'Artigo' },
        { title: 'Quantidade', key: 'QtOrdemFabrico', align: 'end' },
        { title: 'Custo Materiais Previsto (€)', key: 'CustoMateriaisPrevisto', align: 'end' },
        { title: 'Custo Materiais Real (€)', key: 'CustoMateriaisReal', align: 'end' },
        // {
        //     title: 'Custo Real',
        //     align: 'center',
        //     children: [
        //         { title: 'Materiais', key: 'CustoMateriaisReal', align: 'end' },
        //         { title: 'Subprodutos', key: 'CustoSubprodutosReal', align: 'end' },
        //         { title: 'Transformação', key: 'CustoTransformacaoReal', align: 'end' },
        //         { title: 'Outros', key: 'OutrosCustosReal', align: 'end' },
        //     ],
        // },
        // {
        //     title: 'Custo Previsto',
        //     align: 'center',
        //     children: [
        //         { title: 'Materiais', key: 'CustoMateriaisPrevisto', align: 'end' },
        //         { title: 'Subprodutos', key: 'CustoSubprodutosPrevisto', align: 'end' },
        //         { title: 'Transformação', key: 'CustoTransformacaoPrevisto', align: 'end' },
        //         { title: 'Outros', key: 'OutrosCustosPrevito', align: 'end' },
        //     ],
        // },
    ]);

    // here we are initializing the query params, in case there are no query params
    async function initQueryParams() {
        if (Object.keys($route.query).length === 0) $router.push({ query: { page: page.value, pageSize: pageSize.value } });
    }

    initQueryParams();

    async function onRowClick(row: any, o: any) {
        if (expanded.value.includes(o.item.OrdemFabrico)) {
            expanded.value = expanded.value.filter((item) => item !== o.item.OrdemFabrico);
        } else {
            expanded.value.push(o.item.OrdemFabrico);
        }
    }

    function setRouterQuery() {
        const query = {
            ..._.cloneDeep($route.query),
            pageSize: pageSize.value,
            page: page.value,
        };
        if (pageSize.value) query.pageSize = pageSize.value;
        if (page.value) query.page = page.value;

        $router.push({ query });
    }

    // Watchers
    watch(
        () => $route.query,
        () => {
            pageSize.value = parseInt($route.query.pageSize as string) || 10;
            page.value = parseInt($route.query.page as string) || 1;
        },
        {
            immediate: true,
        },
    );

    watch([page, pageSize], () => {
        setRouterQuery();
    });
</script>

<style scoped></style>
