<template>
    <v-app>
        <router-view />
        <PromptUpdate />
        <v-overlay
            persistent
            class="centered-content"
            location-strategy="connected"
            scroll-strategy="reposition"
            :model-value="isLoading">
            <v-progress-circular
                indeterminate
                size="64"
                color="primary"></v-progress-circular>
        </v-overlay>
    </v-app>
</template>

<script setup lang="ts">
    import PromptUpdate from '@/components/PromptUpdate.vue';
    import { useAuth } from '@/composables/useAuth';
    import { useLoader } from '@/composables/useLoader';
    import { computed } from 'vue';

    const { getLoader } = useLoader();
    const isLoading = computed(() => getLoader.value);

    useAuth().checkAuth();
</script>

<style lang="scss">
    .centered-content .v-overlay__content {
        position: fixed;
        top: 50%;
        left: 50%;
        //left: calc(50% + 150px);
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
