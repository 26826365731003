import { AxiosPromise } from 'axios';
import axios from '@plugins/axios';

export const API_REF = 'metabase/dashboards';


export async function getDashboard(id: number): AxiosPromise<any[]> {
    return axios.get(`${API_REF}/${id}`);
}


