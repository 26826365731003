<template>
    <v-row class="tw-mt-3">
        <v-data-table
            v-model:page="page"
            v-model:items-per-page="pageSize"
            :headers="headers"
            show-expand
            v-model:expanded="expanded"
            :items="filteredProductionOrders"
            @click:row="onRowClick"
            item-value="OrdemFabrico">
            <!-- filters -->
            <template v-slot:header.Estado="{ column }">
                <div class="tw-flex tw-cursor-auto tw-justify-between">
                    <span>{{ column.title }}</span>
                    <v-menu
                        width="350"
                        v-model="isStateFilterOpened"
                        location="bottom"
                        :close-on-content-click="false">
                        <template v-slot:activator="{ props }">
                            <v-icon
                                v-bind="props"
                                class="tw-cursor-pointer"
                                color="gray"
                                >mdi-filter-variant</v-icon
                            >
                        </template>
                        <v-list height="400">
                            <v-list-item>
                                <div class="tw-ml-2 tw-flex tw-py-2">
                                    <v-text-field
                                        v-model="searchState"
                                        @update:model-value="onSearchState"
                                        clearable
                                        label="Pesquisar"
                                        variant="outlined"></v-text-field>
                                </div>
                            </v-list-item>
                            <v-list-item
                                v-for="item in searchedStates"
                                :key="item">
                                <div class="tw-ml-2 tw-flex tw-items-center tw-py-2">
                                    <v-list-item-action start>
                                        <v-checkbox-btn
                                            color="primary"
                                            v-model="selectedStates"
                                            :value="item"></v-checkbox-btn>
                                    </v-list-item-action>
                                    <div>
                                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                                    </div>
                                </div>
                            </v-list-item>
                            <v-list-item>
                                <div class="tw-ml-2 tw-flex tw-items-center">
                                    <v-list-item-action start>
                                        <v-checkbox-btn
                                            @click="selectAllStates()"
                                            color="primary"
                                            v-model="allStatesSelected"></v-checkbox-btn>
                                    </v-list-item-action>
                                    <div>
                                        <v-list-item-title>Selecionar Tudo</v-list-item-title>
                                    </div>
                                </div>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template>
            <!-- expanded rows -->
            <template v-slot:expanded-row="{ columns, item }">
                <tr
                    v-if="item?.operations?.length > 0"
                    class="tw-bg-gray-100">
                    <td :colspan="columns.length">
                        <div class="tw-py-2">
                            <!-- Headers -->
                            <v-row class="tw-items-center tw-font-bold tw-text-secondary">
                                <v-col>
                                    <p class="tw-text-sm">Sequência</p>
                                </v-col>
                                <v-col>
                                    <p class="tw-text-sm">Operacão</p>
                                </v-col>
                                <v-col>
                                    <p class="tw-text-sm">Descrição</p>
                                </v-col>
                                <v-col>
                                    <p class="tw-text-sm">Confirmada</p>
                                </v-col>
                                <v-col class="tw-flex tw-justify-end">
                                    <p class="tw-text-sm">Qtd. Fabricada</p>
                                </v-col>
                                <v-col class="tw-flex tw-justify-end">
                                    <p class="tw-text-sm">Qtd. Rejeitada</p>
                                </v-col>
                                <v-col class="tw-flex tw-justify-end">
                                    <p class="tw-text-sm">Previsto</p>
                                </v-col>
                                <v-col class="tw-flex tw-justify-end">
                                    <p class="tw-text-sm">Consumido</p>
                                </v-col>
                                <v-col>
                                    <p class="tw-text-sm">Unidade</p>
                                </v-col>
                                <v-col>
                                    <p class="tw-text-sm">CT</p>
                                </v-col>
                                <v-col>
                                    <p class="tw-text-sm">Descrição CT</p>
                                </v-col>
                                <v-col>
                                    <p class="tw-text-sm">Estado</p>
                                </v-col>
                            </v-row>
                            <!-- <v-divider class="tw-py-1"></v-divider> -->
                            <!-- Rows -->
                            <v-row
                                class="tw-cursor-pointer tw-items-center"
                                v-for="(operation, index) in item?.operations"
                                :key="operation.IDOrdemFabricoOperacao"
                                @click="onExpandedRowClick(operation)">
                                <v-col>
                                    <p class="tw-text-sm tw-text-secondary">{{ operation.Operacao }}</p>
                                </v-col>
                                <v-col>
                                    <p class="tw-text-sm tw-text-secondary">{{ operation.OperacaoProducao }}</p>
                                </v-col>
                                <v-col>
                                    <p class="tw-text-sm tw-text-secondary">{{ operation.Descricao }}</p>
                                </v-col>
                                <v-col>
                                    <v-icon
                                        class=""
                                        :color="operation.Confirmada ? 'success' : 'error'">
                                        {{ operation.Confirmada ? 'mdi-check' : 'mdi-close' }}
                                    </v-icon>
                                </v-col>
                                <v-col>
                                    <p class="tw-text-end tw-text-sm tw-text-secondary">{{ operation.QtFabricada }}</p>
                                </v-col>
                                <v-col>
                                    <p class="tw-text-end tw-text-sm tw-text-secondary">{{ operation.QtRejeitada }}</p>
                                </v-col>
                                <v-col>
                                    <p class="tw-text-end tw-text-sm tw-text-secondary">{{ formatTime(operation.TempoPrevisto) }}</p>
                                </v-col>
                                <v-col>
                                    <p class="tw-text-end tw-text-sm tw-text-secondary">{{ formatTime(operation.TempoConsumido) }}</p>
                                </v-col>
                                <v-col>
                                    <p class="tw-text-sm tw-text-secondary">{{ operation.UnidadeTempo }}</p>
                                </v-col>
                                <v-col>
                                    <p class="tw-text-sm tw-text-secondary">{{ workCenter(operation.IDCentroTrabalho).name }}</p>
                                </v-col>
                                <v-col>
                                    <p class="tw-text-sm tw-text-secondary">{{ workCenter(operation.IDCentroTrabalho).description }}</p>
                                </v-col>
                                <v-col>
                                    <v-chip
                                        size="default"
                                        :color="operation.Estado == 7 ? 'secondary' : operation.Estado == 8 ? 'warning' : operation.Estado == 9 ? 'success' : 'grey'"
                                        dark>
                                        {{ operation.Estado == 7 ? 'Por Iniciar' : operation.Estado == 8 ? 'Em Curso' : operation.Estado == 9 ? 'Terminada' : 'Desconhecido' }}
                                    </v-chip>
                                </v-col>
                            </v-row>
                        </div>
                    </td>
                </tr>
            </template>
            <template v-slot:item.DataOrdemFabrico="{ item }">
                <span>{{ formatDate(item.DataOrdemFabrico) }}</span>
            </template>
            <template v-slot:item.DataEntrega="{ item }">
                <span>{{ formatDate(item.DataEntrega) }}</span>
            </template>
            <template v-slot:item.Estado="{ item }">
                <v-chip
                    size="default"
                    :color="item.Estado == 2 ? 'secondary' : item.Estado == 3 ? 'warning' : item.Estado == 4 ? 'error' : item.Estado == 5 ? 'success' : 'grey'"
                    dark>
                    {{ item.Estado == 2 ? 'Por Iniciar' : item.Estado == 3 ? 'Em Curso' : item.Estado == 4 ? 'Suspensa' : item.Estado == 5 ? 'Terminada' : 'Desconhecido' }}
                </v-chip>
            </template>
            <template v-slot:item.Descricao="{ item }">
                <span>{{ description(item.Artigo) }}</span>
            </template>
            <template v-slot:item.DataInicio="{ item }">
                <!-- Estado 5 = Terminada -->
                <span v-if="item.Estado == 5">{{ formatDate(item.DataIniReal) }}</span>
                <!-- Estado 2 = Por Iniciar | Estado 3 = Em Curso -->
                <span v-else>{{ formatDate(item.DataIniPrevista) }}</span>
            </template>
            <template v-slot:item.DataFim="{ item }">
                <!-- Estado 5 = Terminada -->
                <span v-if="item.Estado == 5">{{ formatDate(item.DataFimReal) }}</span>
                <!-- Estado 2 = Por Iniciar | Estado 3 = Em Curso -->
                <span v-else>{{ formatDate(item.DataFimPrevista) }}</span>
            </template>
        </v-data-table>
    </v-row>
</template>

<script setup lang="ts">
    import { getProductionOrders } from '@/api/productionOrders';
    import { ref, watch } from 'vue';
    import { useAlert } from '@/composables/useAlert';
    import { useRouter, useRoute } from 'vue-router';
    import { useLoader } from '@/composables/useLoader';
    import _ from 'lodash';

    const $router = useRouter();
    const $route = useRoute();

    const initialQuery = ref(true);

    const search = ref('');
    const page = ref(parseInt($route.query.page as string) || 1);
    const pageSize = ref(parseInt($route.query.pageSize as string) || 10);
    const expanded = ref<any[]>([]);

    const props = defineProps(['project', 'productionOrders', 'workCenters', 'articles']);
    const filteredProductionOrders = ref(props.productionOrders);

    const states = ref([
        { text: 'Por Iniciar', value: 2 },
        { text: 'Em Curso', value: 3 },
        { text: 'Suspensa', value: 4 },
        { text: 'Terminada', value: 5 },
        { text: 'Desconhecido', value: 0 },
    ]) as any;

    const headers = ref([
        { title: 'Ordem de Fabrico', key: 'OrdemFabrico' },
        { title: 'Data', key: 'DataOrdemFabrico' },
        { title: 'Data Entrega', key: 'DataEntrega' },
        { title: 'Cliente', key: 'Cliente' },
        { title: 'Artigo', key: 'Artigo' },
        { title: 'Descrição', key: 'Descricao' },
        { title: 'Qtd.', key: 'QtOrdemFabrico', align: 'end' },
        { title: 'Qtd. Fabricada', key: 'QtFabricada', align: 'end' },
        { title: 'Qtd. Conforme', key: 'QtNecessaria', align: 'end' },
        { title: 'Qtd. Rejeitada', key: 'QtRejeitada', align: 'end' },
        { title: 'Início', key: 'DataInicio' },
        { title: 'Fim', key: 'DataFim' },
        { title: 'Estado', key: 'Estado' },
        { sortable: false, key: 'actions' },
    ]);

    const isStateFilterOpened = ref(false);

    // Declare Filters Variables
    //
    // States
    const selectedStates = ref([]) as any;
    const allStatesSelected = ref(false);
    const searchedStates = ref(states.value) as any;
    const searchState = ref('');

    // here we are initializing the query params, in case there are no query params
    async function initQueryParams() {
        if (Object.keys($route.query).length === 0) $router.push({ query: { page: page.value, pageSize: pageSize.value } });
    }

    initQueryParams();

    async function onRowClick(row: any, o: any) {
        if (expanded.value.includes(o.item.OrdemFabrico)) {
            expanded.value = expanded.value.filter((item) => item !== o.item.OrdemFabrico);
        } else {
            expanded.value.push(o.item.OrdemFabrico);
        }
    }

    async function onExpandedRowClick(item: any) {
        $router.push({ name: 'Operation', params: { operationId: item.IDOrdemFabricoOperacao } });
    }

    function onSearchState(str: string) {
        if (allStatesSelected.value && searchedStates.value.length !== states.value.length) allStatesSelected.value = false;
        else if (!allStatesSelected.value && searchedStates.value.length == states.value.length) allStatesSelected.value = true;

        searchState.value = str ?? '';

        searchedStates.value = states.value.filter((state: any) => {
            if (state.text == 'Desconhecido') return false;
            else return state.text.toLowerCase().includes(searchState.value.toLowerCase());
        });
    }

    // Select All States Function
    async function selectAllStates() {
        if (selectedStates.value.length == searchedStates.value.length) (selectedStates.value = []), (allStatesSelected.value = false);
        else (selectedStates.value = searchedStates.value), (allStatesSelected.value = true);
    }

    function setRouterQuery() {
        const query = {
            ..._.cloneDeep($route.query),
            pageSize: pageSize.value,
            page: page.value,
            search: undefined as string | undefined,
            projects: undefined as string | undefined,
            states: undefined as string | undefined,
        };
        if (pageSize.value) query.pageSize = pageSize.value;
        if (page.value) query.page = page.value;
        if (search.value) query.search = search.value;
        if (selectedStates.value.length > 0) query.states = selectedStates.value.map((state: any) => state.value).join(',');

        $router.push({ query });
    }

    // Watchers
    watch(
        () => $route.query,
        () => {
            pageSize.value = parseInt($route.query.pageSize as string) || 10;
            page.value = parseInt($route.query.page as string) || 1;
            search.value = ($route.query.search as string) ?? '';
            selectedStates.value =
                ($route.query.states as string)?.split(',').map((item) => ({
                    text: item === '2' ? 'Por Iniciar' : item === '3' ? 'Em Curso' : item === '4' ? 'Suspensa' : item === '5' ? 'Terminada' : 'Desconhecido',
                    value: parseInt(item),
                })) ?? [];

            if ((initialQuery.value == false) == false) {
                if (selectedStates.value.length > 0) filteredProductionOrders.value = props.productionOrders.filter((po: any) => selectedStates.value.map((state) => state.value).includes(po.Estado));
            }
        },
        {
            immediate: true,
        },
    );

    watch([page, pageSize], () => {
        setRouterQuery();
    });

    watch(selectedStates, async () => {
        if (selectedStates.value.length !== searchedStates.value.length) allStatesSelected.value = false;
        else allStatesSelected.value = true;

        if (selectedStates.value.length > 0) filteredProductionOrders.value = props.productionOrders.filter((po: any) => selectedStates.value.map((state) => state.value).includes(po.Estado));
        setRouterQuery();
    });

    // Return "Descricao" of an article
    const description = (articleId: string) => {
        const article = props.articles.find((a) => a.Artigo == articleId);
        return article?.Descricao ?? '';
    };

    // Return "Centro Trabalho" of an operation
    const workCenter = (workCenterId: any) => {
        const workCenter = props.workCenters.find((wc) => wc.IDCentroTrabalho == workCenterId);
        return workCenter ? { name: workCenter?.CentroTrabalho, description: workCenter?.Descricao } : { name: '', description: '' };
    };

    // Format time (e.g. 180 minutes to 3:00)
    const formatTime = (time: number) => {
        // Round the time value to 2 decimal places
        const roundedTime = parseFloat(time.toFixed(2));
        const hours = Math.floor(roundedTime / 60);
        const minutes = Math.floor(roundedTime % 60);

        return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
    };

    // Format date to 'dd/mm/yyyy'
    const formatDate = (date: string) => {
        return date == null ? '' : new Date(date).toLocaleDateString('pt-PT');
    };
</script>

<style></style>
