<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botões -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="9"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-ml-14 tw-text-2xl tw-font-bold lg:tw-ml-14">{{ $t('techincalSheets.createTechnicalSheet') }}</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                offset="9"
                cols="3"
                class="tw-flex tw-items-center tw-justify-end">
                <v-btn
                    type="button"
                    @click="dialog = true"
                    color="blue"
                    class="tw-mr-3">
                    Visualizar Roteiros
                </v-btn>
                <v-btn
                    type="button"
                    @click="sendTechnicalSheet"
                    color="primary">
                    Enviar Ficha Técnica
                </v-btn>
            </v-col>
        </v-row>
        <v-row
            v-if="done"
            class="tw-px-3">
            <div class="hot-table-container tw-w-full tw-overflow-x-auto">
                <hot-table
                    :data="technicalSheetToSend"
                    :licenseKey="license"
                    :contextMenu="true"
                    :columns="columns"
                    :afterChange="afterChange"
                    :afterLoadData="afterLoadData"
                    :beforeKeyDown="handleBeforeKeyDown"
                    :rowHeaders="false"
                    :stretchH="'none'"
                    :width="'100%'"
                    :colHeaders="headers"
                    ref="hotTableComponent"></hot-table>
            </div>
        </v-row>
    </v-container>
    <!-- Pop-up Visualização de Roteiros -->
    <v-dialog
        v-model="dialog"
        max-width="1400">
        <v-card class="tw-p-6">
            <v-card-title>
                <div class="tw-flex tw-items-center tw-gap-x-4">
                    <v-icon class="tw-rounded-md tw-bg-primary tw-p-5 tw-text-white">mdi-format-list-bulleted-square</v-icon>
                    <span class="text-h5 tw-font-bold">Roteiros</span>
                </div></v-card-title
            >
            <v-card-text class="tw-mt-6">
                <v-expansion-panels
                    class="tw-mb-3"
                    elevation="0"
                    v-for="routing in routings">
                    <v-expansion-panel>
                        <v-expansion-panel-title>
                            <v-row>
                                <v-col class="tw-mr-2 tw-flex tw-items-end tw-justify-between">
                                    <p class="tw-text-lg">{{ routing?.Roteiro }}</p>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <v-data-table
                                :headers="routingsTableHeaders"
                                :hover="true"
                                :items="routing?.Operacoes"></v-data-table>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
    import { parse } from 'csv-parse/browser/esm/sync';
    import * as XLSX from 'xlsx';
    import { ref, computed } from 'vue';
    import { useAlert } from '@/composables/useAlert';
    import { useLoader } from '@/composables/useLoader';
    import { createTechnicalSheet } from '@/api/technicalSheets';
    import { getOperations } from '@/api/operations';
    import { getArticles } from '@/api/articles';
    import { getRoutings } from '@/api/routings';
    import { Routing } from '@/types';

    // imports to use handsontable
    import 'handsontable/dist/handsontable.full.min.css';
    import 'handsontable/dist/handsontable.full.css';
    import { HotTable } from '@handsontable/vue3';
    import { registerAllModules } from 'handsontable/registry';
    import Handsontable from 'handsontable';

    registerAllModules();
    const license = ref(import.meta.env.VITE_APP_HANDSONTABLE_LICENSE_KEY);

    const $alert = useAlert();

    const { showLoader, hideLoader } = useLoader();

    const hotTableComponent = ref(null);
    const done = ref(false);

    const articles = ref([]);
    const operations = ref([]);

    const routings = ref<Routing[]>([]);
    const routingsTableHeaders = ref([
        { title: 'Sequência', key: 'Sequencia' },
        { title: 'Operação', key: 'Operacao' },
        { title: 'Descrição', key: 'Descricao' },
    ]);

    // variables to send to backend
    const technicalSheetToSend = ref([
        {
            Item: '1',
            'Grupo Pertencente': '',
            'Part Number': '',
            Description: '',
            QTY: '',
            Material: '',
            'Cód.Material': '',
            'Descricao Cod. Material': '',
            'Qt. Consumida por peça': '',
            Desperdicio: '',
            'Medida Final': '',
            'Medida Corte': '',
            'Posto Inicial Produção': '',
            'Posto Seguinte 1': '',
            'Posto Seguinte 2': '',
            'Posto Seguinte 3': '',
            'Posto Seguinte 4': '',
            'Posto Seguinte 5': '',
            'Posto Seguinte 6': '',
            'Tratamento/ Acabamento Superficial': '',
        },
    ]);

    const mainArticle = ref<any | null>(null);

    const dialog = ref(false);

    const createDropdownSource = (array: any, key: any) => (done.value ? array?.value?.map((item) => item[key]) : []);

    const isValidDropdownValue = (value, sourceArray) => {
        return sourceArray.includes(value) || '';
    };

    const validateAllCells = () => {
        if (hotTableComponent.value && hotTableComponent.value.hotInstance) {
            hotTableComponent.value.hotInstance.validateCells();
        }
    };

    const headers1 = ref<any | null>(null);

    const headers = ref([
        'Item',
        'Grupo Pertencente',
        'Part Number',
        'Description',
        'QTY',
        'Material',
        'Cód.Material',
        'Descricao Cod. Material',
        'Qt. Consumida por peça',
        'Desperdicio',
        'Medida Final',
        'Medida Corte',
        'Roteiro',
        'Posto Inicial Produção',
        'Posto Seguinte 1',
        'Posto Seguinte 2',
        'Posto Seguinte 3',
        'Posto Seguinte 4',
        'Posto Seguinte 5',
        'Posto Seguinte 6',
        'Tratamento/ Acabamento Superficial',
    ]);

    const columns = computed(() => {
        return [
            { data: 'Item' },
            {
                data: 'Grupo Pertencente',
            },
            {
                data: 'Part Number',
                type: 'dropdown',
                source: createDropdownSource(articles, 'Artigo'),
            }, // Use autocomplete render},
            { data: 'Description' },
            { data: 'QTY', type: 'numeric' },
            {
                data: 'Material',
            },
            {
                data: 'Cód.Material',
                type: 'dropdown',
                source: createDropdownSource(articles, 'Artigo'),
            },
            { data: 'Descricao Cod. Material' },
            { data: 'Qt. Consumida por peça', type: 'numeric' },
            { data: 'Desperdicio', type: 'numeric' },
            { data: 'Medida Final' },
            { data: 'Medida Corte' },
            {
                data: 'Roteiro',
                type: 'dropdown',
                source: createDropdownSource(routings, 'Roteiro'),
            },
            {
                data: 'Posto Inicial Produção',
                type: 'dropdown',
                source: createDropdownSource(operations, 'Operacao'),
            },
            {
                data: 'Posto Seguinte 1',
                type: 'dropdown',
                source: createDropdownSource(operations, 'Operacao'),
            },
            {
                data: 'Posto Seguinte 2',
                type: 'dropdown',
                source: createDropdownSource(operations, 'Operacao'),
            },
            {
                data: 'Posto Seguinte 3',
                type: 'dropdown',
                source: createDropdownSource(operations, 'Operacao'),
            },
            {
                data: 'Posto Seguinte 4',
                type: 'dropdown',
                source: createDropdownSource(operations, 'Operacao'),
            },
            {
                data: 'Posto Seguinte 5',
                type: 'dropdown',
                source: createDropdownSource(operations, 'Operacao'),
            },
            {
                data: 'Posto Seguinte 6',
                type: 'dropdown',
                source: createDropdownSource(operations, 'Operacao'),
            },
            {
                data: 'Tratamento/ Acabamento Superficial',
                type: 'dropdown',
                source: createDropdownSource(operations, 'Operacao'),
            },
        ];
    });

    const handleBeforeKeyDown = async (event) => {
        const hotInstance = hotTableComponent.value.hotInstance;
        const activeEditor = hotInstance.getActiveEditor();
        const selectedRange = hotInstance.getSelectedRange();
        const activeColumn = selectedRange?.[0]?.from?.col;

        const isPrintableCharacter = event.key.length === 1 && event.key.match(/^[\w\d\s\p{L}\p{N}]$/u);

        if (activeColumn === 2 && isPrintableCharacter) {
            const currentValue = activeEditor?.TEXTAREA?.value || '';
            const newValue = currentValue + event.key;

            if (newValue.length >= 3) {
                // Fetch updated articles based on newValue (user input)
                showLoader();
                await searchArticles(newValue);
                hideLoader();

                // Update the dropdown source dynamically
                const dropdownEditor = hotInstance.getActiveEditor();
                dropdownEditor.cellProperties.source = articles.value.map((article) => article.Artigo);
                setTimeout(() => dropdownEditor.open(), 0);
            }
        }
    };

    const afterChange = (changes, source) => {
        if (source === 'edit' || source === 'CopyPaste.paste') {
            changes.forEach(async ([row, prop, oldValue, newValue]) => {
                if (prop === 'Part Number' && oldValue !== newValue) {
                    showLoader();
                    await searchArticles(newValue);
                    hideLoader();

                    const article = articles.value.find((article) => article['Artigo'] === newValue);
                    if (article) {
                        technicalSheetToSend.value[row]['Description'] = article['Descricao'];
                    }
                }
                if (prop === 'Roteiro' && oldValue !== newValue) {
                    const columnsToReset = headers.value.slice(headers.value.indexOf('Posto Inicial Produção') + 1);
                    columnsToReset.forEach((column) => {
                        technicalSheetToSend.value[row][column] = '';
                    });

                    const routing = routings.value.find((routing) => routing['Roteiro'] === newValue);
                    if (routing) {
                        technicalSheetToSend.value[row]['Posto Inicial Produção'] = routing['Operacoes'][0]['Operacao'];

                        const routingOperationsLnegth = routing['Operacoes'].length;
                        const operationsColumns = routingOperationsLnegth - 1;

                        // Get Last Column
                        const lastColumn = columns.value.length - 1;

                        // Dynamically add new columns and headers after the last "Posto Seguinte"
                        const newHeaders = [];
                        const newColumns = [];

                        for (let i = 0; i < operationsColumns; i++) {
                            const headerName = `Posto Seguinte ${i + 1}`;
                            if (!headers.value.includes(headerName)) {
                                newHeaders.push(headerName);

                                newColumns.push({
                                    data: headerName,
                                    type: 'dropdown',
                                    source: createDropdownSource(operations, 'Operacao'),
                                });
                            }
                        }

                        if (newHeaders.length > 0) headers.value.splice(lastColumn, 0, ...newHeaders);
                        if (newColumns.length > 0) columns.value.splice(lastColumn, 0, ...newColumns);

                        hotTableComponent.value.hotInstance.updateSettings({
                            columns: columns.value,
                            colHeaders: headers.value,
                        });

                        // Update the data for the new columns
                        for (let i = 0; i < operationsColumns; i++) {
                            technicalSheetToSend.value[row][`Posto Seguinte ${i + 1}`] = routing['Operacoes'][i + 1]['Operacao'];
                        }
                    }
                }
            });
        }
        validateAllCells();
    };

    const afterLoadData = (sourceData, initialLoad, source) => {
        validateAllCells();
    };

    const sendTechnicalSheet = async () => {
        mainArticle.value = technicalSheetToSend.value.filter((component: any) => component['Item'] == '1' && component['QTY'] > 0)[0];

        if (!mainArticle.value) {
            $alert.showAlert({
                type: 'error',
                text: 'A quantidade deve ser superior a 0.',
            });
            return;
        }

        try {
            showLoader();
            let data = {
                article: mainArticle.value['Part Number'],
                info: technicalSheetToSend.value,
                columns: headers.value,
            };

            console.log(technicalSheetToSend.value);
            await createTechnicalSheet(data);
            hideLoader();
            $alert.showAlert({
                type: 'success',
                text: 'Ficha técnica submetida com sucesso',
            });
        } catch (error) {
            hideLoader();
            $alert.showAlert({
                type: 'error',
                text: 'Erro ao submeter ficha técnica',
            });
        }
    };

    const searchArticles = async (article: string) => {
        try {
            const response = await getArticles(null, null, article, null);
            articles.value.push(...response.data.data);
            console.log('articles', articles.value);
        } catch (error) {
            console.error('Error fetching articles:', error);
        }
    };

    const init = async () => {
        try {
            showLoader();
            const [operationsResponse, routingsResponse] = await Promise.all([getOperations(null, null, null, null), getRoutings()]);

            operations.value = operationsResponse.data;
            routings.value = routingsResponse.data;

            done.value = true;
        } catch (error) {
            console.error('Error fetching articles:', error);
            hideLoader();
        } finally {
            hideLoader();
        }
    };

    init();
</script>

<style scoped>
    .hot-table-container {
        height: 75vh;
    }
</style>
