<template>
    <Sidebar />
    <Navbar />
    <v-main
        class="tw-ml-8 tw-mt-4 lg:tw-mt-0"
        :class="{ 'mobile-layout': isMobile }">
        <ioAlert />
        <v-btn
            :readonly="!canGoBack"
            @click="goBack"
            :class="!canGoBack ? 'tw-opacity-50' : ''"
            class="!tw-absolute tw-ml-4 tw-mt-3 tw-grow tw-bg-gray-300 tw-text-xl tw-text-secondary lg:tw-ml-8 lg:tw-mt-11"
            size="small"
            icon="mdi-chevron-left">
        </v-btn>
        <!-- <v-btn @click="isSidebarOpen = !isSidebarOpen" class="tw-z-10" position="fixed" :location="'left top'" icon="mdi-menu" ></v-btn> -->
        <router-view />
    </v-main>
</template>

<script lang="ts" setup>
    import Sidebar from './Sidebar.vue';
    import Navbar from './Navbar.vue';
    import ioAlert from '@/components/ioAlert.vue';
    import { useRoute, useRouter } from 'vue-router';
    import { computed, watch, ref, readonly } from 'vue';
    import { useDisplay } from 'vuetify/lib/framework.mjs';

    const { lgAndUp } = useDisplay();
    const $route = useRoute();
    const $router = useRouter();

    const isMobile = computed(() => {
        return !lgAndUp.value;
    });

    const canGoBack = computed(() => {
        return $route.meta.canGoBack;
    });

    const goBack = () => {
        if ($route.query.page && $route.query.pageSize) {
            $router.go(-2);
        } else {
            $router.go(-1);
        }
    };
</script>
<style>
    .mobile-layout {
        --v-layout-left: 80px !important;
    }
</style>
