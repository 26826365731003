<template>
    <v-container
        v-if="done"
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botões -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="12"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-ml-14 tw-text-2xl tw-font-bold lg:tw-ml-14">{{ project.Codigo }}</h1>
            </v-col>
            <v-col
                cols="12"
                class="tw-flex tw-items-center tw-justify-between">
                <p class="tw-font-semibold">
                    {{ project.Descricao }}
                </p>
            </v-col>
            <v-col
                cols="12"
                class="tw-flex tw-items-center tw-justify-between">
                <div class="tw-text-[15px] tw-font-semibold">
                    <p class="tw-mb-2">Custo Materiais Total: {{ totalMaterialsCost.toFixed(2) }} €</p>
                    <p>Custo H/h Total: {{ totalWorkerCosts.toFixed(2) }} €</p>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="12"
                class="tw-gap-2 max-lg:tw-grid max-lg:tw-grid-cols-2 md:tw-grid-cols-4 lg:tw-flex">
                <v-btn
                    v-for="(item, index) in navItems"
                    :key="index"
                    :to="item.to"
                    min-width="128"
                    size="x-large"
                    :color="item.to.name == $route.name ? 'primary' : 'gray-200'"
                    >{{ item.title }}</v-btn
                >
            </v-col>
            <!-- <v-col
                v-if="isLoading"
                cols="12">
                <v-progress-linear
                    :active="isLoading"
                    color="primary"
                    indeterminate></v-progress-linear>
            </v-col> -->
        </v-row>
        <router-view
            v-if="done"
            :project="project"
            :productionOrders="productionOrders"
            :workCenters="workCenters"
            :articles="articles" />
    </v-container>
</template>

<script setup lang="ts">
    import { ref, computed, watch } from 'vue';
    import { useRoute } from 'vue-router';
    import { useLoader } from '@/composables/useLoader';
    import { useAlert } from '@/composables/useAlert';

    import { getProject } from '@/api/projects';
    import { getProductionOrder, getProductionOrders } from '@/api/productionOrders';
    import { getWorkCenters } from '@/api/workCenters';
    import { getArticles } from '@/api/articles';
    import { getHoursTypes } from '@/api/hoursTypes';
    import { getTimeUnits } from '@/api/timeUnits';

    const { showLoader, hideLoader } = useLoader();
    const $route = useRoute();
    const $alert = useAlert();

    const navItems = ref([
        { title: 'Custos Materiais', to: { name: 'ProjectMaterialCosts' } },
        { title: 'Custos H/h', to: { name: 'ProjectWorkerCosts' } },
        { title: 'OFs Geradas', to: { name: 'ProjectProductionOrders' } },
    ]);

    const project = ref<any>(null);
    const productionOrders = ref<any>([]);
    const workCenters = ref<any>(null);
    const articles = ref<any>(null);

    const done = ref(false);

    async function init() {
        showLoader();
        try {
            const projectId = $route.params.id;

            const [projectResponse, workentersResponse] = await Promise.all([getProject(projectId as string), getWorkCenters(null, null, null, null)]);
            project.value = projectResponse.data;
            workCenters.value = workentersResponse.data.data;

            const projectCode = projectResponse.data.Codigo;
            const filterByIds = { CDU_CodigoProjeto: [projectCode] };

            // then get production orders for this project
            await getProductionOrders(null, null, null, filterByIds).then(async (response) => {
                productionOrders.value = response.data.data;

                const articlesIds = productionOrders.value.map((po: any) => po.Artigo);
                const filterByIds = { Artigo: articlesIds };
                const articlesResponse = await getArticles(null, null, null, filterByIds);
                articles.value = articlesResponse.data.data;
            });
        } catch (error) {
            console.error(error);
            $alert.showAlert({
                type: 'error',
                text: 'Ocorreu um erro ao carregar os dados do projeto.',
            });
        } finally {
            done.value = true;
            hideLoader();
        }
    }

    init();

    const totalMaterialsCost = computed(() => {
        return productionOrders.value.reduce((acc, po) => acc + po.CustoMateriaisReal, 0);
    });

    const totalWorkerCosts = computed(() => {
        return productionOrders.value.reduce((totalCost, po) => {
            const operationsCost = po.operations.reduce((acc, operation) => {
                return acc + operation.CustoMaquina * operation.TempoConsumido;
            }, 0);
            return totalCost + operationsCost;
        }, 0);
    });
</script>

<style scoped></style>
