<template>
    <v-container
        class="flex-column justify-start tw-h-full tw-overflow-x-hidden tw-overflow-y-hidden !tw-p-0"
        fluid>
        <!-- Título e botões -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start lg:tw-px-8 lg:tw-pt-12">
            <v-col
                cols="12"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-ml-14 tw-text-2xl tw-font-bold lg:tw-ml-14">Painel</h1>
            </v-col>
        </v-row>
        <div class="tw-flex tw-h-full tw-w-full tw-flex-col tw-py-4">
            <!-- add an iframe that receives the url -->
            <iframe
                :src="iframeUrl"
                frameborder="0"
                class="tw-h-full tw-w-full tw-grow"
                allowtransparency="true"
                allowfullscreen
                @load="onIframeLoad"></iframe>
        </div>
    </v-container>
</template>

<script setup lang="ts">
    import { ref, computed } from 'vue';
    import { useAuthStore } from '@/store/auth';
    import ioImg from '@/components/ioImg.vue';
    import { onMounted, watch } from 'vue';
    import { useRoute } from 'vue-router';
    import { useLoader } from '@/composables/useLoader';
    import { getDashboard } from '@/api/metabase';

    const useAuth = useAuthStore();
    const user = useAuth.user;

    const { showLoader, hideLoader } = useLoader();

    const $route = useRoute();

    const iframeUrl = ref('');

    async function fetchDashboard(id: number) {
        showLoader();
        try {
            const response = await getDashboard(id);

            // set time to redo call
            setTimeout(() => {
                fetchDashboard(id);
            }, response.data?.expiration || 60000);

            // in the response set the title to false to hide it
            let url = response.data?.iframeUrl;
            url = url.replace('titled=true', 'titled=false');
            url = url.replace('bordered=true', 'bordered=false');

            iframeUrl.value = url;
        } catch (error) {
            console.error('Error fetching dashboard data:', error);
            hideLoader();
        }
    }

    function onIframeLoad() {
        hideLoader();
    }

    watch($route, () => {
        fetchDashboard(Number(1));
    });

    //fetch assets on mounted
    onMounted(() => {
        fetchDashboard(Number(1));
    });
</script>

<style></style>
