<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="9"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-ml-14 tw-text-2xl tw-font-bold lg:tw-ml-14">{{ $t('techincalSheets.details.title') }}</h1>
            </v-col>
            <v-col
                v-if="technicalSheet"
                cols="9"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-text-xl">{{ technicalSheet.article }}</h1>
            </v-col>
            <v-col
                cols="3"
                class="tw-flex tw-items-center tw-justify-end">
                <v-btn
                    type="button"
                    @click="previewTree"
                    color="blue">
                    Pré-visualizar em Árvore
                </v-btn>
                <v-btn
                    type="button"
                    class="mx-3"
                    @click="openConfirmSave"
                    color="secondary">
                    Guardar
                </v-btn>
                <v-btn
                    type="button"
                    @click="openConfirmSend"
                    color="primary">
                    Enviar para Primavera
                </v-btn>
                <v-btn
                    type="button"
                    @click="openDeleteConfirm"
                    class="ml-3"
                    color="red">
                    Eliminar
                </v-btn>
            </v-col>
        </v-row>
        <v-row
            v-if="done"
            class="tw-px-3">
            <div class="hot-table-container tw-w-full tw-overflow-x-auto">
                <hot-table
                    :data="technicalSheet.info"
                    :licenseKey="license"
                    :contextMenu="true"
                    :columns="columns"
                    :afterChange="afterChange"
                    :afterLoadData="afterLoadData"
                    :beforeKeyDown="handleBeforeKeyDown"
                    :rowHeaders="false"
                    :stretchH="'none'"
                    :width="'100%'"
                    :colHeaders="headers"
                    ref="hotTableComponent"></hot-table>
            </div>
        </v-row>

        <v-dialog v-model="treeVisible">
            <template v-slot:default="{ isActive }">
                <v-card>
                    <div class="tw-full tw-flex tw-justify-end">
                        <v-btn
                            size="x-large"
                            @click="treeVisible = false">
                            <i class="mdi mdi-close"></i>
                        </v-btn>
                    </div>
                    <v-treeview
                        :items="[techincalSheetTree]"
                        open-on-click
                        return-object
                        open-all
                        item-title="title"
                        item-value="title">
                        <template v-slot:prepend="{ item, open }">
                            <v-icon>
                                {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                            </v-icon>
                        </template>
                    </v-treeview>
                </v-card>
            </template>
        </v-dialog>
        <!-- <v-btn  @click="openConfirm">Delete Record</v-btn> -->
    </v-container>
    <ConfirmDialog ref="confirm" />
</template>

<script setup lang="ts">
    import { ref, onMounted, computed, nextTick, watch } from 'vue';
    import { useAlert } from '@/composables/useAlert';
    import { useLoader } from '@/composables/useLoader';
    import { useRoute, useRouter } from 'vue-router';
    import { getTechnicalSheetById, updateTechnicalSheet, deleteTechnicalSheet } from '@/api/technicalSheets';
    import { getOperations } from '@/api/operations';
    import { getUnits } from '@/api/units';
    import { getFamilies } from '@/api/families';
    import { getArticleTypes } from '@/api/articleTypes';
    import { loadTechnicalSheetsAutodeskInvetor, getArticles, getSocArticleByCode, getSocArticlesByCode } from '@/api/articles';
    import ConfirmDialog from '@/components/ConfirmDialog.vue';

    // imports to use handsontable
    import 'handsontable/dist/handsontable.full.min.css';
    import 'handsontable/dist/handsontable.full.css';
    import { HotTable } from '@handsontable/vue3';
    import { registerAllModules } from 'handsontable/registry';
    import Handsontable from 'handsontable';

    registerAllModules();
    const license = ref(import.meta.env.VITE_APP_HANDSONTABLE_LICENSE_KEY);

    const $route = useRoute();
    const $router = useRouter();
    const { showLoader, hideLoader } = useLoader();
    const $alert = useAlert();

    //const headers = ref<string[]>([]);

    const treeVisible = ref<boolean>(false);
    const technicalSheet = ref<any | null>(null);
    const techincalSheetTree = ref<any | null>(null);
    const hotTableComponent = ref(null);
    const done = ref(false);
    const units = ref([]);
    const operations = ref([]);
    // SOC ARTICLES HAVE INFORMATION ABOUT THE ARTICLES TO COMPARE WITH THE TECHNICAL SHEET (ITS A COLLECTION CREATED IN STRAPI TO STORE MATERIALS ASSOCIATED AND MANUFACTURING OPERATIONS)
    const socArticles = ref([]);
    const families = ref([]);
    const articles = ref([]);
    const originGPR = ref([
        {
            id: 1,
            name: 'Fabrico',
        },
        {
            id: 2,
            name: 'Compra',
        },
        {
            id: 3,
            name: 'Fabrico/Compra',
        },
    ]);
    const articlesTypes = ref([]);

    const confirm = ref<InstanceType<typeof ConfirmDialog> | null>(null);
    const saveArticlesDataSOC = ref(false);

    const createDropdownSource = (array: any, key: any) => (done.value ? array.value.map((item) => item[key]) : []);

    // const isValidDropdownValue = (value, sourceArray) => {
    //     return sourceArray.includes(value) || '';
    // };

    const validateAllCells = () => {
        if (hotTableComponent.value && hotTableComponent.value.hotInstance) {
            console.log('Validating cells');
            hotTableComponent.value.hotInstance.validateCells();
        }
    };

    const headers = ref([
        'Item',
        'Grupo Pertencente',
        'Part Number',
        'Description',
        'QTY',
        'Material',
        'Cód.Material',
        'Descricao Cod. Material',
        'Qt. Consumida por peça',
        'Desperdicio',
        'Medida Final',
        'Medida Corte',
        'Posto Inicial Produção',
        'Posto Seguinte 1',
        'Posto Seguinte 2',
        'Posto Seguinte 3',
        'Posto Seguinte 4',
        'Posto Seguinte 5',
        'Posto Seguinte 6',
        'Tratamento/ Acabamento Superficial',
    ]);

    const columns = computed(() => {
        return [
            { data: 'Item' },
            {
                data: 'Grupo Pertencente',
            },
            {
                data: 'Part Number',
                type: 'dropdown',
                source: createDropdownSource(articles, 'Artigo'),
                validator: function (value, callback) {
                    //return true if the value is in the source array
                    callback(createDropdownSource(articles, 'Artigo').includes(value));
                },
            }, // Use autocomplete render},
            { data: 'Description' },
            { data: 'QTY', type: 'numeric' },
            {
                data: 'Material',
            },
            {
                data: 'Cód.Material',
                type: 'dropdown',
                source: createDropdownSource(articles, 'Artigo'),
                renderer(instance, td, row, col, prop, value, cellProperties) {
                    // Use the default dropdown renderer
                    Handsontable.renderers.DropdownRenderer.apply(this, arguments);
                    const socArticle = socArticles.value.find((article) => article.code == technicalSheet.value.info[row]['Part Number']);

                    // if given material is different than info stored into the SOC article show the text in yellow
                    if (socArticle && socArticle.material_code != technicalSheet.value.info[row]['Cód.Material']) {
                        // Set the cell's innerHTML to the option's name if found
                        td.style.color = '#ffbf00';
                    }
                    return td;
                },
            },
            { data: 'Descricao Cod. Material' },
            { data: 'Qt. Consumida por peça', type: 'numeric' },
            { data: 'Desperdicio', type: 'numeric' },
            { data: 'Medida Final' },
            { data: 'Medida Corte' },
            {
                data: 'Posto Inicial Produção',
                type: 'dropdown',
                source: createDropdownSource(operations, 'Operacao'),
                renderer(instance, td, row, col, prop, value, cellProperties) {
                    // Use the default dropdown renderer
                    Handsontable.renderers.DropdownRenderer.apply(this, arguments);
                    const socArticle = socArticles.value.find((article) => article.code == technicalSheet.value.info[row]['Part Number']);

                    // if given section is different than info stored into the SOC article show the text in yellow
                    if (socArticle && socArticle.initial_section != technicalSheet.value.info[row]['Posto Inicial Produção']) {
                        // Set the cell's innerHTML to the option's name if found
                        td.style.color = '#ffbf00';
                    }
                    return td;
                },
            },
            {
                data: 'Posto Seguinte 1',
                type: 'dropdown',
                source: createDropdownSource(operations, 'Operacao'),
                renderer(instance, td, row, col, prop, value, cellProperties) {
                    // Use the default dropdown renderer
                    Handsontable.renderers.DropdownRenderer.apply(this, arguments);
                    const socArticle = socArticles.value.find((article) => article.code == technicalSheet.value.info[row]['Part Numner']);

                    // if given section is different than info stored into the SOC article show the text in yellow
                    if (socArticle && socArticle.section1 != technicalSheet.value.info[row]['Posto Seguinte 1']) {
                        // Set the cell's innerHTML to the option's name if found
                        td.style.color = '#ffbf00';
                    }
                    return td;
                },
            },
            {
                data: 'Posto Seguinte 2',
                type: 'dropdown',
                source: createDropdownSource(operations, 'Operacao'),
                renderer(instance, td, row, col, prop, value, cellProperties) {
                    // Use the default dropdown renderer
                    Handsontable.renderers.DropdownRenderer.apply(this, arguments);
                    const socArticle = socArticles.value.find((article) => article.code == technicalSheet.value.info[row]['Part Number']);

                    // if given section is different than info stored into the SOC article show the text in yellow
                    if (socArticle && socArticle.section2 != technicalSheet.value.info[row]['Posto Seguinte 2']) {
                        // Set the cell's innerHTML to the option's name if found
                        td.style.color = '#ffbf00';
                    }
                    return td;
                },
            },
            {
                data: 'Posto Seguinte 3',
                type: 'dropdown',
                source: createDropdownSource(operations, 'Operacao'),
                renderer(instance, td, row, col, prop, value, cellProperties) {
                    // Use the default dropdown renderer
                    Handsontable.renderers.DropdownRenderer.apply(this, arguments);
                    const socArticle = socArticles.value.find((article) => article.code == technicalSheet.value.info[row]['Part Number']);

                    // if given section is different than info stored into the SOC article show the text in yellow
                    if (socArticle && socArticle.section3 != technicalSheet.value.info[row]['Posto Seguinte 3']) {
                        // Set the cell's innerHTML to the option's name if found
                        td.style.color = '#ffbf00';
                    }
                    return td;
                },
            },
            {
                data: 'Posto Seguinte 4',
                type: 'dropdown',
                source: createDropdownSource(operations, 'Operacao'),
                renderer(instance, td, row, col, prop, value, cellProperties) {
                    // Use the default dropdown renderer
                    Handsontable.renderers.DropdownRenderer.apply(this, arguments);
                    const socArticle = socArticles.value.find((article) => article.code == technicalSheet.value.info[row]['Part Number']);

                    // if given section is different than info stored into the SOC article show the text in yellow
                    if (socArticle && socArticle.section4 != technicalSheet.value.info[row]['Posto Seguinte 4']) {
                        // Set the cell's innerHTML to the option's name if found
                        td.style.color = '#ffbf00';
                    }
                    return td;
                },
            },
            {
                data: 'Posto Seguinte 5',
                type: 'dropdown',
                source: createDropdownSource(operations, 'Operacao'),
                renderer(instance, td, row, col, prop, value, cellProperties) {
                    // Use the default dropdown renderer
                    Handsontable.renderers.DropdownRenderer.apply(this, arguments);
                    const socArticle = socArticles.value.find((article) => article.code == technicalSheet.value.info[row]['Part Number']);

                    // if given section is different than info stored into the SOC article show the text in yellow
                    if (socArticle && socArticle.section5 != technicalSheet.value.info[row]['Posto Seguinte 5']) {
                        // Set the cell's innerHTML to the option's name if found
                        td.style.color = '#ffbf00';
                    }
                    return td;
                },
            },
            {
                data: 'Posto Seguinte 6',
                type: 'dropdown',
                source: createDropdownSource(operations, 'Operacao'),
                renderer(instance, td, row, col, prop, value, cellProperties) {
                    // Use the default dropdown renderer
                    Handsontable.renderers.DropdownRenderer.apply(this, arguments);
                    const socArticle = socArticles.value.find((article) => article.code == technicalSheet.value.info[row]['Part Number']);

                    // if given section is different than info stored into the SOC article show the text in yellow
                    if (socArticle && socArticle.section6 != technicalSheet.value.info[row]['Posto Seguinte 6']) {
                        // Set the cell's innerHTML to the option's name if found
                        td.style.color = '#ffbf00';
                    }
                    return td;
                },
            },
            {
                data: 'Tratamento/ Acabamento Superficial',
                type: 'dropdown',
                source: createDropdownSource(operations, 'Operacao'),
                renderer(instance, td, row, col, prop, value, cellProperties) {
                    // Use the default dropdown renderer
                    Handsontable.renderers.DropdownRenderer.apply(this, arguments);
                    const socArticle = socArticles.value.find((article) => article.code == technicalSheet.value.info[row]['Part Number']);

                    // if given section is different than info stored into the SOC article show the text in yellow
                    if (socArticle && socArticle.finishing_section != technicalSheet.value.info[row]['Tratamento/ Acabamento Superficial']) {
                        // Set the cell's innerHTML to the option's name if found
                        td.style.color = '#ffbf00';
                    }
                    return td;
                },
            },
        ];
    });

    // const beforePaste = (data, coords) => {
    //     // Map display names back to IDs before pasting data
    //     data.forEach((row, rowIndex) => {
    //         row.forEach((value, colIndex) => {
    //             if (coords[0].startCol + colIndex === 19) {
    //                 // Família Nova (Primavera) column
    //                 const option = families.value.find((family) => family.Descricao === value);
    //                 data[rowIndex][colIndex] = option ? option.Familia : value;
    //             }
    //         });
    //     });
    // };

    // const afterPaste = (data, coords) => {
    //     // Ensure the pasted data is correctly mapped and validated
    //     coords.forEach((coord) => {
    //         if (coord.startCol <= 19 && coord.endCol >= 19) {
    //             // Família Nova (Primavera) column
    //             const rowData = technicalSheet.value.info[coord.startRow];
    //             const option = families.value.find((family) => family.Familia === rowData['Família Nova (Primavera)']);
    //             rowData['Família Nova (Primavera)'] = option ? option.Familia : rowData['Família Nova (Primavera)'];
    //         }
    //     });
    // };

    const afterChange = (changes: any, source: any) => {
        if (source === 'edit' || source === 'CopyPaste.paste') {
            changes.forEach(async ([row, prop, oldValue, newValue]) => {
                if (prop === 'Part Number' && oldValue !== newValue) {
                    const socArticle = getSocArticleByCode(newValue);
                    if (socArticle && socArticle.data && socArticle.data.data.length > 0) {
                        const article = socArticle.data.data[0];
                        technicalSheet.value.info[row]['Description'] = article['description'];
                        technicalSheet.value.info[row]['Cód.Material'] = article['material_code'];
                        technicalSheet.value.info[row]['Material'] = article['material'];
                        technicalSheet.value.info[row]['Qt. Consumida por peça'] = article['material_qty'];
                        technicalSheet.value.info[row]['Desperdicio'] = article['material_waste'];
                        technicalSheet.value.info[row]['Grupo Pertencente'] = article['group'];
                        technicalSheet.value.info[row]['Posto Inicial Produção'] = article['initial_section'];
                        technicalSheet.value.info[row]['Posto Seguinte 1'] = article['section1'];
                        technicalSheet.value.info[row]['Posto Seguinte 2'] = article['section2'];
                        technicalSheet.value.info[row]['Posto Seguinte 3'] = article['section3'];
                        technicalSheet.value.info[row]['Posto Seguinte 4'] = article['section4'];
                        technicalSheet.value.info[row]['Posto Seguinte 5'] = article['section5'];
                        technicalSheet.value.info[row]['Posto Seguinte 6'] = article['section6'];
                        technicalSheet.value.info[row]['Tratamento/ Acabamento Superficial'] = article['finishing_section'];
                    }
                }
            });
        }

        if (source === 'loadData') {
            console.log('Source is loadData');
            const lastColumn = columns.value.length - 1;
            const newColumns: any[] = [];
            const existingColumnNames = columns.value.map((col: any) => col.data);
            const existingColumns = Object.keys(technicalSheet.value.info[0]);

            existingColumns.forEach((key) => {
                if (key.startsWith('Posto Seguinte') && !existingColumnNames.includes(key)) {
                    newColumns.push({
                        data: key,
                        type: 'dropdown',
                        source: createDropdownSource(operations, 'Operacao'),
                    });
                }
            });

            if (newColumns.length > 0) {
                columns.value.splice(lastColumn, 0, ...newColumns);
                hotTableComponent.value.hotInstance.updateSettings({
                    columns: columns.value,
                });
            }
        }
        validateAllCells();
        console.log('Articles Length:', articles.value.length);
    };

    const afterLoadData = (sourceData, initialLoad, source) => {
        validateAllCells();
    };

    const handleBeforeKeyDown = async (event) => {
        const hotInstance = hotTableComponent.value.hotInstance;
        const activeEditor = hotInstance.getActiveEditor();
        const selectedRange = hotInstance.getSelectedRange();
        const activeColumn = selectedRange?.[0]?.from?.col;

        const isPrintableCharacter = event.key.length === 1 && event.key.match(/^[\w\d\s\p{L}\p{N}]$/u);
        if (activeColumn === 2 && isPrintableCharacter) {
            const currentValue = activeEditor?.TEXTAREA?.value || '';
            const newValue = currentValue + event.key;

            if (newValue.length >= 3) {
                // Fetch updated articles based on newValue (user input)
                showLoader();
                await searchArticles(newValue);
                hideLoader();

                // Update the dropdown source dynamically
                const dropdownEditor = hotInstance.getActiveEditor();
                dropdownEditor.cellProperties.source = articles.value.map((article) => article.Artigo);
                setTimeout(() => dropdownEditor.open(), 0);
            }
        }
    };

    const searchArticles = async (article: string) => {
        try {
            const response = await getArticles(null, null, article);
            if (response.data.data.length > 0) {
                const newArticles = response.data.data;
                newArticles.forEach((newArticle) => {
                    const articleIndex = articles.value.findIndex((article) => article.Artigo === newArticle.Artigo);
                    if (articleIndex === -1) articles.value.push(newArticle);
                });
            }
        } catch (error) {
            console.error('Error fetching articles:', error);
        }
    };

    const previewTree = async () => {
        showLoader();

        techincalSheetTree.value = await buildComponentsData(JSON.parse(JSON.stringify(technicalSheet.value.info)));
        hideLoader();
        treeVisible.value = true;
    };

    const saveTechnicalSheet = async () => {
        try {
            showLoader();
            const updated = await updateTechnicalSheet($route.params.id as string, technicalSheet.value, { updateArticle: saveArticlesDataSOC.value });
            $alert.showAlert({
                type: 'success',
                text: 'Ficha técnica guardada com sucesso.',
            });
        } catch (error) {
            console.error(error);
            $alert.showAlert({
                type: 'error',
                text: 'Erro ao guardar a ficha técnica. Por favor, tente novamente.',
            });
        } finally {
            hideLoader();
        }
    };

    const sendTechnicalSheet = async () => {
        try {
            showLoader();
            const sent = await loadTechnicalSheetsAutodeskInvetor(technicalSheet.value.info);

            technicalSheet.value.sent = true;

            // after send to primavera, update the technical sheet
            const updated = await updateTechnicalSheet($route.params.id as string, technicalSheet.value, { updateArticle: saveArticlesDataSOC.value });

            $alert.showAlert({
                type: 'success',
                text: 'Ficha técnica enviada com sucesso para o Primavera.',
            });
        } catch (error) {
            console.error(error);
            $alert.showAlert({
                type: 'error',
                text: 'Erro ao enviar a ficha técnica para o Primavera. Por favor, tente novamente.',
            });
        } finally {
            hideLoader();
        }
    };

    const deleteSheet = async () => {
        try {
            showLoader();
            const response = await deleteTechnicalSheet($route.params.id as string);
            $alert.showAlert({
                type: 'success',
                text: 'Ficha técnica eliminada com sucesso.',
            });
            $router.push({ name: 'TechnicalSheets' });
        } catch (error) {
            console.error(error);
            $alert.showAlert({
                type: 'error',
                text: 'Erro ao eliminar a ficha técnica. Por favor, tente novamente.',
            });
        } finally {
            hideLoader();
        }
    };

    const technicalRequiredFields = ['Item', 'Cód.Material', 'Descricao Cod. Material', 'Qt. Consumida por peça'];

    const buildArticleStructure = (article: any) => {
        let operations = [] as any[];
        article['title'] = article['Part Number'] + ' | ' + article['Description'] + ' | ' + article['QTY'] + ' unidades';

        if (article['Posto Inicial Produção'] != '') {
            operations.push({
                title: `Operação ${article['Posto Inicial Produção']} - ${article['Part Number']}`,
                children: [],
            });
        }

        if (article['Posto Seguinte 1'] != '') {
            operations.push({
                title: `Operação ${article['Posto Seguinte 1']}  - ${article['Part Number']}`,
                children: [],
            });
        }
        if (article['Posto Seguinte 2'] != '') {
            operations.push({
                title: `Operação ${article['Posto Seguinte 2']}  - ${article['Part Number']}`,
                children: [],
            });
        }
        if (article['Posto Seguinte 3'] != '') {
            operations.push({
                title: `Operação ${article['Posto Seguinte 3']}  - ${article['Part Number']}`,
                children: [],
            });
        }
        if (article['Posto Seguinte 4'] != '') {
            operations.push({
                title: `Operação ${article['Posto Seguinte 4']}  - ${article['Part Number']}`,
                children: [],
            });
        }
        if (article['Posto Seguinte 5'] != '') {
            operations.push({
                title: `Operação ${article['Posto Seguinte 5']}  - ${article['Part Number']}`,
                children: [],
            });
        }
        if (article['Posto Seguinte 6'] != '') {
            operations.push({
                title: `Operação ${article['Posto Seguinte 6']}  - ${article['Part Number']}`,
                children: [],
            });
        }
        if (article['Tratamento/ Acabamento Superficial'] != '') {
            operations.push({
                title: `Operação ${article['Tratamento/ Acabamento Superficial']}  - ${article['Part Number']}`,
                children: [],
            });
        }

        if (operations.length > 0) {
            operations[0]['children'] = article['childs'];
            article['children'] = operations;
        }

        // dot the code above to each children

        article['childs'].map((child: any) => {
            if (child.type && child.type == 'material') {
                return;
            }
            buildArticleStructure(child);
        });
        return article;
    };

    const buildComponentsData = async (records: any) => {
        console.log(technicalRequiredFields);
        console.log(records[0]);

        // validade if records has the required fields do it with some

        console.log(technicalRequiredFields.every((field) => records[0].hasOwnProperty(field)));

        // if (!technicalRequiredFields.every((field) => records[0].hasOwnProperty(field))) {

        //     console.log('Ficheiro de componentes inválido');
        //     $alert.showAlert({
        //         type: 'error',
        //         text: 'Ficheiro de componentes inválido',
        //     });
        //     return;
        // }

        // convert all "Item" to string
        records = records.map((article: any) => {
            article['Item'] = article['Item'].toString();
            return article;
        });

        let mainArticle = records.filter((article: any) => article['Item'] == '1' && parseFloat(article['QTY']) > 0);
        if (mainArticle.length > 1 || mainArticle.length == 0) {
            console.error('Ficha técnica inválida');
            return [];
        }

        mainArticle = mainArticle.map((article: any) => {
            article['materials'] = [];
            if ((article['Cód.Material'] != '' || article['Cód.Material'] != '-') && parseFloat(article['Qt. Consumida por peça']) > 0) {
                article['materials'].push({
                    'Cód.Material': article['Cód.Material'],
                    'Qt. Consumida por peça': parseFloat(article['Qt. Consumida por peça']),
                    //'Qt. Consumida Total': parseFloat(article['Qt. Consumida Total'].replace(',', '.')),
                    //'Unid. Consumo Material': article['Unid. Consumo Material'],
                    Material: article['Descricao Cod. Material'],
                });
            }
            return article;
        });

        let components = records.filter((article: any) => article['Item'] != '1' && article['Item'].startsWith('1.') && parseFloat(article['QTY']) > 0);

        components = components.reduce((acc: any, component: any) => {
            const childs = records.filter(
                (article: any) =>
                    article['Item'].startsWith(component['Item'] + '.') && article['Item'] !== component['Item'] && article['Item'].split('.').length == component['Item'].split('.').length + 1,
            );

            component['childs'] = childs;
            component['materials'] = [];
            component['acquired'] = [];

            if ((component['Cód.Material'] != '' || component['Cód.Material'] != '-') && parseFloat(component['Qt. Consumida por peça']) > 0) {
                component['materials'].push({
                    'Cód.Material': component['Cód.Material'],
                    'Qt. Consumida por peça': parseFloat(component['Qt. Consumida por peça']),
                    //'Unid. Consumo Material': component['Unid. Consumo Material'],
                    Material: component['Descricao Cod. Material'],
                });

                component['childs'].push({
                    title: component['Cód.Material'] + ' | ' + component['Descricao Cod. Material'] + ' | ' + component['Qt. Consumida por peça'],
                    'Cód.Material': component['Cód.Material'],
                    'Qt. Consumida por peça': parseFloat(component['Qt. Consumida por peça']),
                    //'Unid. Consumo Material': component['Unid. Consumo Material'],
                    Material: component['Descricao Cod. Material'],
                    type: 'material',
                });
            }

            acc.push(component);
            return acc;
        }, []);

        mainArticle[0]['childs'] = components.filter((component: any) => component['Item'].split('.').length == 2);

        let structuredArticles = mainArticle[0];

        let x = buildArticleStructure(structuredArticles);

        return x;
    };

    async function openConfirmSave() {
        if (confirm.value) {
            const result = await confirm.value.open(
                'Confirmação',
                'Deseja atualizar as informações dos artigos presentes no SOC? (Qualquer uma das opções guardará os dados da ficha técnica)',
                'Sim',
                'Não',
            );
            saveArticlesDataSOC.value = result;

            if (result) {
                await saveTechnicalSheet();
            }
        }
    }

    async function openConfirmSend() {
        if (confirm.value) {
            const result = await confirm.value.open(
                'Confirmação',
                'Deseja atualizar as informações dos artigos presentes no SOC? (Qualquer uma das opções guardará os dados da ficha técnica)',
                'Sim',
                'Não',
            );
            saveArticlesDataSOC.value = result;

            if (result) {
                // send to primavera
                await sendTechnicalSheet();
            }
        }
    }

    async function openDeleteConfirm() {
        if (confirm.value) {
            const result = await confirm.value.open('Confirmação', 'Deseja eliminar o registo?', 'Sim', 'Não');
            if (result) {
                // delete record
                await deleteSheet();
            }
        }
    }

    const init = async () => {
        try {
            const id = $route.params.id;
            showLoader();

            const [technicalSheetResponse, operationsResponse] = await Promise.all([getTechnicalSheetById(id as string), getOperations(null, null, null, null)]);

            technicalSheet.value = technicalSheetResponse.data.data;

            let articlesToGet = [
                ...technicalSheet.value.info.map((record: any) => record['Part Number']).filter((value: any) => value !== null && value !== undefined && value !== ''),
                ...technicalSheet.value.info.map((record: any) => record['Cód.Material']).filter((value: any) => value !== null && value !== undefined && value !== ''),
            ];
            articlesToGet = [...new Set(articlesToGet)];

            const filterByIds = { Artigo: articlesToGet };

            const [socArticlesResponse, articlesResponse] = await Promise.all([getSocArticlesByCode(articlesToGet), getArticles(null, null, null, filterByIds)]);

            // Get SOC articles to compare with the technical sheet
            socArticles.value = socArticlesResponse.data.data;
            articles.value = articlesResponse.data.data;
            operations.value = operationsResponse.data;

            // sort techincal sheet keys by the order of the headers
            technicalSheet.value.info = technicalSheet.value.info.map((record: any) => {
                const sortedRecord = {} as any;

                const lastHeader = headers.value.length - 1;
                Object.keys(record).forEach((key) => {
                    if (key.startsWith('Posto Seguinte') && !headers.value.includes(key)) {
                        headers.value.splice(lastHeader, 0, key);
                    }
                });

                //ordena os postos de forma que fiquem na ordem correta - eg: posto seguinte 8 tem de vir depois do posto seguinte 7
                headers.value.sort((a, b) => {
                    if (a.startsWith('Posto Seguinte') && b.startsWith('Posto Seguinte')) {
                        return parseInt(a.split(' ')[2]) - parseInt(b.split(' ')[2]);
                    }
                });

                headers.value.forEach((header) => {
                    sortedRecord[header] = record[header];
                });

                return sortedRecord;
            });

            // console.log('headers', headers.value);

            done.value = true;
        } catch (error) {
            console.error(error);
            $alert.showAlert({
                type: 'error',
                text: 'Erro ao carregar os dados da ficha técnica. Por favor, tente novamente.',
            });
        } finally {
            hideLoader();
        }
    };

    init();
</script>

<style scoped>
    .hot-table-container {
        height: 85vh;
    }
</style>
