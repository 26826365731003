<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botão Filtrar -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="12"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-ml-14 tw-text-2xl tw-font-bold lg:tw-ml-14">{{ $t('suppliersOrders.title') }}</h1>
            </v-col>
        </v-row>
        <v-row
            id="events-search"
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start tw-sticky tw-top-[var(--v-layout-top)] tw-z-[5] tw-mb-4 tw-bg-white">
            <v-col
                cols="12"
                class="tw-flex tw-flex-wrap tw-gap-2">
                <v-text-field
                    v-model="search"
                    class="tw-grow max-sm:tw-w-full"
                    clearable
                    @update:model-value="onSearch"
                    placeholder="Pesquisar"></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-data-table
                v-if="done"
                v-model:page="page"
                v-model:items-per-page="pageSize"
                :headers="headers"
                :items="filteredSuppliersOrders"
                @click:row="onRowClick"
                item-value="ID">
                <!-- filters -->
                <!-- Num Doc. -->
                <template v-slot:header.NumDoc="{ column }">
                    <div class="tw-flex tw-cursor-auto tw-justify-between">
                        <span>{{ column.title }}</span>
                        <v-menu
                            width="350"
                            v-model="isNumDocFilterOpened"
                            location="bottom"
                            :close-on-content-click="false">
                            <template v-slot:activator="{ props }">
                                <v-icon
                                    v-bind="props"
                                    class="tw-cursor-pointer"
                                    color="gray"
                                    >mdi-filter-variant</v-icon
                                >
                            </template>
                            <v-list height="400">
                                <v-list-item>
                                    <div class="tw-ml-2 tw-flex tw-py-2">
                                        <v-text-field
                                            v-model="searchNumDoc"
                                            @update:model-value="onSearchNumDoc"
                                            clearable
                                            label="Pesquisar"
                                            variant="outlined"></v-text-field>
                                    </div>
                                </v-list-item>
                                <v-list-item
                                    v-for="item in searchedNumDocs"
                                    :key="item">
                                    <div class="tw-ml-2 tw-flex tw-items-center tw-py-2">
                                        <v-list-item-action start>
                                            <v-checkbox-btn
                                                color="primary"
                                                v-model="selectedNumDocs"
                                                :value="item"></v-checkbox-btn>
                                        </v-list-item-action>
                                        <div>
                                            <v-list-item-title>{{ item }}</v-list-item-title>
                                        </div>
                                    </div>
                                </v-list-item>
                                <v-list-item>
                                    <div class="tw-ml-2 tw-flex tw-items-center">
                                        <v-list-item-action start>
                                            <v-checkbox-btn
                                                @click="selectAllNumDocs()"
                                                color="primary"
                                                v-model="allNumDocsSelected"></v-checkbox-btn>
                                        </v-list-item-action>
                                        <div>
                                            <v-list-item-title>Selecionar Tudo</v-list-item-title>
                                        </div>
                                    </div>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </template>
                <!------------->
                <!-- Fechado -->
                <template v-slot:header.Fechado="{ column }">
                    <div class="tw-flex tw-cursor-auto tw-justify-between">
                        <span>{{ column.title }}</span>
                        <v-menu
                            width="350"
                            v-model="isClosedFilterOpened"
                            location="bottom"
                            :close-on-content-click="false">
                            <template v-slot:activator="{ props }">
                                <v-icon
                                    v-bind="props"
                                    class="tw-cursor-pointer"
                                    color="gray"
                                    >mdi-filter-variant</v-icon
                                >
                            </template>
                            <v-list height="400">
                                <v-list-item>
                                    <div class="tw-ml-2 tw-flex tw-py-2">
                                        <v-text-field
                                            v-model="searchClosed"
                                            @update:model-value="onSearchClosed"
                                            clearable
                                            label="Pesquisar"
                                            variant="outlined"></v-text-field>
                                    </div>
                                </v-list-item>
                                <v-list-item
                                    v-for="item in searchedCloseds"
                                    :key="item">
                                    <div class="tw-ml-2 tw-flex tw-items-center tw-py-2">
                                        <v-list-item-action start>
                                            <v-checkbox-btn
                                                color="primary"
                                                v-model="selectedCloseds"
                                                :value="item"></v-checkbox-btn>
                                        </v-list-item-action>
                                        <div>
                                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                                        </div>
                                    </div>
                                </v-list-item>
                                <v-list-item>
                                    <div class="tw-ml-2 tw-flex tw-items-center">
                                        <v-list-item-action start>
                                            <v-checkbox-btn
                                                @click="selectAllCloseds()"
                                                color="primary"
                                                v-model="allClosedsSelected"></v-checkbox-btn>
                                        </v-list-item-action>
                                        <div>
                                            <v-list-item-title>Selecionar Tudo</v-list-item-title>
                                        </div>
                                    </div>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </template>
                <!------------->
                <template v-slot:item.Fechado="{ item }">
                    <v-chip
                        size="default"
                        :color="item.Fechado ? 'success' : 'error'"
                        dark>
                        {{ item.Fechado ? 'Sim' : 'Não' }}
                    </v-chip>
                </template>
                <template v-slot:item.DataDoc="{ item }">
                    <span>{{ formatDate(item.DataDoc) }}</span>
                </template>
                <template v-slot:item.DataEntrega="{ item }">
                    <span>{{ formatDate(item.DataEntrega) }}</span>
                </template>
            </v-data-table>
        </v-row>
    </v-container>
    <ConfirmDialog ref="confirm" />
</template>

<script setup lang="ts">
    import { getSuppliersOrders } from '@/api/suppliersOrders';
    import { ref, watch, onMounted } from 'vue';
    import { useRouter, useRoute } from 'vue-router';
    import { useLoader } from '@/composables/useLoader';
    import _ from 'lodash';
    import { useAlert } from '@/composables/useAlert';
    import ConfirmDialog from '@/components/ConfirmDialog.vue';

    const { showLoader, hideLoader } = useLoader();
    const $router = useRouter();
    const $route = useRoute();
    const $alert = useAlert();

    const initialQuery = ref(true);
    const done = ref(false);

    const confirm = ref<InstanceType<typeof ConfirmDialog> | null>(null);

    const searchTimeoutId = ref<NodeJS.Timeout>();
    const search = ref('');

    const page = ref(parseInt($route.query.page as string) || 1);
    const pageSize = ref(parseInt($route.query.pageSize as string) || 10);

    const suppliersOrders = ref([]) as any;
    const filteredSuppliersOrders = ref([]) as any;
    const numDocs = ref([]) as any;
    const closeds = ref([
        { text: 'Sim', value: true },
        { text: 'Não', value: false },
    ]) as any;

    const headers = ref([
        {
            title: 'Código',
            key: 'Codigo',
        },
        {
            title: 'Data Doc.',
            key: 'DataDoc',
        },
        {
            title: 'Data Entrega',
            key: 'DataEntrega',
        },
        {
            title: 'Dias Atraso Entrega',
            key: 'DiasDeAtraso',
        },
        {
            title: 'Fechado',
            key: 'Fechado',
        },
        {
            title: 'Tipo Doc.',
            key: 'TipoDoc',
        },
        {
            title: 'N.º Doc.',
            key: 'NumDoc',
        },
        {
            title: 'Série',
            key: 'Serie',
        },
        {
            title: 'Entidade',
            key: 'Entidade',
        },
        {
            title: 'Nome',
            key: 'Nome',
        },
        {
            title: 'Artigo',
            key: 'Artigo',
        },
        {
            title: 'Descrição',
            key: 'Descricao',
        },
        {
            title: 'Qtd. Encomendada',
            key: 'Quantidade',
        },
        {
            title: 'Qtd. Satisfeita',
            key: 'QuantTrans',
        },
        {
            title: 'Qtd. Pendente',
            key: 'QuantidadePendente',
        },
    ]);

    const isNumDocFilterOpened = ref(false);
    const isClosedFilterOpened = ref(false);

    // Declare Filters Variables
    //
    // NumDoc
    const selectedNumDocs = ref([]) as any;
    const allNumDocsSelected = ref(false);
    const searchedNumDocs = ref([]) as any;
    const searchNumDoc = ref('');
    // Closed
    const selectedCloseds = ref([]) as any;
    const allClosedsSelected = ref(false);
    const searchedCloseds = ref(closeds.value) as any;
    const searchClosed = ref('');

    // here we are initializing the query params, in case there are no query params
    async function initQueryParams() {
        if (Object.keys($route.query).length === 0) $router.push({ query: { page: page.value, pageSize: pageSize.value } });
    }

    initQueryParams();

    async function onRowClick(row: any, o: any) {
        $router.push({ name: 'SuppliersOrder', params: { id: o.item.CabecId } });
    }

    // On Search Functions
    function onSearch(str: string) {
        clearTimeout(searchTimeoutId.value);
        searchTimeoutId.value = setTimeout(() => {
            search.value = str ?? '';
            setRouterQuery();
        }, 250);
    }

    function onSearchNumDoc(str: string) {
        if (allNumDocsSelected.value && searchedNumDocs.value.length !== numDocs.value.length) allNumDocsSelected.value = false;
        else if (!allNumDocsSelected.value && searchedNumDocs.value.length == numDocs.value.length) allNumDocsSelected.value = true;

        searchNumDoc.value = str ?? '';
        searchedNumDocs.value = numDocs.value.filter((item: any) => {
            if (item == null) return false;
            else return item.toString().includes(searchNumDoc.value);
        });
    }

    function onSearchClosed(str: string) {
        if (allClosedsSelected.value && searchedCloseds.value.length !== selectedCloseds.value.length) allClosedsSelected.value = false;
        else if (!allClosedsSelected.value && searchedCloseds.value.length == selectedCloseds.value.length) allClosedsSelected.value = true;

        searchClosed.value = str ?? '';
        searchedCloseds.value = closeds.value.filter((item: any) => {
            if (item.text == null) return false;
            else return item.text.toLowerCase().includes(searchClosed.value.toLowerCase());
        });
    }

    // Select All Num Docs Function
    async function selectAllNumDocs() {
        if (selectedNumDocs.value.length == searchedNumDocs.value.length) (selectedNumDocs.value = []), (allNumDocsSelected.value = false);
        else (selectedNumDocs.value = searchedNumDocs.value), (allNumDocsSelected.value = true);
    }

    // Select All Closeds Function
    async function selectAllCloseds() {
        if (selectedCloseds.value.length == searchedCloseds.value.length) (selectedCloseds.value = []), (allClosedsSelected.value = false);
        else (selectedCloseds.value = searchedCloseds.value), (allClosedsSelected.value = true);
    }

    function setRouterQuery() {
        const query = {
            ..._.cloneDeep($route.query),
            pageSize: pageSize.value,
            page: page.value,
            search: undefined as string | undefined,
            numDocs: undefined as string | undefined,
            closeds: undefined as string | undefined,
        };
        if (pageSize.value) query.pageSize = pageSize.value;
        if (page.value) query.page = page.value;
        if (search.value) query.search = search.value;
        if (selectedNumDocs.value.length > 0) query.numDocs = selectedNumDocs.value.join(',');
        if (selectedCloseds.value.length > 0) query.closeds = selectedCloseds.value.map((item: any) => item.value).join(',');

        $router.push({ query });
    }

    // Function to Generate Unique ID for Suppliers Orders
    // We Need This To Use In The Item-Value Property Of The V-Data-Table
    // Otherwise, The Sort Functionality Will Break
    const generateUniqueID = () => {
        return `${Date.now().toString(36)}-${Math.random().toString(36).slice(2, 11)}`;
    };

    async function init() {
        showLoader();
        try {
            await getSuppliersOrders()
                .then((response) => {
                    suppliersOrders.value = response.data;

                    suppliersOrders.value = suppliersOrders.value.map((item: any) => ({
                        ...item,
                        ID: generateUniqueID(),
                    }));

                    filteredSuppliersOrders.value = suppliersOrders.value;

                    numDocs.value = [...new Set(suppliersOrders.value.map((item: any) => item.NumDoc))].sort((a: any, b: any) => a - b);
                    searchedNumDocs.value = numDocs.value;

                    if (search.value || selectedNumDocs.value.length > 0 || selectedCloseds.value.length > 0) {
                        handleFilters();
                    }
                    setRouterQuery();
                })
                .finally(async () => {
                    initialQuery.value = false;
                    done.value = true;
                    hideLoader();
                });
        } catch (error) {
            console.error(error);
            $alert.showAlert({
                type: 'error',
                text: 'Erro ao carregar os dados. Por favor, tente novamente.',
            });
        }
    }

    init();

    function handleFilters() {
        //filters the suppliers orders based on the selected filters and search
        filteredSuppliersOrders.value = suppliersOrders.value.filter((item: any) => {
            const matchesSearch = search.value
                ? (item.Codigo && item.Codigo.toLowerCase().includes(search.value.toLowerCase())) ||
                  (item.Nome && item.Nome.toLowerCase().includes(search.value.toLowerCase())) ||
                  (item.Artigo && item.Artigo.toLowerCase().includes(search.value.toLowerCase()))
                : true;

            const matchesNumDocs = selectedNumDocs.value.length > 0 ? selectedNumDocs.value.includes(item.NumDoc) : true;

            const matchesClosedStatus = selectedCloseds.value.length > 0 ? selectedCloseds.value.some((selectedItem: any) => selectedItem.value === item.Fechado) : true;

            return matchesSearch && matchesNumDocs && matchesClosedStatus;
        });
    }

    // watch when query changes
    watch(
        () => $route.query,
        () => {
            pageSize.value = parseInt($route.query.pageSize as string) || 10;
            page.value = parseInt($route.query.page as string) || 1;
            search.value = ($route.query.search as string) ?? '';
            selectedNumDocs.value = ($route.query.numDocs as string)?.split(',').map((item) => parseInt(item)) ?? [];
            selectedCloseds.value = ($route.query.closeds as string)?.split(',').map((item) => ({ text: item === 'true' ? 'Sim' : 'Não', value: item === 'true' })) ?? [];

            if (initialQuery.value == false) init();
        },
        {
            immediate: true,
        },
    );

    watch(selectedNumDocs, async () => {
        if (selectedNumDocs.value.length !== searchedNumDocs.value.length) allNumDocsSelected.value = false;
        else allNumDocsSelected.value = true;
        handleFilters();
        setRouterQuery();
    });

    watch(selectedCloseds, async () => {
        if (selectedCloseds.value.length !== searchedCloseds.value.length) allClosedsSelected.value = false;
        else allClosedsSelected.value = true;
        handleFilters();
        setRouterQuery();
    });

    watch([page, pageSize], () => {
        setRouterQuery();
    });

    // Format date to 'dd/mm/yyyy'
    const formatDate = (date: string) => {
        return date == null ? '' : new Date(date).toLocaleDateString('pt-PT');
    };
</script>

<style scoped></style>
