<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botões -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="12"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-ml-14 tw-text-2xl tw-font-bold lg:tw-ml-14">{{ $t('dailyWork.createDailyWorks') }}</h1>
            </v-col>
        </v-row>
        <v-form
            v-if="done"
            ref="form"
            @submit.prevent="onSubmit">
            <v-row class="tw-mt-5">
                <v-data-table
                    :headers="headers"
                    :items="data.registers">
                    <template v-slot:top>
                        <!-- Criar Novo Registo (abre dialog para criar um novo registo) -->
                        <v-toolbar
                            flat
                            class="tw-bg-white">
                            <v-dialog
                                v-model="dialog"
                                max-width="80%">
                                <template v-slot:activator="{ props }">
                                    <v-btn
                                        color="white"
                                        class="tw-bg-secondary"
                                        prepend-icon="mdi-plus"
                                        v-bind="props"
                                        >Novo Registo</v-btn
                                    >
                                </template>
                                <v-card>
                                    <v-card-title>
                                        <v-container>
                                            <div class="tw-flex tw-items-center tw-gap-x-2">
                                                <div class="tw-flex tw-items-center tw-gap-x-4">
                                                    <v-icon class="tw-rounded-md tw-bg-primary tw-p-5 tw-text-white">{{ formTitle == 'Novo Registo' ? 'mdi-plus' : 'mdi-pencil' }}</v-icon>
                                                    <span class="text-h5 tw-font-bold">{{ formTitle }}</span>
                                                </div>
                                            </div>
                                        </v-container>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-form
                                            ref="form2"
                                            @submit.prevent="save">
                                            <v-container>
                                                <v-row>
                                                    <v-col
                                                        cols="12"
                                                        md="4"
                                                        sm="6">
                                                        <v-autocomplete
                                                            :items="productionOrders"
                                                            @update:modelValue="
                                                                async (value: string) => {
                                                                    if (!editedItem.productionOrder || editedItem.productionOrder !== value) {
                                                                        editedItem.operation = '';
                                                                        editedItem.stock = false;
                                                                        editedItem.consumption = false;
                                                                        editedItem.order = '';
                                                                    }
                                                                    const filterByIds = {
                                                                        Artigo: [productionOrders.find((po) => po.IDOrdemFabrico === value).Artigo],
                                                                    };
                                                                    let response = await getArticles(null, null, null, filterByIds);
                                                                    articles.push(...response.data.data);
                                                                }
                                                            "
                                                            v-model="editedItem.productionOrder"
                                                            :placeholder="$t('dailyWork.fields.productionOrder')"
                                                            item-title="OrdemFabrico"
                                                            :rules="[rules.required]"
                                                            item-value="IDOrdemFabrico"
                                                            label="Ordem de Fabrico">
                                                        </v-autocomplete>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        md="4"
                                                        sm="6">
                                                        <v-autocomplete
                                                            v-model="editedItem.operation"
                                                            @update:modelValue="isTheLastOperation"
                                                            :placeholder="$t('dailyWork.fields.operation')"
                                                            item-title="OperacaoProducao"
                                                            :rules="[rules.required]"
                                                            item-value="IDOrdemFabricoOperacao"
                                                            :items="getOperations(editedItem.productionOrder)"
                                                            label="Operação">
                                                        </v-autocomplete>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        md="4"
                                                        sm="6">
                                                        <v-autocomplete
                                                            v-model="editedItem.worker"
                                                            placeholder="Operador"
                                                            item-title="Nome"
                                                            :rules="[rules.required]"
                                                            item-value="IDOperador"
                                                            :items="workers"
                                                            label="Operador">
                                                        </v-autocomplete>

                                                        <!-- <v-text-field
                                                        v-model="editedItem.worker"
                                                        label="Operador"></v-text-field> -->
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        md="4"
                                                        sm="6">
                                                        <v-autocomplete
                                                            v-model="editedItem.hourType"
                                                            placeholder="Tipo Hora"
                                                            item-title="Descricao"
                                                            :rules="[rules.required]"
                                                            item-value="IDTipoHora"
                                                            :items="hoursTypes"
                                                            label="Tipo Hora">
                                                        </v-autocomplete>
                                                        <!--
                                                    <v-text-field
                                                        v-model="editedItem.hourType"
                                                        label="Tipo Hora"></v-text-field> -->
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        class="!tw-p-0">
                                                    </v-col>
                                                    <!-- Start and End (Fake) -->
                                                    <v-col
                                                        cols="12"
                                                        md="4"
                                                        sm="6">
                                                        <v-text-field
                                                            type="datetime-local"
                                                            :rules="[rules.required]"
                                                            v-model="editedItem.start"
                                                            label="Inicio"></v-text-field>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        md="4"
                                                        sm="6">
                                                        <v-text-field
                                                            type="datetime-local"
                                                            :rules="[rules.required, rules.afterDate(editedItem.end, editedItem.start)]"
                                                            v-model="editedItem.end"
                                                            label="Fim"></v-text-field>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        class="!tw-p-0">
                                                    </v-col>
                                                    <!-- Start and End (Real) -->
                                                    <v-col
                                                        cols="12"
                                                        md="4"
                                                        sm="6">
                                                        <v-text-field
                                                            type="datetime-local"
                                                            v-model="editedItem.realStart"
                                                            :rules="[rules.required]"
                                                            label="Inicio Real"></v-text-field>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        md="4"
                                                        sm="6">
                                                        <v-text-field
                                                            type="datetime-local"
                                                            :rules="[rules.required, rules.afterDate(editedItem.realEnd, editedItem.realStart)]"
                                                            v-model="editedItem.realEnd"
                                                            label="Fim Real"></v-text-field>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        xl="6"
                                                        class="tw-flex tw-flex-col tw-justify-between">
                                                        <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                                                            {{ 'Operação terminada' + '?' }}
                                                        </v-label>
                                                        <div>
                                                            <v-btn-toggle
                                                                mandatory
                                                                v-model="editedItem.operationFinished"
                                                                :rules="[rules.required]"
                                                                color="primary"
                                                                border
                                                                class="tw-mt-auto">
                                                                <v-btn :value="true">
                                                                    {{ $t('shared.yes') }}
                                                                </v-btn>
                                                                <v-btn :value="false">
                                                                    {{ $t('shared.no') }}
                                                                </v-btn>
                                                            </v-btn-toggle>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-divider class="my-2"></v-divider>
                                                        <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg"> Quantidades </v-label>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        md="4"
                                                        sm="6">
                                                        <v-text-field
                                                            type="number"
                                                            :rules="[rules.required, rules.nonNegative]"
                                                            v-model="editedItem.quantityConform"
                                                            label="Quantidade Conforme"></v-text-field>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        md="4"
                                                        sm="6">
                                                        <v-text-field
                                                            type="number"
                                                            :rules="[rules.required, rules.nonNegative]"
                                                            v-model="editedItem.quantityRejected"
                                                            label="Quantidade Rejeitada"></v-text-field>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        md="8"
                                                        sm="12">
                                                        <div class="tw-flex tw-gap-x-2">
                                                            <div class="tw-flex tw-items-center">
                                                                <v-label class="tw-text-dark tw-mb-1 tw-mt-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg"> Entrada Stock: </v-label>
                                                                <v-checkbox-btn
                                                                    color="primary"
                                                                    v-model="editedItem.stock"
                                                                    @update:modelValue="
                                                                        () => {
                                                                            if (editedItem.stock) {
                                                                                if (editedItem.productionOrder)
                                                                                    editedItem.order = productionOrders.find((po) => po.IDOrdemFabrico === editedItem.productionOrder).OrdemFabrico;
                                                                            } else {
                                                                                editedItem.order = '';
                                                                            }
                                                                        }
                                                                    "></v-checkbox-btn>
                                                            </div>
                                                            <v-text-field
                                                                :disabled="!editedItem.stock"
                                                                label="Lote"
                                                                v-model="editedItem.order"
                                                                hide-details></v-text-field>
                                                        </div>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        md="8"
                                                        sm="12">
                                                        <div class="tw-flex tw-items-center">
                                                            <v-label class="tw-text-dark tw-mb-1 tw-mt-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                                                                {{ $t('dailyWork.fields.componentsConsumption') }}:
                                                            </v-label>
                                                            <v-checkbox-btn
                                                                color="primary"
                                                                v-model="editedItem.consumption"></v-checkbox-btn>
                                                        </div>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        v-if="editedItem.stock || editedItem.consumption">
                                                        <v-divider class="tw-py-1"></v-divider>
                                                        <div class="tw-mb-2 tw-flex tw-justify-between tw-py-2 tw-text-lg tw-font-bold">
                                                            <h2>Editor de Stock:</h2>
                                                            <v-icon
                                                                class="tw-rounded-md tw-bg-primary tw-p-4 tw-text-xl tw-text-white"
                                                                @click.prevent="findStockLines()"
                                                                >mdi-refresh</v-icon
                                                            >
                                                        </div>
                                                        <div class="hot-table-container tw-w-full tw-overflow-x-auto">
                                                            <hot-table
                                                                :data="editedItem.lines"
                                                                :licenseKey="license"
                                                                :contextMenu="true"
                                                                :columns="columns"
                                                                :afterChange="afterChange"
                                                                :afterLoadData="afterLoadData"
                                                                :beforeKeyDown="handleBeforeKeyDown"
                                                                :rowHeaders="false"
                                                                :stretchH="'all'"
                                                                :width="'100%'"
                                                                :colHeaders="tableHeaders"
                                                                ref="hotTableComponent"></hot-table>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-form>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="secondary"
                                            variant="text"
                                            @click="close">
                                            Cancelar
                                        </v-btn>
                                        <v-btn
                                            color="primary"
                                            variant="text"
                                            @click="save">
                                            Guardar
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <ConfirmDialog ref="confirm" />
                        </v-toolbar>
                    </template>
                    <template v-slot:item.productionOrder="{ item }">
                        {{ getProductionOrder(item.productionOrder) }}
                    </template>
                    <template v-slot:item.operation="{ item }">
                        {{ getOperationDescription(item.productionOrder, item.operation) }}
                    </template>
                    <template v-slot:item.operationFinished="{ item }">
                        <v-chip
                            size="default"
                            :color="item.operationFinished ? 'success' : 'error'"
                            dark>
                            {{ item.operationFinished ? 'Sim' : 'Não' }}
                        </v-chip>
                    </template>
                    <template v-slot:item.worker="{ item }">
                        {{ getWorkerName(item.worker) }}
                    </template>
                    <template v-slot:item.hourType="{ item }">
                        {{ getHourTypeDescription(item.hourType) }}
                    </template>
                    <template v-slot:item.start="{ item }">
                        {{ formatDate(item.start) }}
                    </template>
                    <template v-slot:item.end="{ item }">
                        {{ formatDate(item.end) }}
                    </template>
                    <template v-slot:item.realStart="{ item }">
                        {{ formatDate(item.realStart) }}
                    </template>
                    <template v-slot:item.realEnd="{ item }">
                        {{ formatDate(item.realEnd) }}
                    </template>
                    <template v-slot:item.stock="{ item }">
                        <v-icon :color="item.stock ? 'success' : 'error'">
                            {{ item.stock ? 'mdi-check' : 'mdi-close' }}
                        </v-icon>
                    </template>
                    <template v-slot:item.consumption="{ item }">
                        <v-icon :color="item.consumption ? 'success' : 'error'">
                            {{ item.consumption ? 'mdi-check' : 'mdi-close' }}
                        </v-icon>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-icon
                            class="me-2"
                            size="small"
                            @click="editItem(item)">
                            mdi-pencil
                        </v-icon>
                        <v-icon
                            size="small"
                            @click="deleteItem(item)">
                            mdi-delete
                        </v-icon>
                    </template>
                </v-data-table>
            </v-row>
            <v-row class="tw-mt-3">
                <v-col
                    cols="12"
                    class="tw-flex tw-justify-end">
                    <v-btn
                        color="primary"
                        @click="onSubmit">
                        {{ $t('shared.save') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
    <ConfirmDialog ref="confirm" />
</template>

<script setup lang="ts">
    import { VForm } from 'vuetify/lib/components/index.mjs';
    import { ref, computed, watch, nextTick } from 'vue';
    import { getArticles } from '@/api/articles';
    import { getWarehouses } from '@/api/warehouses';
    import { getWarehouseLocations } from '@/api/warehouseLocations';
    import { getProductionOrders, getStockLines } from '@/api/productionOrders';
    import { getWorkers } from '@/api/workers';
    import { getHoursTypes } from '@/api/hoursTypes';
    import { createDailyWorks } from '@/api/dailyWorks';
    import { useLoader } from '@/composables/useLoader';
    import { useAlert } from '@/composables/useAlert';
    import useRules from '@/composables/rules';
    import ConfirmDialog from '@/components/ConfirmDialog.vue';
    import moment from 'moment';
    // imports to use handsontable
    import 'handsontable/dist/handsontable.full.min.css';
    import 'handsontable/dist/handsontable.full.css';
    import { HotTable } from '@handsontable/vue3';
    import { registerAllModules } from 'handsontable/registry';

    registerAllModules();

    const rules = useRules();
    const $alert = useAlert();
    const { showLoader, hideLoader } = useLoader();

    const form = ref<VForm>();
    const form2 = ref<VForm>();
    const done = ref(false);
    const confirm = ref<InstanceType<typeof ConfirmDialog> | null>(null);

    const data = ref({
        registers: [],
    });

    const productionOrders = ref([] as any[]);
    const workers = ref([] as any[]);
    const hoursTypes = ref([] as any[]);

    // This 4 variables are used for "Saída de Stock"
    const docTypes = ref([{ TipoDoc: 'EOF' }, { TipoDoc: 'SOF' }]) as any[];
    const articles = ref([] as any[]);
    const warehouses = ref([] as any[]);
    const warehouseLocations = ref([] as any[]);

    const onSubmit = async () => {
        if (!form.value) return;
        const { valid } = await form.value.validate();
        if (!valid) return;

        // check if there are registers
        if (data.value.registers.length === 0) {
            $alert.showAlert({
                type: 'warning',
                text: 'Deve adicionar pelo menos um registo.',
            });
            return;
        }

        console.log('Data', data.value);

        data.value.registers.forEach((register: any) => {
            // start and end dates will always be todays date
            register['start'] = moment().format('YYYY-MM-DDTHH:mm');
            register['end'] = moment().add(1, 'minutes').format('YYYY-MM-DDTHH:mm');

            register['realDuration'] = moment(register.realEnd).diff(moment(register.realStart), 'minutes');

            const productionOrder = productionOrders.value.find((po) => po.IDOrdemFabrico === register.productionOrder);

            register['article'] = productionOrder?.Artigo;

            if (productionOrder?.operations) {
                const operation = productionOrder.operations.find((op: any) => op.IDOrdemFabricoOperacao === register.operation);
                // Assign workCenter only if the operation exists
                if (operation) register['workCenter'] = operation.IDCentroTrabalho;
            }

            // For each line, remove the empty line (the one which article is empty)
            register.lines = register.lines.filter((doc: any) => doc.article !== '' && doc.article !== null);
        });
        try {
            const result = await confirm.value.open('Confirmação', `Tem a certeza que deseja guardar os Diários de Trabalho?`, 'Sim', 'Não');
            if (!result) return;

            showLoader();
            await createDailyWorks(data.value).then(() => {
                $alert.showAlert({
                    type: 'success',
                    text: 'Registo(s) criado(s) com sucesso.',
                });
            });
            //reset form
            data.value.registers = [];
            hideLoader();
        } catch (error: any) {
            console.error('error', error);
            $alert.showAlert({
                type: 'error',
                text: error?.response?.data?.error?.message || 'Erro ao criar registo(s). Por favor, tente novamente.',
            });
            hideLoader();
        }
    };

    const init = async () => {
        console.log('init');
        showLoader();
        try {
            const [productionOrdersresponse, workersResponse, hoursTypesResponse, warehousesResponse, warehouseLocationsResponse] = await Promise.all([
                getProductionOrders(null, null, null),
                getWorkers(),
                getHoursTypes(),
                getWarehouses(),
                getWarehouseLocations(),
            ]);

            productionOrders.value = productionOrdersresponse.data?.data;
            workers.value = workersResponse.data;
            hoursTypes.value = hoursTypesResponse.data;
            warehouses.value = warehousesResponse.data;
            warehouseLocations.value = warehouseLocationsResponse.data;

            done.value = true;
        } catch (error) {
            console.error('error', error);
            console.error(error);
            $alert.showAlert({
                type: 'error',
                text: 'Erro ao carregar dados. Por favor, tente novamente.',
            });
        } finally {
            hideLoader();
        }
    };

    init();

    const searchArticles = async (article: string) => {
        try {
            const response = await getArticles(null, null, article, null);
            articles.value.push(...response.data?.data.filter((a: any) => !articles.value.some((b: any) => b.Artigo === a.Artigo)));
            console.log('Articles', articles.value);
        } catch (error) {
            console.error('Error fetching articles:', error);
        }
    };

    const getProductionOrder = (productionOrderId: string) => {
        const productionOrder = productionOrders.value.find((po: any) => po.IDOrdemFabrico === productionOrderId);
        return productionOrder ? productionOrder.OrdemFabrico : '';
    };

    const getOperations = (productionOrderId: string) => {
        const productionOrder = productionOrders.value.find((po: any) => po.IDOrdemFabrico === productionOrderId);
        return productionOrder ? productionOrder.operations : [];
    };

    const getOperationDescription = (productionOrderId: string, operationId: string) => {
        const productionOrder = productionOrders.value.find((po: any) => po.IDOrdemFabrico === productionOrderId);
        const operation = productionOrder?.operations.find((op: any) => op.IDOrdemFabricoOperacao === operationId);
        return operation ? operation.Descricao : '';
    };

    const getWorkerName = (workerId: string) => {
        const worker = workers.value.find((w) => w.IDOperador === workerId);
        return worker ? worker.Nome : '';
    };

    const getHourTypeDescription = (hourTypeId: string) => {
        const hourType = hoursTypes.value.find((ht) => ht.IDTipoHora === hourTypeId);
        return hourType ? hourType.Descricao : '';
    };

    // Format date to 'dd/mm/yyyy'
    const formatDate = (date: string) => {
        return date == null ? '' : new Date(date).toLocaleDateString('pt-PT');
    };

    /**
     *
     *
     * INFO TO DATATABLE
     *
     *
     */

    const dialog = ref(false);
    const headers = ref([
        {
            title: 'Ordem de Fabrico',
            key: 'productionOrder',
            sortable: false,
        },
        {
            title: 'Operação',
            key: 'operation',
            sortable: false,
        },
        {
            title: 'Operador',
            key: 'worker',
            sortable: false,
        },
        {
            title: 'Operação Terminada',
            key: 'operationFinished',
            sortable: false,
        },
        {
            title: 'Tipo Hora',
            key: 'hourType',
            sortable: false,
        },
        {
            title: 'Inicio',
            key: 'start',
            sortable: false,
        },
        {
            title: 'Fim',
            key: 'end',
            sortable: false,
        },
        {
            title: 'Inicio Real',
            key: 'realStart',
            sortable: false,
        },
        {
            title: 'Fim Real',
            key: 'realEnd',
            sortable: false,
        },
        {
            title: 'Quantidade Conforme',
            key: 'quantityConform',
            sortable: false,
        },
        {
            title: 'Quantidade Rejeitada',
            key: 'quantityRejected',
            sortable: false,
        },
        {
            title: 'Entrada Stock',
            key: 'stock',
            sortable: false,
        },
        {
            title: 'Lote',
            key: 'order',
            sortable: false,
        },
        {
            title: 'Consumo de Componentes',
            key: 'consumption',
            sortable: false,
        },

        { title: 'Ações', key: 'actions', sortable: false },
    ]);

    const editedIndex = ref(-1);

    const editedItem = ref({
        productionOrder: '',
        operation: '',
        worker: '',
        operationFinished: true,
        hourType: '',
        start: moment().format('YYYY-MM-DDTHH:mm'),
        end: moment().add(1, 'minutes').format('YYYY-MM-DDTHH:mm'),
        realStart: '',
        realEnd: '',
        quantityConform: '',
        quantityRejected: '',
        stock: false,
        order: '',
        consumption: false,
        lines: [
            {
                docType: '',
                article: '',
                description: '',
                warehouse: 'A1',
                warehouseLocation: 'A1',
                order: '',
                unit: '',
                quantity: '',
            },
        ],
    });

    const defaultItem = ref({
        productionOrder: '',
        operation: '',
        worker: '',
        operationFinished: true,
        hourType: '',
        start: moment().format('YYYY-MM-DDTHH:mm'),
        end: moment().add(1, 'minutes').format('YYYY-MM-DDTHH:mm'),
        realStart: '',
        realEnd: '',
        quantityConform: '',
        quantityRejected: '',
        stock: false,
        order: '',
        consumption: false,
        lines: [
            {
                docType: '',
                article: '',
                description: '',
                warehouse: 'A1',
                warehouseLocation: 'A1',
                order: '',
                unit: '',
                quantity: '',
            },
        ],
    });

    const formTitle = computed(() => (editedIndex.value === -1 ? 'Novo Registo' : 'Editar Registo'));

    // Watchers
    watch(dialog, (value) => {
        if (!value) {
            close();
        }
    });

    watch([() => editedItem.value.stock, () => editedItem.value.consumption], async ([stock, consumption]) => {
        if (!stock && !consumption) {
            editedItem.value.lines = [
                {
                    docType: '',
                    article: '',
                    description: '',
                    warehouse: 'A1',
                    warehouseLocation: 'A1',
                    order: '',
                    unit: '',
                    quantity: '',
                },
            ];
        }
    });

     // Watchers
     watch(
        () => editedItem.value.lines,
        (newData) => {
            const hotInstance = hotTableComponent?.value?.hotInstance;
            if (hotInstance) {
                hotInstance.loadData(newData);
            }
        },
        { deep: true },
    );

    // Functions
    function isTheLastOperation(value: string) {
        const productionOrder = productionOrders.value.find((po) => po.IDOrdemFabrico === editedItem.value.productionOrder);
        const operation = productionOrder?.operations.find((op) => op.IDOrdemFabricoOperacao === value);
        if (operation) {
            const isLastOperation = operation.IDOrdemFabricoOperacao === productionOrder.operations[productionOrder.operations.length - 1].IDOrdemFabricoOperacao;

            if (isLastOperation) {
                editedItem.value.stock = true;
                editedItem.value.order = productionOrder.OrdemFabrico;
            } else {
                editedItem.value.stock = false;
                editedItem.value.order = '';
            }
        }
    }

    function editItem(item: any) {
        editedIndex.value = data.value.registers.indexOf(item);
        editedItem.value = Object.assign({}, item);
        dialog.value = true;
    }

    async function deleteItem(item: any) {
        editedIndex.value = data.value.registers.indexOf(item);
        editedItem.value = Object.assign({}, item);

        if (confirm.value) {
            const result = await confirm.value.open('Confirmação', 'Tem a certeza que pretende apagar este registo?', 'Sim', 'Não');

            if (result) {
                deleteItemConfirm();
            }
        }
    }

    function deleteItemConfirm() {
        data.value.registers.splice(editedIndex.value, 1);
        nextTick(() => {
            editedItem.value = Object.assign({}, defaultItem.value);
            editedIndex.value = -1;
        });
    }

    function close() {
        dialog.value = false;
        nextTick(() => {
            editedItem.value = Object.assign({}, defaultItem.value);
            editedIndex.value = -1;
        });
    }

    async function save() {
        if (!form2.value) return;
        const { valid } = await form2.value.validate();
        if (!valid) return;
        if (editedIndex.value > -1) {
            Object.assign(data.value.registers[editedIndex.value], editedItem.value);
        } else {
            data.value.registers.push(editedItem.value);
        }
        console.log('Data Value Registers', data.value);
        close();
    }

    /**
     *
     *
     * INFO TO HANDSONTABLE
     *
     *
     */

    const license = ref(import.meta.env.VITE_APP_HANDSONTABLE_LICENSE_KEY);
    const hotTableComponent = ref(null);

    const tableHeaders = ref(['Tipo Doc.', 'Artigo', 'Descrição', 'Armazém', 'Localização', 'Lote', 'Unidade', 'Quantidades']);

    const columns = computed(() => {
        return [
            {
                data: 'docType',
                type: 'dropdown',
                source: createDropdownSource(docTypes, 'TipoDoc'),
            },
            {
                data: 'article',
                type: 'dropdown',
                source: createDropdownSource(articles, 'Artigo'),
            },
            {
                data: 'description',
                type: 'text',
            },
            {
                data: 'warehouse',
                type: 'dropdown',
                source: createDropdownSource(warehouses, 'Armazem'),
            },
            {
                data: 'warehouseLocation',
                type: 'dropdown',
                source: createDropdownSource(warehouseLocations, 'Localizacao'),
            },
            {
                data: 'order',
                type: 'text',
            },
            {
                data: 'unit',
                type: 'text',
            },
            {
                data: 'quantity',
                type: 'numeric',
            },
        ];
    });

    const createDropdownSource = (array: any, key: any) => (done.value ? array.value.map((item) => item[key]) : []);

    const validateAllCells = () => {
        if (hotTableComponent.value && hotTableComponent.value.hotInstance) {
            hotTableComponent.value.hotInstance.validateCells();
        }
    };

    const handleBeforeKeyDown = async (event) => {
        const hotInstance = hotTableComponent.value.hotInstance;
        const activeEditor = hotInstance.getActiveEditor();
        const selectedRange = hotInstance.getSelectedRange();
        const activeColumn = selectedRange?.[0]?.from?.col;

        const isPrintableCharacter = event.key.length === 1 && event.key.match(/^[\w\d\s\p{L}\p{N}]$/u);

        if (activeColumn === 1 && isPrintableCharacter) {
            const currentValue = activeEditor?.TEXTAREA?.value || '';
            const newValue = currentValue + event.key;

            if (newValue.length >= 3) {
                // Fetch updated articles based on newValue (user input)
                showLoader();
                await searchArticles(newValue);
                hideLoader();

                // Update the dropdown source dynamically
                const dropdownEditor = hotInstance.getActiveEditor();
                dropdownEditor.cellProperties.source = articles.value.map((article) => article.Artigo);
                setTimeout(() => dropdownEditor.open(), 0);
            }
        }
    };

    const afterChange = (changes: any, source: any) => {
        if (source === 'edit' || source === 'CopyPaste.paste') {
            changes.forEach(async ([row, prop, oldValue, newValue]) => {
                if (prop === 'article' && oldValue !== newValue) {
                    const article = articles.value.find((a) => a.Artigo === newValue);
                    if (article) {
                        hotTableComponent.value.hotInstance.setDataAtCell(row, 2, article.Descricao);
                        hotTableComponent.value.hotInstance.setDataAtCell(row, 5, editedItem.value.order);
                        hotTableComponent.value.hotInstance.setDataAtCell(row, 6, article.UnidadeBase);
                    }
                }
                if (prop === 'warehouse' && oldValue !== newValue) {
                    const warehouse = warehouses.value.find((w) => w.Armazem === newValue);
                    if (warehouse) {
                        const warehouseLocation = warehouseLocations.value.find((wl) => wl.Armazem === warehouse.Armazem);
                        if (warehouseLocation) {
                            hotTableComponent.value.hotInstance.setDataAtCell(row, 4, warehouseLocation.Localizacao);
                        }
                    }
                }
            });
        }

        // Insert a new row when the last row is edited and make sure there is at least one column with data
        // So the user can continue to add more rows
        if ((source === 'edit' || source === 'CopyPaste.paste') && changes[0][0] === hotTableComponent.value.hotInstance.countRows() - 1) {
            //check if there is at least one column with data
            const hasData = hotTableComponent.value.hotInstance.getDataAtRow(changes[0][0]).some((cell: any) => cell !== null && cell !== '');
            if (hasData) {
                hotTableComponent.value.hotInstance.alter('insert_row_below', hotTableComponent.value.hotInstance.countRows());
            }
        }
        validateAllCells();
    };

    const afterLoadData = (sourceData, initialLoad, source) => {
        validateAllCells();
    };

    const findStockLines = async () => {
        showLoader();

        const productionOrder = productionOrders.value.find((po) => po.IDOrdemFabrico === editedItem.value.productionOrder);
        const operation = productionOrder?.operations.find((op) => op.IDOrdemFabricoOperacao === editedItem.value.operation);
        const quantity = parseFloat(editedItem.value.quantityConform) + parseFloat(editedItem.value.quantityRejected);

        const eofLines = [];
        const sofLines = [];
        if (editedItem.value.stock) {
            try {
                const response = await getStockLines('EOF', productionOrder.IDOrdemFabrico, operation.Operacao, quantity);
                eofLines.push(...response?.data);
            } catch (error) {
                console.error('Error fetching EOF stock lines:', error);
            }
        }
        if (editedItem.value.consumption) {
            try {
                const response = await getStockLines('SOF', productionOrder.IDOrdemFabrico, operation.Operacao, quantity);
                sofLines.push(...response?.data);
            } catch (error) {
                console.error('Error fetching SOF stock lines:', error);
            }
        }

        editedItem.value.lines = [
            ...eofLines.map((line: any) => ({
                docType: 'EOF',
                article: line.Artigo,
                description: line.Descricao,
                warehouse: line.Armazem ? line.Armazem : 'A1',
                warehouseLocation: line.Localizacao ? line.Localizacao : 'A1',
                order: line.Lote ? line.Lote : editedItem.value.order,
                unit: line.Unidade ? line.Unidade : '',
                quantity: line.Quantidade,
            })),
            ...sofLines.map((line: any) => ({
                docType: 'SOF',
                article: line.Artigo,
                description: line.Descricao,
                warehouse: line.Armazem ? line.Armazem : 'A1',
                warehouseLocation: line.Localizacao ? line.Localizacao : 'A1',
                order: line.Lote ? line.Lote : '<L01>',
                unit: line.Unidade ? line.Unidade : '',
                quantity: line.Quantidade,
            })),
            {
                docType: '',
                article: '',
                description: '',
                warehouse: '',
                warehouseLocation: '',
                order: '',
                unit: '',
                quantity: '',
            },
        ];

        // Search Articles so they dont appear with red label
        editedItem.value.lines.forEach((line: any) => {
            if (line.article) {
                searchArticles(line.article);
            }
        });

        console.log('editedItem', editedItem.value.lines);
        hideLoader();
    };
</script>

<style scoped>
    .hot-table-container {
        height: 150px;
    }
</style>
