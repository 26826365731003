import { AxiosPromise } from 'axios';
import axios from '@plugins/axios';

export const API_REF = 'primavera/stock-dispatches';


export async function getStockDispatches(page: number | null = null, limit: number | null = null, search: string | null, filterByIds: { [key: string]: number[] } | null = null): AxiosPromise<any[]> {
    return axios.get(API_REF, {
        params: {
            page,
            limit,
            search,
            filterByIds
        },
    });
}

export async function getStockDispatch(id: string): AxiosPromise<any> {
    return axios.get(`${API_REF}/${id}`);
}

